import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import InputField from "../../Atoms/InputField";
import {
  getGeneralExamList,
  getSubTopicList,
  getMainCategoryList,
  getCourseTypelist,
  getCountryList,
  bindSubjectList,
  getGeneralBbbMeetingList,
} from "../../Redux/Actions/AdminAction";
import { useDispatch } from "react-redux";
import Submitbtn from "../../Components/submitButton";
import {
  deleteRecVideo,
  getGeneralPackageList,
  getGeneralTopicList,
  getLiveRecordedList,
  getPackageSessionList,
  getPremierVideoId,
  getRecordedDppList,
  getRecordedEditData,
  getStudentList,
  getTimezoneList,
  updateRecVideo,
} from "../../Redux/Actions/SuperAdminAction";
import { usePagination } from "../../Utils/appConstants";
import "../../App.css";
import { startsWithSpace, validateFullName } from "../../Utils/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import { defaultDuration, GradeList } from "../../Utils/dataConstant";
import PaginationTable from "../../Components/paginationTable";
import { minConvertTime } from "../../Utils/dateFormat";
import Dropdown from "../../Components/dropdown";
import Addmorebtn from "../../Components/addmorebtn";
import DatePicker from "../../Components/datePicker";
import Time from "../../Components/time";
import ScheduleRecorded from "../../Components/scheduleRecorded";
import moment from "moment";

export default function UpdateLiveRecordedVideo() {
  const dispatch: Function = useDispatch();
  const initialValInner = [
    {
      title: "",
      scheduleFrom: "",
      scheduleTo: "",
      sessionTime: "",
      s3Path: "",
    },
  ];
  const initialVal = [
    {
      topicId: "",
      subTopicId: "",
      lectureTitle: initialValInner,
    },
  ];
  const [courseType, setCourseType] = useState<any>("");
  const [courseTypeList, setcourseTypeList] = useState([]);
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [subject, setSubject] = useState<any>("");
  const [subjectList, setSubjectList] = useState([]);
  const [lecture, setLecture] = useState("");

  const [lectureDuration, setLectureDuration] = useState<any>(defaultDuration);
  const [awsMP4Link, setAwsMP4Link] = useState("");
  const [awsM3U8Link, setAwsM3U8Link] = useState("");
  const [selectedStudent, setSelectedStudent] = useState<any>([]);
  const [student, setStudent] = useState("");
  const [studentList, setStudentList] = useState<any>([]);
  const [premierId, setPremierId] = useState<any>(1);
  const [premierIdList, setPremierIdList] = useState<any>([]);
  const [resetSubject, setResetSubject] = useState(false);
  const [sessionList, setSessionList] = useState<any>([]);
  const [topicName, setTopicName] = useState<any>("");
  const [topicNameList, setTopicNameList] = useState<any>([]);
  const [DPPName, setDPPName] = useState<any>("");
  const [teachersHandout1, setTeachersHandout1] = useState<any>("");
  const [teachersHandout2, setTeachersHandout2] = useState<any>("");
  const [subTopicName, setSubTopicName] = useState<any>("");
  const [subTopicNameList, setSubTopicNameList] = useState<any>([]);

  const [liveRecordedList, setLiveRecordedList] = useState<any>([]);
  const [isEditMode, setisEditMode] = useState(false);

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);

  const _DATA = usePagination(String(count), rowsPerPage);

  const [demoRecId, setDemoRecId] = useState<any>("");
  const [recordedEditDataList, setRecordedEditDataList] = useState<any>("");

  const [batchList, setBatchtList] = useState<any>([]);
  const [selectedBatch, setSelectedBatch] = useState<any>([]);
  const [selectedStudentList, setSelectedStudentList] = useState<string[]>([]);

  const [category, setCategory] = useState<any>("");
  const [mainCategoryList, setMinCategoryList] = useState<any>([]);
  const [resetMainCategory, setresetMainCategory] = useState(false);

  const [batch, setBatch] = useState<any>("");
  const [DPPNameList, setDPPNameList] = useState<any>([]);

  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [grade, setGrade] = useState<any>("");
  const [session, setSession] = useState<any>("");
  const [sessionsList, setSessionsList] = useState([]);
  const [loginID, setLoginID] = useState<any>("");
  const [loginIDList, setLoginIDList] = useState([]);
  const [teacher, setTeacher] = useState<any>("");
  const [teacherList, setTeacherList] = useState([]);
  const [titleClassScheduleArr, setTitleClassScheduleArr] = useState<any>([]);
  const [fromdate, setFromdate] = useState<any>("");
  const [todate, setTodate] = useState<any>("");
  const [mindate, setmindate] = useState("");
  const [sessionTime, setSessionTime] = useState<any>("");
  const [timeZoneNew, setTimeZoneNew] = useState<any>("");
  const [timeZoneNewList, setTimeZoneNewList] = useState([]);
  const [timeZone, setTimeZone] = useState<any>("");
  const [timeZoneList, setTimeZoneList] = useState([]);


  useEffect(() => {
    setmindate(moment(new Date()).format("YYYY-MM-DD"));
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );
    dispatch(
      getPremierVideoId(
        {},
        (res: any) => {
          setPremierIdList(res);
          setPremierId(res[0]?._id);
        },
        () => {}
      )
    );
    dispatch(
      getGeneralTopicList(
        "",
        (res: any) => {
          setTopicNameList(res);
        },
        () => {}
      )
    );
  }, []);

  const onChangeTimeZoneNew = (value: any) => {
    setTimeZoneNew(value);
  };
  const onChangeTimeZone = (value: any) => {
    setTimeZone(value);
  };
  const onChangeStudentName = (value: any) => {
    setStudent(value);
    setStudentList([]);
  };
  const onChangeCourseType = (value: any) => {
    setCourseType(value);
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => {}
      )
    );
    const postData = {
      coursetypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };
  const onChangeStudentGrade = (value: any) => {
    setGrade(value);
    const postData = {
      coursetypeId: courseType._id,
      gradeId: value?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };
  const onChangeExam = (value: any) => {
    setExam(value);
    const postData = {
      examtypeId: value._id,
      coursetypeId: courseType?._id,
      gradeId: grade?._id,
    };
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };
  const onChangeSubject = (value: any) => {
    setSubject(value);
    setResetSubject(false);
    dispatch(
      getMainCategoryList(
        "",
        (res: any) => {
          setMinCategoryList(res);
        },
        () => {}
      )
    );
    dispatch(
      getGeneralBbbMeetingList(
        {
          subjectId: value._id,
        },
        (res: any) => {
          setLoginIDList(res);
        },
        () => {}
      )
    );
  };
  const onChangeMainCategory = (value: any) => {
    setCategory(value);
    setresetMainCategory(false);
    dispatch(
      getGeneralPackageList(
        {
          subjectId: subject?._id,
          maincategoryId: value._id,
        },
        (res: any) => {
          setBatchtList(res);
        },
        () => {}
      )
    );
  };
  const onChangeSession = (value: { _id: any; name?: string }) => {
    setSession(value._id);
  };

  const onSelectBatch = (event: {
    target: { checked: boolean; id: string };
  }) => {
    if (event.target.checked) {
      selectedBatch.push(event.target.id);
      setSelectedBatch(selectedBatch);
    } else {
      selectedBatch.splice(selectedBatch.indexOf(event.target.id), 1);
    }
    dispatch(
      getPackageSessionList(
        {
          packageIds: selectedBatch,
        },
        (res: any) => {
          setSessionsList(res);
        },
        () => {}
      )
    );
  };
  const onSelectGeneralBatch = (event: any, index: any) => {
    if (event.target.checked) {
      selectedBatch.push(event.target.id);
      setSelectedBatch(selectedBatch);
    } else {
      selectedBatch.splice(selectedBatch.indexOf(event.target.id), 1);
    }
  };

  const onChangeBatch = (value: any) => {
    setBatch(value);
  };

  const onChangeRegisteredStud = (event: any) => {
    const isInclude = selectedStudentList.indexOf(event.target.value);
    const isIncludeId = selectedStudent.indexOf(event.target.id);

    if (event.target.checked && isInclude === -1 && isIncludeId === -1) {
      setSelectedStudent([...selectedStudent, event.target.id]);
      setSelectedStudentList([...selectedStudentList, event.target.value]);
    } else {
      selectedStudent.splice(selectedStudent.indexOf(event.target.id), 1);
      selectedStudentList.splice(
        selectedStudentList.indexOf(event.target.value),
        1
      );
      setSelectedStudentList([...selectedStudentList, ""]);
      setSelectedStudent([...selectedStudent]);
    }
  };

  const validateArray = () => {
    let result = false;
    for (var i = 0; i < titleClassScheduleArr?.length; i++) {
      if (
        titleClassScheduleArr[i]?.scheduleFrom === "" ||
        titleClassScheduleArr[i]?.scheduleTo === "" ||
        titleClassScheduleArr[i]?.sessionTime === "" ||
        titleClassScheduleArr[i]?.s3Path === ""
      ) {
        result = true;
      }
    }
    return result;
  };

  function validation() {
    return courseType === "" ||
      exam === "" ||
      subject === "" ||
      (premierId === 3 ? lecture === "" : false) ||
      loginID === "" ||
      teacher === "" ||
      (premierId === 3 ? fromdate === "" : false) ||
      (premierId === 3 ? todate === "" : false) ||
      (premierId === 3 ? sessionTime === "" : false) ||
      validateArray() ||
      timeZone === "" ||
      premierId != 1
      ? selectedStudent?.length === 0
      : false;
  }

  const onChangeLecture = (value: any) => {
    if (validateFullName(value) && !startsWithSpace(value)) {
      setLecture(value);
    } else {
      dispatch(showErrorSnackbar("Please Character only"));
      return;
    }
  };

  const onChangeloginID = (value: any) => {
    setLoginID(value);
  };

  const onChangeteacher = (value: any) => {
    setTeacher(value);
    dispatch(
      getTimezoneList(
        {},
        (res: any) => {
          setTimeZoneList(res);
        },
        () => {}
      )
    );
  };

  const onPressAdd = () => {
    setTitleClassScheduleArr([...titleClassScheduleArr, initialVal[0]]);
  };

  const onSearchStudentClick = () => {
    const postData = {
      coursetypeId: courseType?._id,
      examtypeId: exam,
      search: student,
      gradeId: grade?._id,
    };
    if (
      postData["examtypeId"] === null ||
      postData["examtypeId"] === undefined ||
      postData["examtypeId"] === ""
    ) {
      delete postData["examtypeId"];
    }
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    dispatch(
      getStudentList(
        postData,
        (res: any) => {
          selectedStudent?.map((item: any, i: any) =>
            res?.map((data: any, index: any) => {
              if (data?._id == item) {
                data["selected"] = true;
              }
            })
          );
          setStudentList(res);
        },
        () => {}
      )
    );
  };

  const onChange = () => {
    const postData = {
      coursetypeId: courseType?._id,
      examtypeId: exam?._id,
      subjectId: subject?._id,
      premierVideoId: premierId,
      packageIds: premierId === 1 ? selectedBatch : [batch?._id],
      maincategoryId: category?._id,
    };
    if (selectedBatch.length === 0 && batch === "")
      delete postData["packageIds"];
    if (category?._id === "") delete postData["maincategoryId"];

    dispatch(
      getLiveRecordedList(
        postData,
        (res: any) => {
          setLiveRecordedList(res);
        },
        () => {}
      )
    );
  };
  const handleEdit = (value: any) => {
    setisEditMode(true);
    setDemoRecId(value?._id);
    setSelectedStudentList([]);
    setSelectedStudent([]);
    dispatch(
      getRecordedEditData(
        value?._id,
        (res: any) => {
          setRecordedEditDataList(res);
          setTitleClassScheduleArr(res?.data?.lectureData);
          setCourseType(res?.data?.coursetypeId?._id);
          setExam(res?.data?.examtypeId?._id);
          setSubject(res?.data?.subjectId?._id);
          setLecture(res?.data?.lectureTitle);
          setAwsM3U8Link(res?.data?.m3u8Link);
          setAwsMP4Link(res?.data?.mp4LinkLink);
          setStudentList(res?.student);
          setDPPName(res?.data?.dppId);
          setTopicName(res?.data?.topicId?._id);
          setSubTopicName(res?.data?.subTopicId?._id);
          setSession(res?.data?.sessionId?._id);
          setTeachersHandout1(res?.data?.teacherHandout1);
          setTeachersHandout2(res?.data?.teacherHandout2);
          setSelectedStudent(res?.student?.studentId);
          setCategory(res?.data?.maincategoryId?._id);

          res?.student?.map((item: any, index: any) => {
            let newArrr: any = [];
            newArrr = selectedStudent;
            if (item?.isSelected) {
              newArrr.push(item.studentId);
            }
            setSelectedStudent(newArrr);
          });

          res?.student?.map((item: any, index: any) => {
            let newArrr: any = [];
            newArrr = selectedStudentList;
            if (item?.isSelected) {
              newArrr.push(
                `${item.firstName} ${item.lastName} - ${item.mobile}`
              );
            }
            setSelectedStudentList(newArrr);
          });

          res?.data?.packages?.map((item: any, index: any) => {
            let newArrr: any = [];
            newArrr = selectedBatch;
            if (item?.isSelected) {
              newArrr.push(item._id);
            }
            setSelectedBatch(newArrr);
          });

          dispatch(
            getPackageSessionList(
              {
                packageIds: selectedBatch,
              },
              (res: any) => {
                setSessionList(res);
              },
              () => {}
            )
          );

          if (premierId === 1 || premierId === 2) {
            const data = {
              coursetypeId: courseType?._id,
              examtypeId: exam?._id,
              subjectId: subject?._id,
              topicId: res?.data?.topicId?._id,
            };
            dispatch(
              getSubTopicList(
                data,
                (res: any) => {
                  setSubTopicNameList(res);
                },
                () => {}
              )
            );
          }

          setLectureDuration(minConvertTime(res?.data?.lectureDuration));

          const postdata = {
            coursetypeId: courseType?._id,
            examtypeId: exam?._id,
            subjectId: subject?._id,
            topicId: topicName._id,
            subTopicId: subTopicName?._id,
          };
          if (topicName._id === "") delete postdata["topicId"];
          if (subTopicName?._id === "") delete postdata["subTopicId"];
          dispatch(
            getRecordedDppList(
              postdata,
              (res: any) => {
                setDPPNameList(res);
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  };
  const handleDelete = (value: any) => {
    dispatch(
      deleteRecVideo(
        value._id,
        () => {
          const postData = {
            coursetypeId: courseType?._id,
            examtypeId: exam?._id,
            subjectId: subject?._id,
            premierVideoId: premierId,
          };
          dispatch(
            getLiveRecordedList(
              postData,
              (res: any) => {
                setLiveRecordedList(res);
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  };

  const handleOnEdit = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    let arr: any = [];
    arr.push(lectureDuration.split(":"));
    var minutes = arr[0][0] * 60 + +arr[0][1] + arr[0][2] / 60;

    const postData: any = {};
    if (recordedEditDataList?.data?.coursetypeId?._id != courseType?._id) {
      postData["coursetypeId"] = courseType?._id;
    }
    if (recordedEditDataList?.data?.examtypeId?._id != exam?._id) {
      postData["examtypeId"] = exam?._id;
    }
    if (recordedEditDataList?.data?.subjectId?._id != subject?._id) {
      postData["subjectId"] = subject?._id;
    }
    if (recordedEditDataList?.data?.maincategoryId?._id != category?._id) {
      postData["maincategoryId"] = category?._id;
    }
    if (recordedEditDataList?.data?.lectureTitle != lecture) {
      postData["lectureTitle"] = lecture;
    }
    if (recordedEditDataList?.data?.m3u8Link != awsM3U8Link) {
      postData["m3u8Link"] = awsM3U8Link;
    }
    if (recordedEditDataList?.data?.mp4LinkLink != awsMP4Link) {
      postData["mp4LinkLink"] = awsMP4Link;
    }
    if (recordedEditDataList?.data?.premierVideoId?._id != premierId) {
      postData["premierVideoId"] = premierId;
    }
    if (recordedEditDataList?.student != selectedStudent) {
      postData["studentArrayID"] = selectedStudent;
    }
    if (recordedEditDataList?.data?.lectureDuration != minutes) {
      postData["lectureDuration"] = minutes;
    }
    if (recordedEditDataList?.data?.topicId?._id != topicName) {
      postData["topicId"] = topicName;
    }
    if (recordedEditDataList?.data?.subTopicId?._id != subTopicName) {
      postData["subTopicId"] = subTopicName;
    }
    if (recordedEditDataList?.data?.dppId != DPPName) {
      postData["dppId"] = DPPName;
    }
    if (recordedEditDataList?.data?.teacherHandout1 != teachersHandout1) {
      postData["teacherHandout1"] = teachersHandout1;
    }
    if (recordedEditDataList?.data?.teacherHandout2 != teachersHandout2) {
      postData["teacherHandout2"] = teachersHandout2;
    }
    dispatch(
      updateRecVideo(
        postData,
        demoRecId,
        (res: any) => {
          setisEditMode(false);
          setSelectedStudentList([]);
          setSelectedStudent([]);
          const postData = {
            coursetypeId: courseType?._id,
            examtypeId: exam?._id,
            subjectId: subject?._id,
            premierVideoId: premierId,
          };
          dispatch(
            getLiveRecordedList(
              postData,
              (res: any) => {
                setLiveRecordedList(res);
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  };

  const onChangeCountry = (value: any) => {
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setcourseTypeList(res);
        },
        () => {}
      )
    );
    // dispatch(
    //   getGeneralTimezoneList(
    //     value?._id,
    //     (res: any) => {
    //       setTimeZoneNewList(res);
    //     },
    //     () => {}
    //   )
    // );
  };


  const onChangeValue = (e: any, index: any) => {
    setPremierId(index);
    clearData1();
  };

  const clearData1 = () => {
    setLiveRecordedList([]);
    setCountry("");
    setCourseType("");
    setGrade("");
    setExam("");
    setSubject("");
    setCategory("");
    setBatch("");
    document
      .querySelectorAll("input[type=checkbox]")
      .forEach((el: any) => (el.checked = false));
  };

  const renderListMode = () => {
    return (
      <>
        <PaginationTable
          tableData={liveRecordedList ? liveRecordedList : []}
          pagename={"Recordedvideo"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "Lecture Title",
            "Student Name",
            "Subject",
            "Teacher Name",
            "ACTION",
          ]}
          edit={true}
          handleEdit={(value: any) => handleEdit(value)}
          handleDelete={(value: any) => handleDelete(value)}
        />
      </>
    );
  };

  const renderEditMode = () => {
    return (
      // <>
      //   <div className="contentBox">
      //     <div className="px-4 mx-0 mt-4 formDiv ">
      //       <form className="col-10" onSubmit={handleOnEdit}>
      //         <div className="d-flex Justify-content-between mb-3">
      //           <div className="col-2 align-item-center d-flex">
      //             <LabelField lableName={"Schedule For"} />
      //           </div>
      //           <div className="col-10 d-flex">
      //             {premierIdList.map((c: any, index: any) => {
      //               return (
      //                 <>
      //                   {premierId === c?._id ? (
      //                     <div className="col-3">
      //                       <span className="me-3 radiobtnspan">
      //                         <span>
      //                           <input
      //                             type="radio"
      //                             name="type"
      //                             value={c._id}
      //                             checked={premierId === c?._id ? true : false}
      //                             onChange={() => setPremierId(c?._id)}
      //                           />{" "}
      //                           <span className="ms-2">{c?.name}</span>
      //                         </span>
      //                       </span>
      //                     </div>
      //                   ) : null}
      //                 </>
      //               );
      //             })}
      //           </div>
      //         </div>
      //         <div className="d-flex Justify-content-between">
      //           <div className="col-2 align-item-center d-flex">
      //             <LabelField lableName={"Course Type"} />
      //           </div>
      //           <div className="col-6 ps-2  ">
      //             <CustomDropdown
      //               lableName="Select Course Type"
      //               setInputText={(value: { _id: string; name: string }) =>
      //                 onChangeCourseType(value)
      //               }
      //               value={courseType}
      //               options={courseTypeList}
      //               Isrequired={true}
      //               defaultValue={courseType}
      //               disabled={isEditMode}
      //             />
      //           </div>
      //         </div>
      //         {courseType?.type === 2 ? (
      //           <div className="d-flex Justify-content-between">
      //             <div className="col-2 align-item-center d-flex">
      //               <LabelField lableName={"Student Grade"} isRequired />
      //             </div>
      //             <div className="col-6 ps-2  ">
      //               <Dropdown
      //                 labelName="Select Student Grade"
      //                 setInputText={(value: any) => onChangeStudentGrade(value)}
      //                 value={grade}
      //                 options={GradeList}
      //               />
      //             </div>
      //           </div>
      //         ) : null}
      //         {courseType?.type === 2 || courseType?.type === 3 ? null : (
      //         <div className="d-flex Justify-content-between">
      //           <div className="col-2 align-item-center d-flex">
      //             <LabelField lableName={"Exam Name"} />
      //           </div>
      //           <div className="col-6 ps-2 ">
      //             <CustomDropdown
      //               lableName="Select Exam Name"
      //               setInputText={(value: { _id: string; name: string }) =>
      //                 onChangeExam(value)
      //               }
      //               value={exam}
      //               options={examList}
      //               Isrequired={true}
      //               defaultValue={exam}
      //               disabled={isEditMode}
      //             />
      //           </div>
      //         </div>
      //         )}
      //         <div className="d-flex Justify-content-between">
      //           <div className="col-2 align-item-center d-flex">
      //             <LabelField lableName={"Subject Name"} />
      //           </div>
      //           <div className="col-6 ps-2 ">
      //             <CustomDropdown
      //               lableName="Select Subject Name"
      //               setInputText={(value: { _id: string; name: string }) =>
      //                 onChangeSubject(value)
      //               }
      //               value={subject}
      //               options={subjectList}
      //               Isrequired={true}
      //               disabled={isEditMode}
      //               defaultValue={subjectList.length > 0 ? subject : ""}
      //             />
      //           </div>
      //         </div>

      //         {premierId === 1 || premierId === 2 ? (
      //           <div className="d-flex Justify-content-between  ">
      //             <div className="col-2 align-item-center d-flex">
      //               <LabelField lableName={"Main Category"} />
      //             </div>
      //             <div className="col-6 ps-2">
      //               <CustomDropdown
      //                 lableName="Select Main Category"
      //                 setInputText={(value: { _id: string; name: string }) =>
      //                   onChangeMainCategory(value)
      //                 }
      //                 value={category}
      //                 options={mainCategoryList}
      //                 Isrequired={true}
      //                 defaultValue={mainCategoryList.length > 0 ? category : ""}
      //                 disabled={isEditMode}
      //               />
      //             </div>
      //           </div>
      //         ) : null}

      //         {premierId === 1 ? (
      //           <>
      //             <div className="d-flex Justify-content-between">
      //               <div className="col-2 align-item-center d-flex">
      //                 <LabelField lableName={"Batch Name"} />
      //               </div>

      //               <div className="col-6 border rounded p-3">
      //                 {recordedEditDataList?.packages !== undefined
      //                   ? recordedEditDataList?.packages.map(
      //                     (c: any, index: any) => {
      //                       return (
      //                         <div className="ms-3">
      //                           <input
      //                             type="checkbox"
      //                             id={c._id}
      //                             value={c.name}
      //                             defaultChecked={c.isSelected}
      //                             onChange={(e) => onSelectBatch(e, index)}
      //                             disabled={isEditMode}
      //                           />
      //                           <span className="ms-2">{c.name}</span>
      //                         </div>
      //                       );
      //                     }
      //                   )
      //                   : null}
      //               </div>
      //             </div>
      //             <div className="d-flex Justify-content-between  ">
      //               <div className="col-2 align-item-center d-flex">
      //                 <LabelField lableName={"Session"} />
      //               </div>

      //               <div className="col-6 ps-2 ">
      //                 <CustomDropdown
      //                   lableName="Select Session"
      //                   setInputText={(value: { _id: string; name: string }) =>
      //                     onChangeSession(value)
      //                   }
      //                   value={session}
      //                   options={sessionList}
      //                   Isrequired={true}
      //                   defaultValue={sessionList.length > 0 ? session : ""}
      //                 />
      //               </div>
      //             </div>
      //           </>
      //         ) : null}
      //         {premierId === 2 ? (
      //           <div className="d-flex Justify-content-between  ">
      //             <div className="col-2 align-item-center d-flex">
      //               <LabelField lableName={"Batch Name"} />
      //             </div>

      //             <div className="col-6 ps-2 ">
      //               <CustomDropdown
      //                 lableName="Select Batch Name"
      //                 setInputText={(value: { _id: string; name: string }) =>
      //                   onChangeBatch(value)
      //                 }
      //                 value={batch}
      //                 options={batchList}
      //                 Isrequired={true}
      //                 defaultValue={batchList.length > 0 ? batch : ""}
      //                 disabled={isEditMode}
      //               />
      //             </div>
      //           </div>
      //         ) : null}
      //         {premierId === 1 || premierId === 2 ? (
      //           <>
      //             <div className="d-flex Justify-content-between">
      //               <div className="col-2 align-item-center d-flex">
      //                 <LabelField lableName={"Topic Name"} />
      //               </div>
      //               <div className="col-6 ps-2 ">
      //                 <CustomDropdown
      //                   lableName="Select Topic Name"
      //                   setInputText={(value: { _id: string; name: string }) =>
      //                     onChangeTopicName(value)
      //                   }
      //                   value={topicName}
      //                   options={topicNameList}
      //                   Isrequired={true}
      //                   defaultValue={topicNameList.length > 0 ? topicName : ""}
      //                 />
      //               </div>
      //             </div>
      //             <div className="d-flex Justify-content-between">
      //               <div className="col-2 align-item-center d-flex">
      //                 <LabelField lableName={"Sub-Topic Name"} />
      //               </div>
      //               <div className="col-6 ps-2 ">
      //                 <CustomDropdown
      //                   lableName="Select Sub-Topic Name"
      //                   setInputText={(value: { _id: string; name: string }) =>
      //                     onChangeSubTopicName(value)
      //                   }
      //                   value={subTopicName}
      //                   options={subTopicNameList}
      //                   Isrequired={true}
      //                   defaultValue={
      //                     subTopicNameList.length > 0 ? subTopicName : ""
      //                   }
      //                 />
      //               </div>
      //             </div>
      //           </>
      //         ) : null}
      //         {premierId === 1 || premierId === 2 || premierId === 3 ? (
      //           <>
      //             <div className="d-flex Justify-content-between">
      //               <div className="col-2 align-item-center d-flex">
      //                 <LabelField lableName={"Lecture Title"} />
      //               </div>
      //               <div className="col-6">
      //                 <InputField
      //                   placeholder="Enter Lecture Title"
      //                   value={lecture}
      //                   //onChangeInput={(value: any) => setLecture(value)}
      //                   onChangeInput={(value: any) => onChangeLecture(value)}
      //                   maxlength={50}
      //                   minlength={3}
      //                 />
      //               </div>
      //             </div>
      //             <div className="d-flex Justify-content-between">
      //               <div className="col-2 align-item-center d-flex">
      //                 <LabelField lableName={"Lecture Duration "} />
      //               </div>
      //               <div className="col-6  ">
      //                 <TimerClock
      //                   value={lectureDuration}
      //                   setInputText={(value: any) => setLectureDuration(value)}
      //                   Isrequired={true}
      //                 />
      //               </div>
      //             </div>
      //             <div className="d-flex Justify-content-between">
      //               <div className="col-2 align-item-center d-flex">
      //                 <LabelField lableName={"AWS M3U8 Link"} />
      //               </div>
      //               <div className="col-6   ">
      //                 <InputField
      //                   placeholder="Enter AWS M3U8 Link"
      //                   value={awsM3U8Link}
      //                   onChangeInput={(value: any) => setAwsM3U8Link(value)}
      //                   // maxlength={50}
      //                   minlength={3}
      //                 />
      //               </div>
      //             </div>
      //             <div className="d-flex Justify-content-between">
      //               <div className="col-2 align-item-center d-flex">
      //                 <LabelField lableName={"AWS MP4 Link"} />
      //               </div>
      //               <div className="col-6   ">
      //                 <InputField
      //                   placeholder="Enter AWS MP4 Link"
      //                   value={awsMP4Link}
      //                   onChangeInput={(value: any) => setAwsMP4Link(value)}
      //                   // maxlength={50}
      //                   minlength={3}
      //                 />
      //               </div>
      //             </div>
      //           </>
      //         ) : null}

      //         {premierId === 1 || premierId === 2 ? (
      //           <>
      //             <div className="d-flex Justify-content-between">
      //               <div className="col-2 align-item-center d-flex">
      //                 <LabelField lableName={"DPP Name"} />
      //               </div>
      //               <div className="col-6  ps-2 ">
      //                 <CustomDropdown
      //                   lableName="Select DPP Name"
      //                   setInputText={(value: { _id: string; name: string }) =>
      //                     onChangeDPPName(value)
      //                   }
      //                   value={DPPName}
      //                   options={DPPNameList}
      //                   defaultValue={DPPNameList.length > 0 ? DPPName : ""}
      //                 />
      //               </div>
      //             </div>
      //             <div className="d-flex Justify-content-between">
      //               <div className="col-2 pt-2">
      //                 <LabelField lableName={"Teacher Handout 1"} />
      //                 <p className="text-secondary">(With Annotation)</p>
      //               </div>
      //               <div className="col-6   ">
      //                 <InputField
      //                   placeholder="Enter Teacher Handout Url 1"
      //                   value={teachersHandout1}
      //                   onChangeInput={(value: any) =>
      //                     setTeachersHandout1(value)
      //                   }
      //                   // maxlength={50}
      //                   minlength={3}
      //                 />
      //               </div>
      //             </div>
      //             <div className="d-flex Justify-content-between">
      //               <div className="col-2 pt-2">
      //                 <LabelField lableName={"Teacher Handout 2"} />
      //                 <p className="text-secondary">(Without Annotation)</p>
      //               </div>
      //               <div className="col-6   ">
      //                 <InputField
      //                   placeholder="Enter Teacher Handout Url 2"
      //                   value={teachersHandout2}
      //                   onChangeInput={(value: any) =>
      //                     setTeachersHandout2(value)
      //                   }
      //                   // maxlength={50}
      //                   minlength={3}
      //                 />
      //               </div>
      //             </div>
      //           </>
      //         ) : null}

      //         {premierId === 2 || premierId === 3 ? (
      //           <div className="d-flex Justify-content-between">
      //             <div className="col-2 align-item-center d-flex">
      //               <LabelField lableName={"Scheduled For"} />
      //             </div>
      //             <div className="col-6 p-2 border rounded">
      //               <p className="fw-bold ">Registered Student</p>
      //               <div className="d-flex">
      //                 <InputField
      //                   placeholder="Enter student name"
      //                   value={student}
      //                   onChangeInput={(value: any) =>
      //                     onChangeStudentSearch(value)
      //                   }
      //                   maxlength={50}
      //                 />
      //                 <button
      //                   type="button"
      //                   className="btn btn-primary mb-3 align-items-center liveSearchBtn mt-2"
      //                   onClick={(e) => onChangeStudent()}
      //                 >
      //                   Search
      //                 </button>
      //               </div>

      //               {studentList !== undefined && student
      //                 ? studentList.map((c: any, index: any) => {
      //                   return (
      //                     <div className="ms-3">
      //                       <input
      //                         type="checkbox"
      //                         id={c._id}
      //                         value={`${c.firstName} ${c.lastName} - ${c.mobile}`}
      //                         onChange={(e) =>
      //                           onChangeRegisteredStud(e, index)
      //                         }
      //                         defaultChecked={c.selected}
      //                       />
      //                       <span className="ms-2">
      //                         {c.firstName} {c.lastName}
      //                       </span>
      //                     </div>
      //                   );
      //                 })
      //                 : null}
      //             </div>
      //             {selectedStudentList && selectedStudentList?.length > 0 ? (
      //               <div className="col-auto p-2 border rounded">
      //                 {selectedStudentList.map((item: any, index: any) => {
      //                   return <div>{item}</div>;
      //                 })}
      //               </div>
      //             ) : null}
      //           </div>
      //         ) : null}
      //         <div className="d-flex justify-content-center mt-3">
      //           <Submitbtn name={"Save & Update"} />
      //         </div>
      //       </form>
      //     </div>
      //   </div>
      // </>
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="col-10" onSubmit={handleOnEdit}>
          <div className="d-flex Justify-content-between mb-3">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Schedule For"} />
            </div>
            <div className="col-10 d-flex">
              {premierIdList.map((c: any, index: any) => {
                return (
                  <div className="col-3">
                    <span className="me-3 radiobtnspan">
                      <span>
                        <input
                          type="radio"
                          name="type"
                          id={c._id}
                          checked={c._id === premierId ? true : false}
                          onChange={(e) => onChangeValue(e, c._id)}
                        />
                        <span className="ms-2"> {c?.name}</span>
                      </span>
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Country"} isRequired />
            </div>
            <div className="col-6 ps-2 ">
              <Dropdown
                labelName="Select Country"
                setInputText={(value: any) => onChangeCountry(value)}
                value={country}
                options={countryList}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Course Type"} isRequired />
            </div>
            <div className="col-6 ps-2  ">
              <Dropdown
                labelName="Select Course Type"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeCourseType(value)
                }
                value={courseType}
                options={courseTypeList}
              />
            </div>
          </div>
          {courseType?.type === 2 ? (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Student Grade"} isRequired />
              </div>
              <div className="col-6 ps-2  ">
                <Dropdown
                  labelName="Select Student Grade"
                  setInputText={(value: any) => onChangeStudentGrade(value)}
                  value={grade}
                  options={GradeList}
                />
              </div>
            </div>
          ) : null}
          {courseType?.type === 2 || courseType?.type === 3 ? null : (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Exam Name"} isRequired />
              </div>
              <div className="col-6 ps-2 ">
                <Dropdown
                  labelName="Select Exam Name"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeExam(value)
                  }
                  value={exam}
                  options={examList}
                />
              </div>
            </div>
          )}
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Subject Name"} isRequired />
            </div>
            <div className="col-6 ps-2 ">
              <Dropdown
                labelName="Select Subject Name"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeSubject(value)
                }
                value={subject}
                options={subjectList}
              />
            </div>
          </div>

          {premierId === 1 || premierId === 2 ? (
            <div className="d-flex Justify-content-between mt-2">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Main Category"} isRequired />
              </div>
              <div className="col-6 ps-2">
                <Dropdown
                  labelName="Select Main Category"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeMainCategory(value)
                  }
                  value={category}
                  options={mainCategoryList}
                />
              </div>
            </div>
          ) : null}
          {premierId === 1 && batchList.length > 0 ? (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Batch Name"} />
              </div>
              <div className="col-6 mb-2 ">
                <div className=" border rounded p-3 ms-2 me-3">
                  {batchList.length > 0
                    ? batchList.map(
                        (
                          c: { checked: boolean; _id: string; name: string },
                          index: number
                        ) => {
                          return (
                            <div className="ms-3">
                              <input
                                type="checkbox"
                                id={c._id}
                                value={c.name}
                                onChange={onSelectBatch}
                              />
                              <span className="ms-2">{c.name}</span>
                            </div>
                          );
                        }
                      )
                    : null}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {premierId === 1 && sessionsList.length > 0 ? (
            <div className="d-flex Justify-content-between mt-3">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Session"} isRequired />
              </div>
              <div className="col-6 ps-2 ">
                <Dropdown
                  labelName="Select Session"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeSession(value)
                  }
                  value={session}
                  options={sessionsList}
                />
              </div>
            </div>
          ) : null}
          {premierId === 2 ? (
            <div className="d-flex Justify-content-between mt-3">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Batch Name"} isRequired />
              </div>

              <div className="col-6 ps-2 ">
                <Dropdown
                  labelName="Select Batch Name"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeBatch(value)
                  }
                  value={batch}
                  options={batchList}
                />
              </div>
            </div>
          ) : null}
          {premierId === 3 ? (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Lecture Title"} isRequired />
              </div>
              <div className="col-10">
                <InputField
                  placeholder="Enter Lecture Title"
                  value={lecture}
                  onChangeInput={(value: any) => onChangeLecture(value)}
                  maxlength={50}
                  minlength={3}
                />
              </div>
            </div>
          ) : null}

          {premierId === 2 ? null : (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Login ID"} isRequired />
              </div>
              <div className="col-6 ps-2 ">
                <Dropdown
                  labelName="Select Login ID"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeloginID(value)
                  }
                  value={loginID}
                  options={loginIDList}
                />
              </div>
            </div>
          )}
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Teachers Name"} isRequired />
            </div>
            <div className="col-6 ps-2 ">
              <Dropdown
                labelName="Select Teacher Name"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeteacher(value)
                }
                value={teacher}
                options={teacherList}
              />
            </div>
          </div>

          {premierId === 1 || premierId === 2 ? (
            <>
              <span>
                {titleClassScheduleArr?.map((item: any, index: number) => (
                  <ScheduleRecorded
                    courseType={courseType?._id}
                    exam={exam}
                    subject={subject}
                    grade={grade?._id}
                    topicNameList={topicNameList}
                    data={item}
                    index={index}
                    titleClassScheduleArr={titleClassScheduleArr}
                    setTitleClassScheduleArr={setTitleClassScheduleArr}
                  />
                ))}
              </span>
              <div className=" col-8 d-flex justify-content-end mb-3">
                <Addmorebtn name="Add More" handleClick={onPressAdd} />
              </div>
            </>
          ) : null}

          {premierId === 3 ? (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Class Schedule"} />
              </div>
              <div className="col-6 d-flex ps-2 ">
                <div className="col-4">
                  <div className="fw-bold">From Date</div>
                  <DatePicker
                    value={fromdate}
                    setInputText={(value: string) => setFromdate(value)}
                    mindate={mindate}
                  />
                </div>
                <div className="col-4">
                  <div className="fw-bold">To Date</div>
                  <DatePicker
                    value={todate}
                    setInputText={(value: string) => setTodate(value)}
                    mindate={mindate}
                  />
                </div>
                <div className="col-4">
                  <div className="fw-bold">Session Time</div>
                  <Time
                    value={sessionTime}
                    setInputText={(value: string) => {
                      setSessionTime(value);
                    }}
                  />
                </div>
              </div>
            </div>
          ) : null}
          {premierId === 2 ? (
            <>
              <div className="d-flex Justify-content-between">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Time Zone"} isRequired />
                </div>
                <div className="col-6 ps-2 ">
                  <Dropdown
                    labelName="Select Time Zone"
                    value={timeZoneNew}
                    options={timeZoneNewList}
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeTimeZoneNew(value)
                    }
                  />
                </div>
              </div>
            </>
          ) : null}

          {premierId === 2 ? null : (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Time Zone"} isRequired />
              </div>
              <div className="col-6 ps-2 ">
                <Dropdown
                  labelName="Select Time Zone"
                  value={timeZone}
                  options={timeZoneList}
                  setInputText={(value: any) => onChangeTimeZone(value)}
                />
              </div>
            </div>
          )}
          {premierId === 2 || premierId === 3 ? (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Schedule For"} isRequired />
              </div>
              <div className="col-6 p-2 border rounded">
                <p className="fw-bold ">Registered Student</p>
                <div className="d-flex">
                  <InputField
                    placeholder="Enter student name"
                    value={student}
                    onChangeInput={(value: any) => onChangeStudentName(value)}
                    maxlength={50}
                    minlength={1}
                  />
                  <button
                    type="button"
                    className="btn btn-primary mb-3 ms-2 align-items-center liveSearchBtn mt-2"
                    onClick={(e) => onSearchStudentClick()}
                  >
                    Search
                  </button>
                </div>
                {studentList !== undefined && student.length > 0
                  ? studentList.map((c: any, index: any) => {
                      return (
                        <div className="ms-3">
                          <input
                            type="checkbox"
                            id={c._id}
                            value={`${c.firstName} ${c.lastName} - ${c.mobile}`}
                            onChange={onChangeRegisteredStud}
                            defaultChecked={c.selected}
                          />
                          <span className="ms-2">
                            {c.firstName} {c.lastName}
                          </span>
                        </div>
                      );
                    })
                  : null}
              </div>
              {selectedStudentList.length > 0 ? (
                <div className="col-auto p-2 border rounded">
                  {selectedStudentList.map((item: any, index: any) => {
                    return <div>{item}</div>;
                  })}
                </div>
              ) : null}
            </div>
          ) : null}
          <div className="d-flex justify-content-center mt-3">
            <Submitbtn name={"Submit"} validation={validation()} />
          </div>
        </form>
      </div>
    );
  };

  useEffect(() => {
    if (isEditMode === false) {
      setSelectedStudentList([]);
      setSelectedStudent([]);
    }
  }, [isEditMode]);

  return (
    <div className="contentBox">
      <PageTitle
        name={"UPDATE RECORDED LIVE VIDEO"}
        isEditMode={isEditMode}
        setisEditMode={setisEditMode}
      />
      {isEditMode !== true ? (
        <div className="p-4">
          <div className="d-flex Justify-content-between mb-3">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Schedule For"} />
            </div>
            <div className="col-10 d-flex">
              {premierIdList.map((c: any, index: any) => {
                return (
                  <div className="col-3">
                    <span className="me-3 radiobtnspan">
                      <span>
                        <input
                          type="radio"
                          name="type"
                          value={c._id}
                          checked={premierId === c?._id ? true : false}
                          onChange={() => {
                            setPremierId(c?._id);
                            clearData1();
                          }}
                        />{" "}
                        <span className="ms-2">{c?.name}</span>
                      </span>
                    </span>
                  </div>
                );
              })}
            </div>
          </div>

          {premierId === 1 || premierId === 2 || premierId === 3 ? (
            <>
              <div className="d-flex Justify-content-between">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Country"} isRequired />
                </div>
                <div className="col-6 ps-2 ">
                  <Dropdown
                    labelName="Select Country"
                    setInputText={(value: any) => onChangeCountry(value)}
                    value={country}
                    options={countryList}
                  />
                </div>
              </div>
              <div className="d-flex Justify-content-between">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Course Type"} isRequired />
                </div>
                <div className="col-6 ps-2  ">
                  <Dropdown
                    labelName="Select Course Type"
                    setInputText={(value: any) => onChangeCourseType(value)}
                    value={courseType}
                    options={courseTypeList}
                  />
                </div>
              </div>
              <div className="d-flex Justify-content-between">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Exam Name"} isRequired />
                </div>
                <div className="col-6 ps-2 ">
                  <Dropdown
                    labelName="Select Exam Name"
                    setInputText={(value: any) => onChangeExam(value)}
                    value={exam}
                    options={examList}
                  />
                </div>
              </div>
              <div className="d-flex Justify-content-between">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Subject Name"} isRequired />
                </div>
                <div className="col-6 ps-2 ">
                  <Dropdown
                    labelName="Select Subject Name"
                    setInputText={(value: any) => onChangeSubject(value)}
                    value={subject}
                    options={subjectList}
                  />
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          {premierId === 1 || premierId === 2 ? (
            <div className="d-flex Justify-content-between  ">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Main Category"} isRequired />
              </div>
              <div className="col-6 ps-2">
                <Dropdown
                  labelName="Select Main Category"
                  setInputText={(value: any) => onChangeMainCategory(value)}
                  value={category}
                  options={mainCategoryList}
                />
              </div>
            </div>
          ) : null}

          {premierId === 1 ? (
            <>
              {batchList.length > 0 ? (
                <div className="d-flex Justify-content-between">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Batch Name"} />
                  </div>

                  <div className="col-6 border rounded p-3">
                    {batchList !== undefined
                      ? batchList.map((c: any, index: any) => {
                          return (
                            <div className="ms-3">
                              <input
                                type="checkbox"
                                id={c._id}
                                value={c.name}
                                onChange={(e) => onSelectGeneralBatch(e, index)}
                              />
                              <span className="ms-2">{c.name}</span>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            ""
          )}

          {premierId === 2 ? (
            <div className="d-flex Justify-content-between  ">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Batch Name"} isRequired />
              </div>

              <div className="col-6 ps-2 ">
                <Dropdown
                  labelName="Select Batch Name"
                  setInputText={(value: any) => onChangeBatch(value)}
                  value={batch}
                  options={batchList}
                />
              </div>
            </div>
          ) : null}

          <div className="d-flex justify-content-end pe-4 mt-3">
            <button
              type="button"
              className="btn btn-primary mb-3 align-items-center liveSearchBtn mt-2"
              onClick={(e) => onChange()}
            >
              {" "}
              Search{" "}
            </button>
          </div>
        </div>
      ) : null}
      {isEditMode ? renderEditMode() : renderListMode()}
    </div>
  );
}
