export default {
  SalesSidebarData: [
    {
      title: "COUNSELLING LEADS",
      path: "counsellingLeads",
    },
    // {
    //   title: "LANDING PAGE LEADS",
    //   path: "landingpageleads",
    // },
    {
      title: "REGISTERED USER",
      path: "registeredUsers",
    },
    {
      title: "TUTOR NETWORK",
      path: "tutorNetwork",
    },
    {
      title: "MARKETING LANDING PAGE",
      path: "marketingLandingpage",
    },
    {
      title: "PAID ADD PAGE",
      path: "paidAdd",
    },
    {
      title: "DEMO MANAGEMENT",
      subNav: [
        {
          title: "DEMO REQUEST",
          path: "demoRequest",
          cName: "sub-nav",
        },
        {
          title: "DEMO SCHEDULE",
          path: "demoSchedule",
          cName: "sub-nav",
        },
        {
          title: "DEMO CONDUCTED",
          path: "demoConducted",
          cName: "sub-nav",
        },
        {
          title: "DEMO PENDING",
          path: "demoPending",
          cName: "sub-nav",
        },
        {
          title: "DEMO CANCEL",
          path: "demoCancel",
          cName: "sub-nav",
        },
        {
          title: "DEMO JOIN",
          path: "demoJoin",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "REGULAR CLASS JOIN",
      path: "ClassJoin",
    },
    {
      title: "CLASS PENDING",
      path: "classPending",
    },
    {
      title: "PAYMENT PAYOUT",
      path: "paymentPayout",
    },
    {
      title: "UPCOMING CLASS",
      path: "upcomingScheduleClass",
    },
    {
      title: "RESCHEDULE",
      path: "reschedule",
    },
    {
      title: "CONVERSION",
      path: "conversion",
    },
  ],
};
