import { SetStateAction, useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import InputField from "../../Atoms/InputField";
import SubmitButton from "../../Components/submitButton";
import PaginationView from "../../Components/paginationView";
import PaginationTable from "../../Components/paginationTable";
import Pagination from "@mui/material/Pagination";
import { getTableDataSlots, usePagination } from "../../Utils/appConstants";
import { useDispatch } from "react-redux";
import {
  getAdminPanelUsers,
  getDepartment,
} from "../../Redux/Actions/SuperAdminAction";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import {
  hasWhiteSpace,
  length_Five,
  length_Hundred,
  length_Six,
  length_Ten,
  length_Thirty,
  length_Three,
  startsWithSpace,
  validateFullName,
  validateIsNumberOnly,
  validatePassword,
} from "../../Utils/validators";
import ToggleSwitchbtn from "../../Components/toggleSwitchBtn";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  deleteDsaUser,
  getCountryList,
  updateDsaUser,
} from "../../Redux/Actions/AdminAction";
import Dropdown from "../../Components/dropdown";

export default function UpdateUser() {
  const dispatch: Function = useDispatch();
  const [departmentList, setDepartmentList] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);

  const _DATA = usePagination(String(count), rowsPerPage);
  const [users, setUsers] = useState([]);
  const [isEditMode, setisEditMode] = useState(false);
  const [employeeName, setEmployeeName] = useState<string>("");
  const [mobileNo, setMobileNo] = useState<string>("");
  const [departmentedit, setDepartmentedit] = useState<string>("");
  const [roleedit, setRoleedit] = useState("");
  const [email, setEmail] = useState<string>("");
  const [dateOfJoining, setDateOfJoining] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [userID, setuserID] = useState<number>();
  const [changePassword, setChangePassword] = useState(false);
  const [dsaUserStatus, setDsaUserStatus] = useState<any>("");
  const [toggle, setToggle] = useState<any>(false);
  const [country, setCountry] = useState<any>("");
  const [resetCountryList, setResetCountryList] = useState(false);
  const tabs = ["ACTIVATED", "DEACTIVATED"];
  const [selectedTabIndex, setSelectedTabIndex] = useState<any>(1);

  const initialVal: {
    countryId: any;
    charges: any;
  }[] = [
    {
      countryId: "",
      charges: "",
    },
  ];
  const [priceArr, setPriceArr] = useState(initialVal);
  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    dispatch(
      getDepartment(
        "",
        (res: any) => {
          let tempStatus = res?.filter((item: any) => item?._id === 16);
          setDepartmentList(tempStatus);
        },
        () => {}
      )
    );

    const postData: any = {
      skip: 0,
      limit: 10,
      departmentId: 16,
      userRoleId: 16,
      status: selectedTabIndex,
    };

    dispatch(
      getAdminPanelUsers(
        postData,
        (res: any) => {
          setUsers(res?.data);
          setCount(res?.count);
        },
        () => {}
      )
    );

    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );
  }, []);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };

  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data: any = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        departmentId: 16,
        userRoleId: 16,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        departmentId: 16,
        userRoleId: 16,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        departmentId: 16,
        userRoleId: 16,
      };
    }

    if (dsaUserStatus) {
      data["status"] = dsaUserStatus?._id;
    }

    dispatch(
      getAdminPanelUsers(
        data,
        (res: any) => {
          setUsers(res?.data);
          setCount(res?.count);
        },
        () => {}
      )
    );
  };
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const handleEdit = (value: any) => {
    setEmployeeName(value?.name);
    setDepartmentedit(value?.departmentId?._id);
    setRoleedit(value?.userRoleId?._id);
    setMobileNo(value?.mobile);
    setEmail(value?.email);
    setDateOfJoining(value?.doj);
    setUserName(value?.username);
    setToggle(value?.isActive);
    setuserID(value?.empId);
    setCountry(value?.countryId);
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
          let tempCountry = res?.filter(
            (item: any) => value?.countryId === item?._id
          );
          setCountry(tempCountry[0]);
        },
        () => {}
      )
    );
    setisEditMode(true);
  };

  const handleDeactivate = (value: any) => {
    const postData: any = {
      isActive: false,
    };

    dispatch(
      deleteDsaUser(
        postData,
        value?.empId,
        (res: any) => {
          const postData: any = {
            skip: 0,
            limit: 10,
            departmentId: 16,
            userRoleId: 16,
          };

          dispatch(
            getAdminPanelUsers(
              postData,
              (res: any) => {
                setUsers(res?.data);
                setCount(res?.count);
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  };

  const onChangeMobileNO = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setMobileNo(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };

  const onChangeEmpName = (value: any) => {
    if (validateFullName(value)) {
      setEmployeeName(value);
    } else {
      dispatch(showErrorSnackbar("Please Character only"));
      return;
    }
  };

  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
  };

  const onChangeUserName = (value: any) => {
    if (!startsWithSpace(value)) {
      setUserName(value);
    } else {
      dispatch(showErrorSnackbar("Please enter valid User Name"));
      return;
    }
  };

  const onChangeEmail = (value: any) => {
    if (!startsWithSpace(value) && !hasWhiteSpace(value)) {
      setEmail(value);
    } else {
      dispatch(showErrorSnackbar("Please enter valid Email"));
      return;
    }
  };

  const handleChangePassword = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setChangePassword(!changePassword);
  };

  const onChangePassword = (value: SetStateAction<string>) => {
    if (validatePassword(value)) {
      setPassword(value);
    } else {
      dispatch(showErrorSnackbar("Please input valid Password"));
      return;
    }
  };
  const handleClose = () => {
    setChangePassword(false);
  };

  const changeToggle = (event: any) => {
    if (event.target.checked) {
      setToggle(true);
      const postData: any = {
        isActive: true,
      };

      dispatch(
        deleteDsaUser(
          postData,
          userID,
          (res: any) => {},
          () => {}
        )
      );
    } else {
      setToggle(false);
      const postData: any = {
        isActive: false,
      };

      dispatch(
        deleteDsaUser(
          postData,
          userID,
          (res: any) => {},
          () => {}
        )
      );
    }
  };

  var emailCheck = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  function allemail(inputtxt: any) {
    if (email.match(emailCheck)) {
      return true;
    } else {
      dispatch(showErrorSnackbar("Please input valid email"));
      return false;
    }
  }

  const onChangeTabs = (e: { preventDefault: () => void }, status: number) => {
    setSelectedTabIndex(status);
    const postData: any = {
      skip: 0,
      limit: 10,
      departmentId: 16,
      userRoleId: 16,
      status: status,
    };

    dispatch(
      getAdminPanelUsers(
        postData,
        (res: any) => {
          setUsers(res?.data);
          setCount(res?.count);
        },
        () => {}
      )
    );
  };

  const handleupdate = (e: any) => {
    e.preventDefault();
    if (allemail(email)) {
      const postData: any = {
        name: employeeName,
        departmentId: departmentedit,
        userRoleId: roleedit,
        username: userName,
        mobileNo: mobileNo,
        doj: dateOfJoining,
        email: email,
        isActive: toggle,
        countryId: country?._id,
      };
      if (
        postData["empCreatedId"] === null ||
        postData["empCreatedId"] === undefined ||
        postData["empCreatedId"] === ""
      ) {
        delete postData["empCreatedId"];
      }
      if (password.length > 0) {
        postData["password"] = password;
      }
      dispatch(
        updateDsaUser(
          postData,
          userID,
          (res: any) => {
            setisEditMode(false);
            const postData: any = {
              skip: 0,
              limit: 10,
              departmentId: 16,
              userRoleId: 16,
            };
            if (dsaUserStatus) {
              postData["status"] = dsaUserStatus?._id;
            }

            dispatch(
              getAdminPanelUsers(
                postData,
                (res: any) => {
                  setUsers(res?.data);
                  setCount(res?.count);
                  setEmployeeName("");
                  setUserName("");
                  setMobileNo("");
                  setDateOfJoining("");
                  setEmail("");
                  setPriceArr(initialVal);
                  setResetCountryList(true);
                },
                () => {}
              )
            );
          },
          () => {}
        )
      );
    } else {
      dispatch(showErrorSnackbar("Please input valid email"));
      return false;
    }
  };

  const validation = () => {
    return (
      employeeName === "" ||
      country === "" ||
      mobileNo === "" ||
      email === "" ||
      userName === ""
    );
  };

  const renderEditMode = () => {
    return (
      <div className="col-7 px-5">
        <form onSubmit={handleupdate}>
          <div className="d-flex mb-2 flex-column p-3">
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Employee Name"} />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter Employee Name"
                  value={employeeName}
                  onChangeInput={(value: any) => onChangeEmpName(value)}
                  maxlength={length_Hundred}
                  minlength={length_Three}
                />
              </div>
            </div>

            <div className="d-flex Justify-content-between  ">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Country"} isRequired />
              </div>
              <div className="col-6 ps-2 ">
                <Dropdown
                  labelName="Select Country"
                  setInputText={(value: any) => onChangeCountry(value)}
                  value={country}
                  options={countryList}
                />
              </div>
            </div>

            <div className="d-flex Justify-content-between ">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Mobile Number"} />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter Mobile Number"
                  value={mobileNo}
                  onChangeInput={(value: any) => onChangeMobileNO(value)}
                  maxlength={length_Ten}
                  minlength={length_Six}
                />
              </div>
            </div>
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Email Address"} />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter Email Address"
                  value={email}
                  onChangeInput={(value: any) => onChangeEmail(value)}
                  maxlength={length_Hundred}
                />
              </div>
            </div>

            <div className="d-flex Justify-content-between  ">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Username"} />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter Username"
                  value={userName}
                  onChangeInput={(value: any) => onChangeUserName(value)}
                  maxlength={length_Thirty}
                  minlength={length_Five}
                />
              </div>
            </div>
            {changePassword ? (
              <div className="d-flex Justify-content-between  ">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Password"} />
                </div>
                <div className="col-6">
                  <InputField
                    placeholder="Enter Password"
                    value={password}
                    onChangeInput={(value: any) => onChangePassword(value)}
                    maxlength={30}
                    minlength={5}
                  />
                </div>
                <div className="pt-3">
                  <CancelOutlinedIcon onClick={() => handleClose()} />
                </div>
              </div>
            ) : (
              <div className="d-flex Justify-content-between  ">
                <div className="col-2 align-item-center d-flex"></div>
                <div className=" ps-2 ">
                  <button
                    type="submit"
                    className={"btn btn-primary rounded-pill me-4"}
                    onClick={(e) => handleChangePassword(e)}
                  >
                    Change Password{" "}
                  </button>
                </div>
              </div>
            )}

            <div className="d-flex mb-2 align-items-center mt-2">
              <LabelField lableName={"Change Status"} />
              <ToggleSwitchbtn
                getToggleval={(value: any) => {
                  changeToggle(value);
                }}
                isActive={toggle}
              />
            </div>
          </div>
          <div className="d-flex mb-2 align-items-center mt-2 p-5">
            <SubmitButton name={"Submit"} validation={validation()} />
          </div>
        </form>
      </div>
    );
  };

  const renderListMode = () => {
    return (
      <div>
        <div className="d-flex justify-content-between">
          <div className="pe-5 d-flex justify-content-between mx-2 ps-2 mt-5">
            <div>
              {tabs?.map((data: string, index: number) => {
                return (
                  <button
                    className={`add_Question-btn btn     me-4 ${
                      selectedTabIndex === index + 1
                        ? "btn-success"
                        : "btn-secondary"
                    }`}
                    onClick={(e) => onChangeTabs(e, index + 1)}
                  >
                    {data}
                  </button>
                );
              })}
            </div>
          </div>
          {users.length !== 0 ? (
            <div className=" paginationdiv  align-items-center pt-3">
              <div className="ms-5">
                <Pagination
                  count={totalPages}
                  size="large"
                  page={currentPage}
                  variant="outlined"
                  shape="rounded"
                  color={"primary"}
                  onChange={handleChange}
                />
              </div>
              {count > 0 ? (
                <PaginationView
                  count={count}
                  tableData={users ? users : []}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  rowsPerPageOptions={getTableDataSlots(count)}
                />
              ) : null}
            </div>
          ) : null}
        </div>

        <PaginationTable
          tableData={users ? users : []}
          pagename={"DsaUserdata"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "Employee Name",
            "Department",
            "Status",
            "Panel Role",
            "Mobile Number",
            "Username",
            "Date Of Joining",
            "ACTION",
          ]}
          edit={true}
          handleEdit={(value: any) => handleEdit(value)}
          handleDeactivate={(value: any) => handleDeactivate(value)}
        />
      </div>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"EDIT USER"}
        isEditMode={isEditMode}
        setisEditMode={setisEditMode}
      />

      {isEditMode ? renderEditMode() : renderListMode()}
    </div>
  );
}
