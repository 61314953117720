import { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useDispatch } from "react-redux";
import InputField from "../Atoms/InputField";
import LabelField from "./labelField";
import { addFaqType, getFaqTypeList } from "../Redux/Actions/AdminAction";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  containsNumber,
  length_Two,
  validateValueWithoutSpace,
} from "../Utils/validators";
import TextCKEditor from "./textCKEditor";
import Addmorebtn from "./addmorebtn";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { showErrorSnackbar } from "../Redux/Actions/snackbarAction";
import { validateIsNumberOnlyErrMsg } from "../Utils/errorMsg";
import CustomDropdown from "./customDropdown";

export default function FaqSeo(props: any) {
  const dispatch = useDispatch();

  const [FAQtypeList, setFAQtypeList] = useState<any>([]);
  const [open, setOpen] = useState<any>(false);
  const [newFAQType, setNewFAQType] = useState<any>("");
  const [Order, setOrder] = useState<any>("");
  const initialValInner = [
    {
      question: "",
      answer: "",
      questionOrderId: "",
    },
  ];
  const initialVal = [
    {
      faq: initialValInner,
      faqTypeId: "",
      faqTypeOrderId: "",
    },
  ];
  const [FAQArr, setFAQArr] = useState<any>(initialVal);

  useEffect(() => {
    props?.getData?.map((value: any) => {
      setFAQArr(value?.faqData);
    });
    faqlist();
  }, [props?.getData]);

  const faqlist = () => {
    dispatch(
      getFaqTypeList(
        "",
        (data: any) => {
          setFAQtypeList(data);
        },
        () => {}
      )
    );
  };

  const onPressAdd = () => {
    setFAQArr([...FAQArr, initialVal[0]]);
  };

  const onChangePYQ = (value: any, index: any, key: any, Innerindex: any) => {
    let newArray = JSON.parse(JSON.stringify(FAQArr));
    if (key && index !== "") {
      if (key === "faqTypeId") {
        newArray[index][key] = value?._id;
        setFAQArr(newArray);
      } else if (key === "faqTypeOrderId") {
        if (value !== "") {
          if (!containsNumber(value)) {
            dispatch(showErrorSnackbar("Only Numbers are allowed!") as any);
            return;
          } else if (!validateValueWithoutSpace(value)) {
            dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
            return;
          } else newArray[index][key] = value;
          setFAQArr(newArray);
        }
        setFAQArr(newArray);
      }
    }

    if (key && Innerindex !== "") {
      if (key === "question") {
        newArray[index].faq[Innerindex][key] = value;
        setFAQArr(newArray);
      } else if (key === "questionOrderId") {
        if (value !== "") {
          if (!containsNumber(value)) {
            dispatch(showErrorSnackbar("Only Numbers are allowed!") as any);
            return;
          } else if (!validateValueWithoutSpace(value)) {
            dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
            return;
          } else newArray[index].faq[Innerindex][key] = value;
        }
        setFAQArr(newArray);
      } else if (key === "answer") {
        newArray[index].faq[Innerindex][key] = value;
        setFAQArr(newArray);
      }
      // ********
    }
  };

  const onChangeOrder = (value: any) => {
    if (value !== "") {
      if (!containsNumber(value)) {
        dispatch(showErrorSnackbar("Only Numbers are allowed!") as any);
        return;
      } else if (!validateValueWithoutSpace(value)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else setOrder(value);
    }
    setOrder(value);
  };

  const handleSubmitFAQ = () => {
    // e.preventDefault();
    const postdata = {
      type: newFAQType,
      orderId: Order,
    };
    dispatch(
      addFaqType(
        postdata,
        (res: any) => {
          setOpen(false);
          faqlist();
        },
        () => {}
      )
    );
  };

  const validationsFAQType = () => {
    return newFAQType === "";
  };

  const handleCrossOuter = (index: any) => {
    const newArray = [...FAQArr];
    if (index > -1) {
      newArray.splice(index, 1);
    }
    setFAQArr(newArray);
  };

  const handleCloseInner = (index: any, Innerindex: any) => {
    const newArray = [...FAQArr];
    if (Innerindex > -1) {
      newArray[index]?.faq?.splice(Innerindex, 1);
    }
    setFAQArr(newArray);
  };

  const onPressAddInner = (index: any) => {
    const newArray = [...FAQArr];
    newArray[index]?.faq?.push(initialValInner[0]);
    setFAQArr(newArray);
  };
  useEffect(() => {
    faqAlldata();
  }, [FAQArr]);
  useEffect(() => {
    if (props?.allFaqData?.length === 0) {
      setFAQArr(initialVal);
    }
  }, [props?.allFaqData]);

  const faqAlldata = () => {
    const PostData = {
      faqData: FAQArr,
    };
    props?.setFasSeoData(PostData);
    return PostData;
  };

  return (
    <>
      {FAQArr?.map((value: any, index: any) => {
        const { faqTypeId, faqTypeOrderId } = value;
        return (
          <>
            <span className="col-12 d-flex justify-content-end removeButton mt-3">
              {FAQArr.length === 0 ? null : (
                <HighlightOffIcon
                  className="cursor"
                  color="primary"
                  fontSize="small"
                  onClick={() => handleCrossOuter(index)}
                />
              )}
            </span>
            <div className=" p-3 border border-dark rounded mt-3">
              <h5 className="fw-bold">FAQ's</h5>
              <div className="col-md-12">
                <>
                  <div className="d-flex mb-2 align-items-center">
                    <div className=" align-item-center d-flex">
                      <LabelField lableName={"FAQ Type"} isRequired />
                    </div>
                    <div className="col-3 m-2">
                      <CustomDropdown
                        lableName="Select FAQ Type"
                        setInputText={(value: any) =>
                          onChangePYQ(value, index, "faqTypeId", "")
                        }
                        value={faqTypeId}
                        defaultValue={faqTypeId}
                        options={FAQtypeList}
                      />
                    </div>
                    <div className="col-auto d-flex justify-content-center  mb-2 ms-3">
                      <button
                        type="button"
                        onClick={() => setOpen(true)}
                        className={`btn submitbtn btn-dark`}
                      >
                        Add New FAQ Type
                      </button>
                    </div>
                  </div>
                  <div className="d-flex Justify-content-between">
                    <div className="col-md-1 align-item-center d-flex">
                      <LabelField lableName={"Order " + (index + 1)} />
                    </div>
                    <div className="col-md-3">
                      <InputField
                        key={"Order" + index}
                        placeholder={"Enter Order " + (index + 1)}
                        onChangeInput={(value: any) =>
                          onChangePYQ(value, index, "faqTypeOrderId", "")
                        }
                        value={faqTypeOrderId}
                      />
                    </div>
                  </div>
                </>
                <div className="col-md-12">
                  {value?.faq?.map((item: any, Innerindex: any) => (
                    <div key={index} className="position-relative ">
                      <span className="d-flex justify-content-end p-2 removeButton">
                        {value?.faq?.length === 1 ? null : (
                          <HighlightOffIcon
                            className="cursor"
                            color="primary"
                            fontSize="small"
                            onClick={() => handleCloseInner(index, Innerindex)}
                          />
                        )}
                      </span>
                      <div className="col-md-12 border border-4 p-3">
                        <div className="d-flex Justify-content-between">
                          <div className="col-md-1 align-item-center d-flex">
                            <LabelField
                              lableName={"Question " + (Innerindex + 1)}
                              isRequired
                            />
                          </div>
                          <div className="col-md-3">
                            <InputField
                              key={"Question" + Innerindex + 1}
                              placeholder={"Enter Question " + (Innerindex + 1)}
                              onChangeInput={(value: any) =>
                                onChangePYQ(
                                  value,
                                  index,
                                  "question",
                                  Innerindex
                                )
                              }
                              value={item?.question}
                              minlength={length_Two}
                            />
                          </div>
                        </div>
                        <div className="d-flex Justify-content-between">
                          <div className="col-md-1 align-item-center d-flex">
                            <LabelField
                              lableName={"Order " + (Innerindex + 1)}
                              isRequired
                            />
                          </div>
                          <div className="col-md-3">
                            <InputField
                              key={"Order" + index}
                              placeholder={"Enter Order " + (Innerindex + 1)}
                              onChangeInput={(value: any) =>
                                onChangePYQ(
                                  value,
                                  index,
                                  "questionOrderId",
                                  Innerindex
                                )
                              }
                              value={item?.questionOrderId}
                            />
                          </div>
                        </div>
                        <div className="d-flex mb-2 align-items-center">
                          <div className="col-md-1 align-item-center d-flex">
                            <LabelField
                              lableName={"Answer " + (Innerindex + 1)}
                              isRequired
                            />
                          </div>
                          <div className="col-md-11 editor_content mb-2">
                            <TextCKEditor
                              onChange={(value: any) =>
                                onChangePYQ(value, index, "answer", Innerindex)
                              }
                              value={item?.answer}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="col-auto d-flex justify-content-end mt-2 ">
                    <Addmorebtn
                      name={"Add More"}
                      handleClick={() => onPressAddInner(index)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
      <div className=" col-12 d-flex justify-content-end p-2">
        <Addmorebtn name="Add More" handleClick={onPressAdd} />
      </div>

      {/* *******************Add New Faq Type******************************************* */}
      {open ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="modelChat"
          open={open}
          onClose={() => setOpen(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className="modeldivChat p-3">
              <div className="col-mb-4 d-flex justify-content-between ">
                <p className="fw-bold">
                  <span style={{ color: "#3F51B5" }}>
                    {" "}
                    Server Status Update
                  </span>
                </p>
                <ClearOutlinedIcon
                  className="text-primary cursor"
                  onClick={() => setOpen(false)}
                />
              </div>
              <div className="form-check text-center p-4">
                <div className="d-flex justify-content-between">
                  <div className="col-4 align-item-center d-flex">
                    <LabelField lableName="New FAQ Type" isRequired />
                  </div>
                  <div className="col-6">
                    <InputField
                      placeholder={"Enter New FAQ Type"}
                      onChangeInput={(value: any) => setNewFAQType(value)}
                      value={newFAQType}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col-4 align-item-center d-flex">
                    <LabelField lableName={"Order Id"} />
                  </div>
                  <div className="col-6">
                    <InputField
                      placeholder={"Enter Order"}
                      onChangeInput={(value: any) => onChangeOrder(value)}
                      value={Order}
                    />
                  </div>
                </div>
                <div className="col-auto d-flex justify-content-center mt-5">
                  <button
                    onClick={() => handleSubmitFAQ()}
                    type="button"
                    className={`btn mb-3 submitbtn ${
                      validationsFAQType() ? "btn-secondary" : "btn-primary"
                    }`}
                    disabled={validationsFAQType()}
                  >
                    Save and Update
                  </button>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      ) : null}
    </>
  );
}
