import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import ReplyIcon from "@mui/icons-material/Reply";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { useState } from "react";
import NotificationDropDown from "./notificationDropDown";
interface props {
  name: string;
  isEditMode?: boolean;
  setisEditMode?: any;
  overallRating?: any;
  reviewCount?: any;
  lectureData?: any;
  countryData?: any;
  courseTypeData?: any;
  gradeData?: any;
  examData?: any;
  subjectData?: any;
  topicData?: any;
  subTopicData?: any;
  endDate?: any;
  startdate?: any;
}

export default function PageTitle({
  name,
  isEditMode,
  setisEditMode,
  overallRating,
  reviewCount,
  lectureData,
  countryData,
  courseTypeData,
  gradeData,
  examData,
  subjectData,
  topicData,
  subTopicData,
  endDate,
  startdate,
}: props) {
  const currentURLarr = useLocation().pathname;
  const navigate = useNavigate();
  const [showNotification, setShowNotification] = useState(false);

  const clickNotification = () => {
    if (currentURLarr !== "/TeachersPanel/Notification") {
      setShowNotification(!showNotification);
    }
  };
  return (
    <div className="d-flex justify-content-between">
      <h5 className="Title">
        {name}
        {currentURLarr === "/TeachersPanel/feedback" ||
        currentURLarr === "/TeachersPanel/subjectFeedback" ||
        currentURLarr === "/TeachersPanel/demoConducted" ||
        currentURLarr === "/TeachersPanel/ClassConducted" ? (
          <div className="d-flex justify-content-between">
            <span className="font-12 text-dark d-flex pt-2">
              overall rating:
            </span>
            <span className="d-flex font-12 pt-2" style={{ color: "#3F51B5" }}>
              {overallRating
                ? Math.round(
                    (parseFloat(overallRating) + Number.EPSILON) * 10
                  ) / 10
                : "0"}
            </span>
            <span className="text-secondary pt-3  d-flex fs-6">
              ({reviewCount ? reviewCount : "0"})
            </span>
            <span className=" pt-2 ">
              <Box
                sx={{
                  "& > legend": { mt: 2 },
                }}
              >
                <Rating
                  name="simple-controlled size-small"
                  value={overallRating}
                />
              </Box>
            </span>
          </div>
        ) : (
          ""
        )}
        {currentURLarr === "/TeachersPanel/feedback" ||
        currentURLarr === "/TeachersPanel/subjectFeedback" ||
        currentURLarr === "/TeachersPanel/demoConducted" ||
        currentURLarr === "/TeachersPanel/paymantsAndCoupons" ||
        currentURLarr === "/TeachersPanel/timeAvailability" ||
        currentURLarr === "/TeachersPanel/demoSchedule" ||
        currentURLarr === "/TeachersPanel/myDemo" ||
        currentURLarr === "/TeachersPanel/myClass" ||
        currentURLarr === "/TeachersPanel/classSchedule" ||
        currentURLarr === "/TeachersPanel/ClassConducted" ? (
          <div
            className="me-5 cursor position-relative mt-1"
            onClick={clickNotification}
          >
            <NotificationsNoneIcon />
          </div>
        ) : (
          ""
        )}
        {showNotification ? (
          <div className="position-absolute notiDiv">
            <NotificationDropDown />
          </div>
        ) : null}
        {currentURLarr === "/SalesPanel/conversionDetails" ||
        currentURLarr === "/OperationPanel/dsaConversionDetails" ||
        currentURLarr === "/Academics/addPageAcademics" ||
        currentURLarr === "/OperationPanel/conversionDetails" ? (
          <div className=" ">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => navigate(-1)}
            >
              <ReplyIcon /> Back
            </button>
          </div>
        ) : null}
        {currentURLarr === "/HrOperationPanel/tutorDataDetails" ? (
          <div className=" ">
            <button
              type="button"
              className="btn btn-light"
              onClick={() =>
                navigate("../tutorDetails", {
                  state: {
                    endDateReturn: endDate,
                    startdateReturn: startdate,
                    isDetails:true
                  },
                })
              }
            >
              <ReplyIcon /> Back
            </button>
          </div>
        ) : null}

        {currentURLarr === "/Academics/indexAcademics" ? (
          <div className=" ">
            <button
              type="button"
              className="btn btn-light"
              onClick={() =>
                navigate("../academicsAddData", {
                  state: {
                    countryData: countryData,
                    courseTypeData: courseTypeData,
                    gradeData: gradeData,
                    examData: examData,
                    subjectData: subjectData,
                    topicData: topicData,
                    subTopicData: subTopicData,
                    lectureData: lectureData,
                  },
                })
              }
            >
              <ReplyIcon /> Back
            </button>
          </div>
        ) : null}

        {isEditMode ? (
          <button
            className="btn btn-outline-dark"
            style={{ fontSize: "0.6em" }}
            onClick={() => setisEditMode(false)}
          >
            Cancel Edit
          </button>
        ) : null}
      </h5>
    </div>
  );
}
