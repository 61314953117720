// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.horizontalcontainer {
    position: relative;
    z-index: 1;
}
.horizontalcontainer .nav.nav-tabs {
     position: relative;
     z-index: 9999;
     top: 3px;
     border-bottom: 0;
 }

 .horizontalcontainer .nav-tabs .nav-link {
     margin: 0 25px 0 25px;
     border-radius: 11px 11px 0px 0px;
     box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.1);
     padding: 10px 20px;
     /* color: var(--bookSessionOrange) !important; */
     display: flex;
     align-items: center;
     justify-content: center;
     /* border: 1px solid #e5e5e5; */
     color: #353535 !important;
 }

 .horizontalcontainer .nav-tabs .nav-item.show .nav-link,
 .horizontalcontainer .nav-tabs .nav-link.active {
     border: solid #f15d22 !important;
     border-bottom: 0 !important;
     border-radius: 11px 11px 0px 0px;
     /* margin: 0 50px; */
     box-shadow: none;
     color: var(--bookSessionOrange) !important;
     font-weight: bold !important;
     /* background: var(--bookSessionOrange) !important;
     color: #fff !important; */

 }

 .horizontalcontainer .tab-content>.active {
     border: solid #f15d22;
     border-radius: 0 15px 15px 15px;
     box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
 }`, "",{"version":3,"sources":["webpack://./src/Assets/css/horizontalTab.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;AACd;AACA;KACK,kBAAkB;KAClB,aAAa;KACb,QAAQ;KACR,gBAAgB;CACpB;;CAEA;KACI,qBAAqB;KACrB,gCAAgC;KAChC,4CAA4C;KAC5C,kBAAkB;KAClB,gDAAgD;KAChD,aAAa;KACb,mBAAmB;KACnB,uBAAuB;KACvB,+BAA+B;KAC/B,yBAAyB;CAC7B;;CAEA;;KAEI,gCAAgC;KAChC,2BAA2B;KAC3B,gCAAgC;KAChC,oBAAoB;KACpB,gBAAgB;KAChB,0CAA0C;KAC1C,4BAA4B;KAC5B;8BACyB;;CAE7B;;CAEA;KACI,qBAAqB;KACrB,+BAA+B;KAC/B,qDAAqD;CACzD","sourcesContent":[".horizontalcontainer {\n    position: relative;\n    z-index: 1;\n}\n.horizontalcontainer .nav.nav-tabs {\n     position: relative;\n     z-index: 9999;\n     top: 3px;\n     border-bottom: 0;\n }\n\n .horizontalcontainer .nav-tabs .nav-link {\n     margin: 0 25px 0 25px;\n     border-radius: 11px 11px 0px 0px;\n     box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.1);\n     padding: 10px 20px;\n     /* color: var(--bookSessionOrange) !important; */\n     display: flex;\n     align-items: center;\n     justify-content: center;\n     /* border: 1px solid #e5e5e5; */\n     color: #353535 !important;\n }\n\n .horizontalcontainer .nav-tabs .nav-item.show .nav-link,\n .horizontalcontainer .nav-tabs .nav-link.active {\n     border: solid #f15d22 !important;\n     border-bottom: 0 !important;\n     border-radius: 11px 11px 0px 0px;\n     /* margin: 0 50px; */\n     box-shadow: none;\n     color: var(--bookSessionOrange) !important;\n     font-weight: bold !important;\n     /* background: var(--bookSessionOrange) !important;\n     color: #fff !important; */\n\n }\n\n .horizontalcontainer .tab-content>.active {\n     border: solid #f15d22;\n     border-radius: 0 15px 15px 15px;\n     box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
