// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Container for cards */
.card-container {
  display: flex;
  gap: 40px;
  justify-content: center;
  margin-top: 50px;
}

/* Flip Card Styling */
.flip-card {
  flex-basis: 350px;
  /* width: 300px; */
  height: 400px;
  perspective: 1000px;
  cursor: pointer;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

/* Flip the card when flipped class is added */
.flipped .flip-card-inner {
  transform: rotateY(180deg);
}

/* Front and back styling */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #353535;
  border-radius: 10px;
  flex-direction: column;
  padding: 20px;
  background: #fff;
}

/* Front card styling */
.flip-card-front {
  /* background-color: #2980b9; */
}

/* Back card styling */
.flip-card-back {
  /* background-color: #e74c3c; */
  transform: rotateY(180deg);
}`, "",{"version":3,"sources":["webpack://./src/Assets/css/FlipCardTemplate.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;EACE,aAAa;EACb,SAAS;EACT,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA,sBAAsB;AACtB;EACE,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,0BAA0B;EAC1B,4BAA4B;AAC9B;;AAEA,8CAA8C;AAC9C;EACE,0BAA0B;AAC5B;;AAEA,2BAA2B;AAC3B;;EAEE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,2BAA2B;EAC3B,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,cAAc;EACd,mBAAmB;EACnB,sBAAsB;EACtB,aAAa;EACb,gBAAgB;AAClB;;AAEA,uBAAuB;AACvB;EACE,+BAA+B;AACjC;;AAEA,sBAAsB;AACtB;EACE,+BAA+B;EAC/B,0BAA0B;AAC5B","sourcesContent":["/* Container for cards */\n.card-container {\n  display: flex;\n  gap: 40px;\n  justify-content: center;\n  margin-top: 50px;\n}\n\n/* Flip Card Styling */\n.flip-card {\n  flex-basis: 350px;\n  /* width: 300px; */\n  height: 400px;\n  perspective: 1000px;\n  cursor: pointer;\n}\n\n.flip-card-inner {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  text-align: center;\n  transition: transform 0.6s;\n  transform-style: preserve-3d;\n}\n\n/* Flip the card when flipped class is added */\n.flipped .flip-card-inner {\n  transform: rotateY(180deg);\n}\n\n/* Front and back styling */\n.flip-card-front,\n.flip-card-back {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  backface-visibility: hidden;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 14px;\n  color: #353535;\n  border-radius: 10px;\n  flex-direction: column;\n  padding: 20px;\n  background: #fff;\n}\n\n/* Front card styling */\n.flip-card-front {\n  /* background-color: #2980b9; */\n}\n\n/* Back card styling */\n.flip-card-back {\n  /* background-color: #e74c3c; */\n  transform: rotateY(180deg);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
