import parser from "html-react-parser";
function DragndropLeft({ items, index, item }: any) {
  let startingCharCode = 97;
  return (
    <div className="left-side">
      {/* {items.map((item:any, index:number) => { */}

      {/* return( */}
      <div className={`d-flex align-items-center`}>
        <div className="me-2">
          {String.fromCharCode(startingCharCode + index)}.
        </div>
        <div key={index} className={`left-item`}>
          {item?.descType === "text" ? (
            <div>{parser(item?.description)}</div>
          ) : (
            <div className="">
              <img src={item?.description} alt="dndImg" className="dndImg" />
            </div>
          )}
        </div>
      </div>
      {/* )})} */}
    </div>
  );
}

export default DragndropLeft;
