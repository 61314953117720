import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import LabelField from "./labelField";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DatePicker from "./datePicker";
import moment from "moment";
import SubmitButton from "./submitButton";
import { useDispatch } from "react-redux";
import Time from "./time";

interface props {
  open: boolean;
  handleClose: Function;
  data?: any;
  pageName?: any; 
}

export default function ImagePopUp(props: props) {
  const { open, handleClose, data,   pageName } = props;
  console.log("props",props?.data)

  const dispatch: Function = useDispatch();

  const [startDate, setStartDate] = useState<any>("");
  const [mindate, setmindate] = useState<any>("");
  const [sessionTime, setSessionTime] = useState<any>("");

  useEffect(() => {
    setmindate(moment(new Date()).format("YYYY-MM-DD"));
    setStartDate(moment(data?.date).format("YYYY-MM-DD"));
  }, []);

  function closePopup(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void {
    handleClose();
  }
 
 

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modelChat"
      open={open}
      // onClose={() => {
      //   handleClose();
      // }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="demoReSchedulemodel ">
          <div className="d-flex justify-content-between">
            <h5 className="fw-bold"></h5>

            <div>
              <HighlightOffIcon
                color="primary"
                className="cursor"
                onClick={closePopup}
              />{" "}
            </div>
          </div>
          <div className="demoCard mt-2">
           <img src={props?.data} className="img-fluid" />
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
