import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getJitSiSoftwareList } from "../../Redux/Actions/AdminAction";

export default function UpgradeStudentLevel() {
  const dispatch: Function = useDispatch();
  const [IOSLink, setIOSLink] = useState<any>("");
  const [WindowsLink, setWindowsLink] = useState<any>("");
  useEffect(() => {
    dispatch(
      getJitSiSoftwareList(
        "",
        (res: any) => {
          res?.map((item: any) => {
            if (item?.deviceType === "ios") {
              setIOSLink(item?.link);
            }
            if (item?.deviceType === "windows") {
              setWindowsLink(item?.link);
            }
          });
        },
        () => {}
      )
    );
  }, []);

  return (
    <div className="contentBox">
      <PageTitle name={"DOWNLOAD JITSI SOFTWARE"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <div className="d-flex justify-content-between mt-3">
          <div className="col-6 align-item-center d-flex justify-content-center">
            <LabelField lableName={"Windows"} />
            <a
              href={WindowsLink}
              target="_blank"
              className="btn btn-dark rounded-pill px-3"
            >
              DOWNLOAD JITSI
            </a>
          </div>
          <div className="col-6 align-item-center d-flex justify-content-center">
            <LabelField lableName={"IOS"} />
            <a
              href={IOSLink}
              target="_blank"
              className="btn btn-dark rounded-pill px-3"
            >
              DOWNLOAD JITSI
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
