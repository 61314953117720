export default {
  superAdmin: {
    login: "/v1/admin/auth/login",
    register: "/v1/users/auth/register-user-adminPanel",
    logout: "/v1/admin/auth/logout",
    addAdminUser: "/v1/admin/auth/add-admin-user",
    getDept: "/v1/auth/static/department/list",
    getRole: "/v1/auth/static/role/list",
    getUserRoles: "/v1/auth/static/userRoles/list",
    getExamList: "/v1/exam/list",
    getAdminPanelUsers: "/v1/admin/auth/get-admin-users",
    filterRoles: "/v1/auth/static/",
    addTeacher: "/v1/admin/teacher/add-admin-teacher ",
    getRegisterUser: "/v1/admin/registered-users/list",
    updateAdminUser: "/v1/admin/auth/update/",
    getTimezone: "/v1/auth/timezone/list",
    getStudentList: "/v1/auth/students/list",
    getPremierVideoId: "/v1/auth/admin/premiertypes/list",
    addLivePremier: "/v1/admin/live-premier/add",
    getLivePremierList: "/v1/admin/live-premier/premier-list",
    getTimezoneList: "/v1/auth/timezone/list",
    deleteLivePremier: "/v1/admin/live-premier/delete/",
    getLivePremierEditData: "/v1/admin/live-premier/",
    updateLivePremier: "/v1/admin/live-premier/update/",
    getLiveStatusUpdate: "/v1/admin/teacher/bbb-live/status/change",
    deleteTeacher: "/v1/admin/teacher/delete/",
    assignTeacherExam: "/v1/admin/teacher/assigned-exam/list",
    updateTeacher: "/v1/admin/teacher/update/",
    updateTeacherList: "/v1/admin/teacher/teacher-list",
    updateTeacherStatus: "/v1/admin/teacher/isActive/",
    updateUserStatus: "/v1/admin/auth/isActive/",
    getCountryList: "/v1/general/countries",
    getGeneralTimezoneList: "/v1/general/timezones/",
    getSourceList: "/v1/general/source-type/list",
    getCourseExamList: "/v1/auth/all/course-exam",
    getExamListWeb: "v1/auth/static/exam/list",
    getLiveStatusChange: "/v1/admin/teacher/bbb-live/status/change",
    getCounsellorList: "/v1/auth/counsellor/list",
    getAffiliateUsers: "/v1/admin/auth/affiliate-admin-user/get",
    updateAffiliateUser: "/v1/admin/auth/affiliate-update/",
    getJitSiSoftwareList: "/v1/admin/global-setting/jitsi-exe/download",
  },
  leads: {
    landingLeads: "/v1/admin/leads/list",
    academicLeads: "/v1/admin/academic/list",
    affilateMarketingLead: "/v1/admin/academic/affliate-leads",
  },
  academic: {
    addBookSession: "/v1/admin/academic/add",
  },
  course: {
    coursetype: "/v1/admin/course-type/add",
    updateCourseType: "/v1/admin/course-type/update/",
    deleteCourseType: "/v1/admin/course-type/delete/",
    getCourseTypelist: "/v1/admin/course-type/list",
  },
  exam: {
    addExamName: "/v1/admin/exam/add",
    uploadExamLogo: "/v1/admin/exam/upload-logo",
    getExamList: "/v1/admin/exam/exam-list",
    updateExamName: "/v1/admin/exam/update/",
    deleteExamname: "/v1/admin/exam/delete/",
    getGeneralExamList: "/v1/auth/exam/list",
  },
  language: {
    uploadLanguageLogo: "/v1/admin/language/upload-logo",
    addLanguage: "/v1/admin/language/add",
    getLanguageList: "/v1/admin/language/list",
    updateLanguage: "/v1/admin/language/update/",
    deleteLanguage: "/v1/admin/language/delete/",
  },
  subCategory: {
    uploadSubcategoryLogo: "/v1/admin/subcategory/upload-logo",
    addSubcategory: "/v1/admin/subcategory/add",
    getSubcategoryList: "/v1/admin/subcategory/list",
    updateSubCategory: "/v1/admin/subcategory/update/",
    deleteSubCategory: "/v1/admin/subcategory/delete/",
  },
  section: {
    addSection: "/v1/admin/section/add",
    getSectionList: "/v1/admin/section/list",
    updateSection: "/v1/admin/section/update/",
    deleteSection: "/v1/admin/section/delete/",
    getSectionTypesList: "/v1/auth/admin/sectiontypes/list",
  },
  mainCategory: {
    addMainCategory: "/v1/admin/maincategory/add",
    getMainCategoryList: "/v1/admin/maincategory/list",
    getSessionList: "/v1/auth/admin/sessions/list",
    updateMainCategory: "/v1/admin/maincategory/update/",
    deleteMainCategory: "/v1/admin/maincategory/delete/",
    getMainCategoryEditData: "/v1/admin/maincategory/",
  },
  assignExam: {
    //getAssignExamList:"/v1/auth/admin/exam/list",
    getAssignExamList: "/v1/admin/exam/assigned-exam",
    getGeneralCourseList: "/v1/auth/admin/course/list",
    getAssignExam: "/v1/admin/exam/assign/exam",
    getAssignedExamsList: "/v1/auth/admin/assignedExam/list",
  },

  meeting: {
    addMeeting: "/v1/admin/meeting/add",
    getMeetingList: "/v1/admin/meeting/meeting-list",
    deleteBbbMeetingId: "/v1/admin/meeting/delete/",
    getGeneralBbbMeetingList: "/v1/auth/bbbmeeting/list",
    updateBbbMeeting: "/v1/admin/meeting/update",
    getBBBTeacherList: "/v1/auth/teacher/list-v2",
  },
  examDescription: {
    uploadExamBanner: "/v1/admin/exam-description/upload-media",
    addExamDescription: "/v1/admin/exam-description/add",
    getExamDescriptionList: "/v1/admin/exam-description/list",
    updateExamDescription: "/v1/admin/exam-description/update/",
    deleteExamDescription: "/v1/admin/exam-description/delete/",
  },
  subject: {
    addSubject: "/v1/admin/subject/add",
    getSubjectList: "/v1/auth/admin/subject/list",
    updateSubject: "/v1/admin/subject/update/",
    deleteSubject: "/v1/admin/subject/delete/",
    getGeneralSubjectList: "/v1/auth/subject/list",
    getGeneralTeacherList: "/v1/auth/teacher/list",
    getSubject: "/v1/admin/subject/list",
    bindSubject: "/v1/admin/subject/bind",
    getNewSubjectList: "/v1/admin/subject/list-v2",
    bindSubjectList: "/v1/admin/subject/bind-list",
    assignedSubjectList: "/v1/admin/subject/assigned-list",
  },
  courseDescription: {
    addCourseDescription: "/v1/admin/course-description/add",
    getCourseDescription: "/v1/admin/course-description/list",
    updateCourseDescription: "/v1/admin/course-description/update/",
    deleteCourseDescription: "/v1/admin/course-description/delete/",
    addCourseDescFeature: "/v1/admin/course-description/features/add",
    courseDescFeatureList: "v1/admin/course-description/features-align/get",
  },
  package: {
    uploadPackageMedia: "/v1/admin/package/upload-media",
    createPackage: "/v1/admin/package/add",
    getPackageList: "/v1/admin/package/list",
    getCourseDuration: "/v1/auth/admin/courseduration/list",
    addPackage: "/v1/admin/package/add",
    getPackageEditData: "/v1/admin/package/",
    updatePackage: "/v1/admin/package/update/",
    deletePackage: "/v1/admin/package/delete/",
    getCountlist: "/v1/admin/lectures/count",
    getPackageWebList: "/v1/package/list",
  },
  teacher: {
    getLiveScheduleList: "/v1/admin/teacher/bbb-live/list/",
    getUpcomingList: "/v1/admin/teacher/bbb-live/upcominglist/",
    addTeacherTimeAvailability: "/v1/admin/teacher/teacher-availability-add",
    teacherDemoSchedule: "/v1/admin/teacher/demo-scheduled/list",
    getweeklyScheduleList: "/v1/admin/teacher/weekly-schedule/get",
    getTeacherPayoutList: "/v1/admin/teacher/payout/get",
    teacherDemoPending: "/v1/admin/teacher/pending-demo-scheduled/list",
    teacherClassPending: "/v1/admin/teacher/bbb-live/pending-list/",
    addLiveFeedback: "/v1/admin/teacher/live-feedback/submit",
    getLiveFeedback: "/v1/admin/teacher/get-Teacher-liveFeedback",
    getUpcomingListAdmin: "v1/admin/teacher/bbb-live/upcominglistForAdmin",
    getUserAnswerReq: "/v1/admin/teacher/get-Descriptive-QuestionData",
    submitUserAnswerPoints:
      "/v1/admin/teacher/submit-Descriptive-QuestionDataPoints",
    getStudentListByTeacherID: "/v1/auth/students/get-studentList-ByTeacherId",
    getTeacherStudentList: "/v1/admin/teacher/studentList",
    updateStudentLevel: "/v1/admin/teacher/set-studentLevel",
    getTeacherUpcomingLectureList: "/v1/admin/teacher/aligned-lectureList",
    getAlignedLecture: "/v1/admin/teacher/get-IndexWise-Data",
    addAcademicData: "/v1/admin/teacher/academicData-add",
    addSubmitDescriptiveQuestion:
      "/v1/description/submit-Descriptive-questionData",
  },
  aboutexam: {
    addAboutExam: "/v1/admin/about-exam/add",
  },
  recordedVideo: {
    addRecordedVideo: "v1/admin/recorded-video/add",
    getLiveRecordedList: "/v1/admin/recorded-video/list",
    getRecordedEditData: "/v1/admin/recorded-video/",
    updateRecVideo: "/v1/admin/recorded-video/update/",
    deleteRecVideo: "/v1/admin/recorded-video/delete/",
    getGeneralPackageList: "/v1/auth/package/list",
    getGeneralTopicList: "/v1/auth/topics/list",
    getPackageSessionList: "/v1/auth/pakage-sessions-list",
    getRecordedDppList: "/v1/admin/recorded-video/dpp-list/1",
    uploadRecVideo: "/v1/admin/recorded-video/upload-media",
  },
  notification: {
    addNotification: "/v1/admin/notification/add",
    getNotificationIconList: "/v1/admin/notification/icon-list",
    getNotificationList: "/v1/admin/notification/getlist",
    getNotificationEditData: "/v1/admin/notification/get/",
    updateNotification: "/v1/admin/notification/update/",
    deleteNotification: "/v1/admin/notification/delete/",
    teacherNotification: "/v1/admin/notification/teacher-notification",
  },
  examdates: {
    addExamDates: "/v1/admin/exam-date/add",
    uploadExamIcon: "/v1/admin/examdate-icon/upload-icon",
    addExamDateIcon: "/v1/admin/exam-date/add",
    getExamDateIconList: "/v1/admin/examdate-icon/list",
  },
  topic: {
    addTopic: "/v1/admin/topic/add",
    getTopicList: "/v1/admin/topic/list",
    getTopicEditData: "/v1/admin/topic/list/",
    updateTopic: "/v1/admin/topic/update/",
    deleteTopic: "/v1/admin/topic/delete/",
  },
  subtopic: {
    addSubTopic: "/v1/admin/sub-topics/add",
    getSubTopicList: "/v1/admin/sub-topics/list",
    updateSubTopic: "/v1/admin/sub-topics/update/",
    deleteSubTopic: "/v1/admin/sub-topics/delete/",
  },
  lecture: {
    addLecture: "/v1/admin/lectures/add",
    getLectureList: "/v1/admin/lectures/list",
    deleteLectures: "/v1/admin/lectures/delete/",
    updateLecture: "/v1/admin/lectures/update/",
    getLectureCount: "/v1/admin/lectures/totalcount",
  },
  pattern: {
    getCalculatorTypes: "v1/auth/static/calculatorTypes/list",
    addPattern: "/v1/admin/examPattern/add",
    getPatternList: "/v1/admin/examPattern/list",
    getPatternByID: "/v1/admin/examPattern/ExamPattern-list/",
    updatePattern: "/v1/admin/examPattern/update",
    deletePart: "/v1/admin/examPattern/deletePart/",
    deleteSection: "/v1/admin/examPattern/deleteSection/",
    deletePattern: "/v1/admin/examPattern/delete/",
    addScholorshipPattern: "/v1/admin/examPattern/scholorship/add",
    getScholorshipPattern: "/v1/admin/examPattern/scholorship-list",
    getScholorshipPatternByID:
      "/v1/admin/examPattern/scholorship/ExamPattern-list/",
  },
  ExamSet: {
    getExamSetTypeId: "/v1/auth/admin/examSetType/list",
    addExamSet: "/v1/admin/exam-set/add",
    getExamSetList: "/v1/admin/exam-set/examset-list",
    changeActiveStatusExamset: "/v1/admin/exam-set/isActive/",
    updateExamSet: "/v1/admin/exam-set/update/",
    deleteExamSet: "/v1/admin/exam-set/delete/",
    getExamSetQuestionList: "/v1/admin/exam-set/questionlist",
    getExamSetAvailQuestionList: "/v1/admin/exam-set/get-availques",
    getFilteredPatternList: "/v1/admin/examPattern/listbyExamFlag",
  },
  Question: {
    getExamSetList: "/v1/admin/exam-question/examset-list",
    getSectionsData: "/v1/admin/exam-question/get-examsection-data/",
    updatePartTotalQuestions: "/v1/admin/exam-question/update-totalquestion",
    uploadQuestionMedia: "/v1/admin/exam-question/upload-media",
    addQuestions: "/v1/admin/exam-question/add",
    getQuestionsList: "/v1/admin/exam-question/question-list",
    updateQuestionOrder: "/v1/admin/exam-question/update-order/",
    updateQuestions: "/v1/admin/exam-question/update/",
    deleteQuestions: "/v1/admin/exam-question/delete/",
  },
  Feedback: {
    getUserFeedbackData: "/v1/admin/teacher/teacherFeedbackList",
    getUserFeedbackbypackage:
      "/v1/admin/teacher/teacherFeedbackListByPackageId",
    getFeedbackforLecture: "/v1/admin/teacher/LectureRatingAndComments/",
    getLiveTeacherFeedbackList: "/v1/admin/lectures/get-Teacher-Feedback-List",
    getLiveStudentFeedbackList: "/v1/admin/lectures/get-Student-liveFeedback",
    getFeedbackForGraph:
      "/v1/admin/lectures/get-Teacher-QuestionWise-Feedback-ForGraph",
    getFeedbackForGraphLectureWise:
      "/v1/admin/lectures/get-Teacher-LectureWise-Feedback-ForGraph",
    getStudentListFeedback: "/v1/auth/students/list2",
  },
  Registration: {
    getRegistrationDetails: "/v1/admin/registered-users/DetailsByMobile",
    addManuallyCourse: "/v1/admin/registered-users/addCourse",
    updateCourse: "/v1/admin/registered-users/updateCourse",
    oldManualActivation: "/v1/admin/registered-users/addCourse-old",
    updateUserProfile: "/v1/admin/auth/edit-userprofile/",
    getReferenceUserList: "/v1/admin/auth/get-reference-users",
  },
  CXO: {
    getTransactions: "/v1/admin/transactions/transaction-details",
    getPaymentStatus: "/v1/admin/transactions/payment-details",
    getUpcomingInstallments: "/v1/admin/transactions/upcoming-installment",
    getOverdueInstallments: "/v1/admin/transactions/overdue-installment",
  },
  Blog: {
    uploadBlogImg: "/v1/admin/blogs/upload-blog-image",
    addBlog: "/v1/admin/blogs/add",
    getBlogList: "/v1/admin/blogs/list",
    deleteBlog: "/v1/admin/blogs/delete/",
    updateBlog: "/v1/admin/blogs/update/",
  },
  sales: {
    getLandingPageSales: "/v1/landingpage/LSATlandingpage/get",
    getTutorNetwork: "/v1/tutorNetwork/get",
    getMarketingLandingPage: "/v1/landingpage/Marketinglandingpage/get",
  },
  demo: {
    getDemoRequestList: "/v1/admin/demo/request-list",
    getPreferTeacherList: "/v1/admin/demo/preferteacher/list",
    getOtherTeacherList: "/v1/admin/demo/other-teacher/list",
    alignDemo: "/v1/admin/demo/schedule/save",
    getScheduledList: "/v1/admin/demo/sheduled-list",
    ReScheduleDemo: "/v1/admin/demo/reschedule-demo",
    getDemoParentFeedBack: "/v1/admin/demo/user-feedback",
    getLink: "/v1/admin/demo/admin-invitelink/get",
    getClassData: "/v1/live-lecture/admin-get",
    getPendingClass: "/v1/admin/teacher/bbb-live/pending-list",
  },
  coupon: {
    createCoupon: "/v1/admin/coupon/add",
    getCouponList: "/v1/admin/coupon/list",
    updateCoupon: "/v1/admin/coupon/update/",
    deactiveCoupon: "/v1/admin/deactivate/",
    deleteCoupon: "/v1/admin/coupon/delete/",
  },
  feature: {
    assignFeature: "/v1/admin/course-description/features/align",
    getFeatureList: "/v1/admin/course-description/features/get",
    updateFeature: "/v1/admin/course-description/updateCourseFeature/",
    deleteFeature: "/v1/admin/course-description/deleteCourseFeature/",
  },
  globalSetting: {
    addGlobalSetting: "/v1/admin/global-setting/extension/add",
    getGlobalSettingList: "/v1/admin/global-setting/extension/get",
    addlectureCharges: "/v1/admin/global-setting/lecture-charges/add",
    getLectureCharges: "/v1/admin/global-setting/lecture-charges/get",
  },
  payout: {
    getPayoutList: "/v1/admin/transactions/payout",
    PayoutSave: "/v1/admin/transactions/payout/save",
  },
  rescheduleRequest: {
    getRescheduleList: "/v1/admin/live-premier/reSchedule/list",
    RescheduleRequest: "/v1/admin/live-premier/reSchedule",
    deleteRescheduleReq: "/v1/admin/live-premier/reSchedule/cancel/",
  },
  installment: {
    addInstallment: "/v1/admin/global-setting/installment/add",
    getInstallment: "/v1/admin/global-setting/installment/get",
  },
  conversion: {
    teacherOverallRecord: "/v1/admin/demo/teacher-overall-record",
    teacherRecordDetails: "/v1/admin/demo/teacher-overall-record/",
  },
  serverTimings: {
    getBbbServerTimings: "/v1/admin/global-setting/bbbserver-freetime/get",
  },
  DSA: {
    addDsaUser: "/v1/admin/auth/add-dsa-user",
    getDsaPanelUsers: "/v1/admin/auth/get-dsa-users",
    updateDsaUser: "/v1/admin/auth/dsa-update/",
    checkUser: "/v1/admin/auth/check-user",
    deleteDsaUser: "/v1/admin/auth/isActive/",
    getDsaPayoutList: "/v1/admin/demo/dsa-payout/get",
    DsaPayoutSave: "/v1/admin/transactions/dsa-payout/save",
    getDsaSalesList: "/v1/admin/auth/dsa-sales-list",
    createRegisterUser: "/v1/users/auth/register-user-adminPanel",
    getDsaManagerList: "/v1/admin/auth/dsa-manager-list",
    getManagerByCompany: "/v1/admin/auth/dsa-manager-list/",
  },
  transactions: {
    getPaymentDetails: "/v1/admin/transactions/payment-details",
  },
  academics: {
    addIndex: "/v1/admin/academicPortal/add-index",
    uploadAcademicsImg: "/v1/admin/academicPortal/upload-media",
    getIndex: "/v1/admin/academicPortal/get-index",
    addPage: "/v1/admin/academicPortal/add-page",
    getAddPageData: "/v1/admin/academicPortal/get-data/",
    getUpdateDemoData: "/v1/admin/academicPortal/democlass-bind",
    getDemoClassBindData: "/v1/admin/academicPortal/democlass-bind/",
    updateIndex: "/v1/admin/academicPortal/update-index/",
  },
  affiliate: {
    AffiliatePaymentList: "/v1/admin/demo/affiliate-payout/get",
    AffiliatePayoutSave: "/v1/admin/transactions/affiliate-payout/save",
  },
  questionConfig: {
    addQuestionConfig: "/v1/admin/question-configuration/create",
    getQuestionConfigList: "/v1/admin/question-configuration/get",
    deleteQuestionConfig: "/v1/admin/question-configuration/delete/",
  },
  questionBank: {
    createQuestionBank: "/v1/admin/exam-question/add-QuestionBank",
    getQuestionBankList: "/v1/admin/exam-question/questionBank-list",
    updateQuestionBank: "/v1/admin/exam-question/update-QuestionBank",
    deleteQuestionBank: "/v1/admin/exam-question/delete/",
    importQuestions: "/v1/admin/examPattern/import-questionBank",
    deleteAllQuestions: "/v1/admin/exam-question/delete-allQuestions",
    updateImportQuestionBank:
      "/v1/admin/examPattern/update-Importing-QuestionBank",
  },
  orders: {
    addToCart: "/v1/admin/order/add-to-cart",
    applyCoupon: "/v1/admin/order/apply-coupon",
    getCartList: "/v1/admin/order/get-cart-list",
    placeOrder: "/v1/admin/order/place-order",
    paymentRequest: "/v1/admin/order/payment-request",
    paymentExecute: "/v1/admin/order/payment-execute",
    getByPackageTopicwiseList: "/v1/admin/lectures/topicwise-list",
    getCustomizePackageAmount: "/v1/order/lecture/amount",
    saveCustomizePackage: "/v1/order/user-customise-package",
    saveRegistrationDetails: "/v1/users/auth/register-user-adminPanel",
    savePaymentLink: "/v1/admin/order/paymentlink/save",
    getTransactionPaymentLinks: "/v1/admin/transactions/link-payments",
  },
  tutorDetails: {
    getTutorDetails: "v1/employee/tutorDetails-get",
  },
  student: {
    getAllStudentList: "/v1/auth/students/list2",
  },
  finance: {
    paymentPayout: "/v1/admin/transactions/payout/update",
  },
  PaidAds: {
    getPaidAdsLeadList: "/v1/admin/academic/student-leads",
    getRenquiredLeadList: "/v1/admin/academic/reinquired-student-leads",
  },
  Seo: {
    uploadSeoImg: "/v1/admin/seo/upload-media",
    addFaqType: "/v1/admin/seo/faqtype-add",
    getFaqTypeList: "/v1/admin/seo/faqtype-get",
    addSeoData: "/v1/admin/seo/add",
    getSeoData: "/v1/admin/seo/get",
  },
};
