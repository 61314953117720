import { Route, Routes } from "react-router-dom";
import Sidebar from "../../Components/sidebar";
import WIP from "../wip";
import SocialMediasidebarData from "./socialMediasidebarData";
import CreateBlog from "./createBlog";
import CreateCountrySEO from "./createCountrySEO";
import UpdateBlog from "./updateBlog";
import CreateCourseTypeSEO from "./createCourseTypeSEO";
import CreateExamGradeSEO from "./createExamGradeSEO";
import CreateSubjectSEO from "./createSubjectSEO ";

export default function SocialMediaDashboard() {
  return (
    <div className="AppBox">
      <Sidebar data={SocialMediasidebarData?.SocialMediasidebarData} />

      <div className="dashboard">
        <Routes>
          <Route path="/" element={<CreateBlog />} />
          <Route path="createBlog" element={<CreateBlog />} />
          <Route path="updateBlog" element={<UpdateBlog />} />
          <Route path="createCountrySEO" element={<CreateCountrySEO />} />
          <Route path="createCourseTypeSEO" element={<CreateCourseTypeSEO />} />
          <Route path="createExamGradeSEO" element={<CreateExamGradeSEO />} />
          <Route path="createSubjectSEO" element={<CreateSubjectSEO />} />
          <Route path="*" element={<WIP />} />
        </Routes>
      </div>
    </div>
  );
}
