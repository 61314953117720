import React, { useState } from "react";
import "../Assets/css/AccordianTemplate.css";
import parser from "html-react-parser";

export default function AccordianTemplate(props: any) {
  return (
    <div className="accordion" id="accordionExample">
      {JSON.parse(props?.data?.desc)?.map((card: any, index: number) => (
        <div className="accordion-item mb-2 rounded">
          <h2 className="accordion-header" id={"heading" + index}>
            <button
              className="accordion-button collapsed rounded"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={"#collapse" + index}
              aria-expanded="false"
              aria-controls={"collapse" + index}
            >
              {card?.Heading}
            </button>
          </h2>
          <div
            id={"collapse" + index}
            className="accordion-collapse collapse"
            aria-labelledby={"heading" + index}
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body text-dark">{parser(card?.Text)}</div>
          </div>
        </div>
      ))}
    </div>
  );
}
