import moment from "moment";
import "../Assets/css/demoRequestCard.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import RatingStar from "./ratingStar";
import { useState } from "react";
export default function ClassRequestCard(props: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const labels: { [index: string]: string } = {
    1: "Bad",
    2: "Below Average",
    3: "Average",
    4: "Good",
    5: "Excellent",
  };
  const [rating, setRating] = useState<any>({
    id: props?.data?.rating,
    value: props?.data?.rating,
  });
  const onClickJoinClass = (classType: string) => {
    redirectToJitsi();
  };

  const redirectToJitsi = () => {
    let JitSiURL: any = `jitsi-meet://meet.lurnigo.com/${props?.data?.roomName}`;
    window.location.href = JitSiURL;
  };

  function onClickFeedbackButton(val: string): void {
    navigate("/TeachersPanel/" + val, {
      state: {
        classData: props?.data,
      },
    });
  }
  function onClickStdViewFdkBtn(val: string): void {
    navigate("/OperationPanel/" + val, {
      state: { liveClassData: props?.data, isFeedback: true },
    });
  }
  function onClickStudentFeedbackButton(val: string): void {
    navigate("/OperationPanel/" + val, {
      state: { liveClassData: props?.data, isFeedback: true },
    });
  }

  return (
    <>
      <div className="demoRequestCard rounded card h-100">
        <div className="container p-3 h-100">
          <div className="row d-flex align-items-between h-100">
            <div>
              <div className="d-flex justify-content-between">
                <h5 className="fw-bold">{`${props?.data?.firstName} ${props?.data?.lastName}`}</h5>
                {props?.pageName === "teacherPanelClassConducted" ? (
                  <div className="ms-1 fw-bold">CONDUCTED</div>
                ) : null}
              </div>
              <div className="flex-column d-flex">
                <div className="d-flex justify-content-between">
                  <div className="DemoRecordcourseType badge badge-pill badge-info p-1 pr-2 mb-3">
                    {props?.data?.coursetypeName}
                  </div>
                  <div className="DemoRecordcourseType badge badge-pill badge-info p-1 pr-2 mb-3">
                    <div>
                      {`CLASS COUNT - ${
                        props?.data?.totalDemoCount >= 0
                          ? props?.data?.totalDemoCount
                          : 0
                      }`}
                    </div>
                  </div>
                </div>

                {props?.data?.gradedesc ? (
                  <div className="DemoRecordgrade badge badge-pill badge-info p-1 pr-2">
                    {props?.data?.gradedesc}
                  </div>
                ) : (
                  <div className="">{props?.data?.examtypeName}</div>
                )}
              </div>
              <div className="mt-1 mb-1">{props?.data?.countryName}</div>

              <div className="d-flex col-md-12">
                <div className="fw-bold">Schedule Date :</div>
                <div className="font14 ms-1">
                  {`${moment(props?.data?.scheduleFrom).format("DD-MM-YYYY")} 
              ${props?.data?.time}
              ${props?.data?.timezone}`}
                </div>
              </div>
              {props?.pageName === "teacherPanelClassSchedule" ? (
                <div className="d-flex">
                  <div className="fw-bold">Indian date & Time : </div>
                  <div className="ms-1">
                    <h6>
                      {moment
                        .utc(props?.data?.Indian_DateTime)
                        .format("DD-MM-YYYY, HH:mm:ss")}
                    </h6>
                  </div>
                </div>
              ) : null}
              {props?.data?.subjectName ? (
                <div className="d-flex">
                  <div className="fw-bold">Subject : </div>
                  <div className="ms-1">{props?.data?.subjectName}</div>
                </div>
              ) : null}
              {props?.pageName === "salesPanelClassJoin" ? (
                <div className="d-flex">
                  <div className="fw-bold">Teacher Name : </div>
                  <div className="ms-1">
                    {props?.data?.name ? props?.data?.name : "-"}
                  </div>
                </div>
              ) : null}
              {props?.data?.topicName ? (
                <div className="d-flex">
                  <div className="fw-bold">Topic : </div>
                  <div className="ms-1">{props?.data?.topicName}</div>
                </div>
              ) : null}
              {props?.data?.subTopicName ? (
                <div className="d-flex">
                  <div className="fw-bold">Sub-Topic : </div>
                  <div className="ms-1">{props?.data?.subTopicName}</div>
                </div>
              ) : null}
              {props?.pageName === "liveClassStudentFeedback" ||
              props?.pageName === "teacherPanelClassConducted" ? (
                <div className="d-flex">
                  <div className="d-flex font16 mb-2 fw-bold col-md-5">
                    <span>Rating:</span>
                    <div className="d-flex">
                      <RatingStar
                        value={rating?.id}
                        label={labels}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            {props?.pageName === "teacherPanelClassConducted" ? (
              <div className=" align-items-end">
                <button
                  type="button"
                  className="btn btn-success w-100 mt-2"
                  onClick={() => onClickFeedbackButton("TeacherLiveFeedback")}
                >
                  {props?.data?.feedbackStatus === 2
                    ? "VIEW FEEDBACK"
                    : props?.name}
                </button>
              </div>
            ) : props?.pageName === "liveClassStudentFeedback" ? (
              <div className="align-items-end">
                <button
                  type="button"
                  className="btn lightOrange w-100 p-1"
                  onClick={() => onClickStdViewFdkBtn("StudentLiveFeedback")}
                >
                  {"STUDENT FEEDBACK"}
                </button>
                <button
                  type="button"
                  className="btn btn-success w-100 mt-2"
                  onClick={() =>
                    onClickStudentFeedbackButton("StudentLiveFeedback")
                  }
                >
                  {props?.name}
                </button>
              </div>
            ) : (
              <div className="d-flex align-items-end">
                <button
                  type="button"
                  className="btn btn-dark mb-2 w-100"
                  onClick={() => onClickJoinClass("LIVE CLASS")}
                  disabled={props?.data?.status === 1 ? true : false}
                >
                  {props?.data?.status === 1 ? "START CLASS" : "REJOIN CLASS"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
