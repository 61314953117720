import moment from "moment";
import { useDispatch } from "react-redux";
import "../Assets/css/demoRequestCard.css";
import DemoPopup from "./demoPopup";
import { useState } from "react";
import RatingStar from "./ratingStar";
import DemoTeacherPopup from "./demoTecherPopup";
export default function DemoRequestCard(props: any) {
  const dispatch = useDispatch();
  const [openPopup, setOpenPopup] = useState(false);
  const [showEditSchedule, setShowEditSchedule] = useState(false);
  const [showDemoSchedule, setShowDemoSchedule] = useState(false);
  const labels: { [index: string]: string } = {
    1: "Bad",
    2: "Below Average",
    3: "Average",
    4: "Good",
    5: "Excellent",
  };

  const [rating, setRating] = useState<any>({
    id: props?.data?.rating,
    value: labels[props?.data?.rating],
  });
  const onClickButton = () => {
    if (props?.pageName === "salesPanelDemoRequest") {
      setOpenPopup(true);
      setShowEditSchedule(true);
    } else if (props?.pageName === "salesPanelDemoSchedule") {
      setOpenPopup(true);
      setShowDemoSchedule(true);
    } else if (props?.pageName === "salesPanelDemoConducted") {
      setOpenPopup(true);
      setShowDemoSchedule(true);
    } else if (props?.pageName === "salesPanelDemoCancel") {
      setOpenPopup(true);
      setShowDemoSchedule(true);
    } else if (props?.pageName === "teacherPanelDemoSchedule") {
      setOpenPopup(true);
    } else if (props?.pageName === "teacherPanelDemoConducted") {
      setOpenPopup(true);
    } else if (props?.pageName === "salesPanelDemoJoin") {
      window.open(
        props?.data?.attendeeUrl,
        "_blank",
        "width=" +
          window.screen?.availWidth +
          ",height=" +
          window.screen?.availHeight
      );
    }
  };

  const onClickJoinClass = (classType: string) => {
    if (props?.pageName === "salesPanelDemoRequest") {
      setOpenPopup(true);
      setShowEditSchedule(true);
    } else if (props?.pageName === "salesPanelDemoSchedule") {
      setOpenPopup(true);
      setShowDemoSchedule(true);
    } else if (props?.pageName === "salesPanelDemoConducted") {
      setOpenPopup(true);
      setShowDemoSchedule(true);
    } else if (props?.pageName === "salesPanelDemoCancel") {
      setOpenPopup(true);
      setShowDemoSchedule(true);
    } else if (props?.pageName === "teacherPanelDemoSchedule") {
      setOpenPopup(true);
    } else if (props?.pageName === "teacherPanelDemoConducted") {
      setOpenPopup(true);
    } else if (props?.pageName === "salesPanelDemoJoin") {
      redirectToJitsi();
    }
  };

  const redirectToJitsi = () => {
    // let JitSiURL: any = `jitsi-meet://meet.lurnigo.com/${props?.item?.roomName}#userInfo.displayName=%22${userName}%22&userInfo.email=%22${userEmail}%22&config.prejoinConfig.enabled=false`;
    let JitSiURL: any = `jitsi-meet://meet.lurnigo.com/${props?.item?.roomName}`;
    window.location.href = JitSiURL;
  };

  const handleClose = (value: any) => {
    setOpenPopup(false);
  };

  function tConvert(time: any) {
    if (props?.pageName !== "teacherPanelClassSchedule") {
      time = time
        ?.toString()
        ?.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        time = time.slice(1);
        time[5] = +time[0] < 12 ? "AM" : "PM";
        time[0] = +time[0] % 12 || 12;
      }
      return time.join("");
    }
  }
  return (
    <>
      <div className="demoRequestCard rounded card h-100">
        <div className="container p-3 h-100">
          <div className="row d-flex align-items-between h-100">
            <div>
              <div className="d-flex justify-content-between">
                <h5 className="fw-bold">{`${
                  props?.data?.firstName
                    ? props?.data?.firstName.toUpperCase()
                    : null
                } ${
                  props?.data?.lastName
                    ? props?.data?.lastName.toUpperCase()
                    : null
                }`}</h5>

                {props?.pageName === "teacherPanelDemoSchedule" ||
                props?.pageName === "teacherPanelDemoConducted" ? null : (
                  <div className="DemoRecordgrade badge badge-pill badge-info p-1 pr-2 my-2">
                    <div>{props?.data?.source}</div>
                  </div>
                )}
              </div>
              <div className="flex-column d-flex">
                <div className="d-flex justify-content-between">
                  <div className="DemoRecordcourseType badge badge-pill badge-info p-1 pr-2 mb-3">
                    {props?.data?.coursetypeName}
                  </div>
                  {props?.pageName === "teacherPanelDemoConducted" ||
                  props?.pageName === "teacherPanelDemoSchedule" ? null : (
                    <div className="DemoRecordcourseType badge badge-pill badge-info p-1 pr-2 mb-3">
                      <div>
                        {`DEMO COUNT - ${
                          props?.data?.totalDemoCount >= 0
                            ? props?.data?.totalDemoCount
                            : 0
                        }`}
                      </div>
                    </div>
                  )}
                </div>

                {props?.data?.gradedesc ? (
                  <div className="DemoRecordgrade badge badge-pill badge-info p-1 pr-2">
                    {props?.data?.gradedesc}
                  </div>
                ) : (
                  <div className="">{props?.data?.examtypeName}</div>
                )}
              </div>
              <div className="mt-1 mb-1">{props?.data?.countryName}</div>
              <div className="d-flex row">
                <div className="fw-bold col-md-6">Demo Request Date :</div>
                <div className="font14  col-md-6">
                  {moment(props?.data?.createdAt).format("DD-MM-YYYY , LTS")}
                </div>
              </div>
              <div className="d-flex row">
                <div className="fw-bold col-md-6">Demo Date :</div>
                <div className="font14 col-md-6">
                  {`${moment(props?.data?.date).format("DD-MM-YYYY")} 
              ${tConvert(props?.data?.time)}
              ${props?.data?.timezone}`}
                </div>
              </div>
              {props?.pageName === "teacherPanelDemoSchedule" ||
              props?.pageName === "salesPanelDemoRequest" ||
              props?.pageName === "salesPanelDemoSchedule" ||
              props?.pageName === "salesPanelDemoJoin" ? (
                <div className="d-flex row">
                  <div className="fw-bold col-md-6">Indian Date & Time :</div>
                  <div className="font14 col-md-6">
                    {moment
                      .utc(props?.data?.Indian_DateTime)
                      .format("DD-MM-YYYY, HH:mm:ss ")}
                  </div>
                </div>
              ) : null}
              {props?.data?.sourceId === 2 ? (
                <div className="d-flex row">
                  <div className="fw-bold col-md-6">
                    Demo Scheduling Person :
                  </div>
                  <div className=" col-md-6">{props?.data?.memberName}</div>
                </div>
              ) : null}
              {props?.data?.sourceId === 2 ? (
                <div className="d-flex row">
                  <div className="fw-bold col-md-6">Company Name :</div>
                  <div className=" col-md-6">{props?.data?.DSACompanyName}</div>
                </div>
              ) : null}
              {props?.data?.subjectName ? (
                <div className="d-flex row">
                  <div className="fw-bold col-md-6">Subject : </div>
                  <div className=" col-md-6">{props?.data?.subjectName}</div>
                </div>
              ) : null}
              {props?.pageName === "salesPanelDemoSchedule" &&
              props?.data?.link ? (
                <div className="d-flex row">
                  <div className="fw-bold col-md-6">Link :</div>
                  <div className="ms-1 col-md-6">{props?.data?.link}</div>
                </div>
              ) : null}
              {props?.pageName === "salesPanelDemoSchedule" ? (
                <div className="d-flex row">
                  <div className="fw-bold col-md-6">Teacher Name :</div>
                  <div className="ms-0 col-md-6">
                    {props?.data?.teacherName}
                  </div>
                </div>
              ) : null}
              {props?.pageName === "salesPanelDemoConducted" ||
              props?.pageName === "teacherPanelDemoConducted" ? (
                <div className="d-flex">
                  <div className="d-flex font16 mb-2 fw-bold">
                    <span>Rating:</span>
                    <div>
                      <RatingStar
                        value={rating?.id}
                        label={labels}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            {props?.pageName === "salesPanelDemoJoin" ? (
              <div className="d-flex align-items-end">
                {/* <button
                  type="button"
                  className="btn btn-success w-100 mt-2"
                  onClick={onClickButton}
                  disabled={props?.data?.attendeeUrl ? false : true}
                >
                  {props?.name}
                </button> */}
                <button
                  type="button"
                  className="btn btn-dark mb-2 w-100"
                  onClick={() => onClickJoinClass("LIVE CLASS")}
                  disabled={props?.data?.statusId === 2 ? true : false}
                >
                  {props?.data?.statusId === 2 ? "START DEMO" : " DEMO "}
                </button>
              </div>
            ) : props?.pageName === "salesPanelDemoRequest" ? (
              <div className="d-flex align-items-end">
                <button
                  type="button"
                  className="btn btn-dark mb-2 w-100"
                  onClick={() => onClickJoinClass("LIVE CLASS")}
                >
                  {" DEMO ALIGN"}
                </button>
              </div>
            ) : (
              <div className="d-flex align-items-end">
                {/* <button
                  type="button"
                  className="btn btn-success w-100 mt-2"
                  onClick={onClickButton}
                >
                  {props?.name}
                </button> */}
                <button
                  type="button"
                  className="btn btn-dark mb-2 w-100"
                  onClick={() => onClickJoinClass("LIVE CLASS")}
                  disabled={props?.data?.statusId === 2 ? true : false}
                >
                  {props?.data?.statusId === 2 ? "START DEMO" : "REJOIN DEMO"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {props?.pageName === "teacherPanelDemoSchedule" ? (
        <DemoTeacherPopup
          open={openPopup}
          handleClose={handleClose}
          showEditSchedule={showEditSchedule}
          showDemoSchedule={showDemoSchedule}
          data={props?.data}
          pageName={props?.pageName}
          getDemoReq={props?.getDemoReq}
        />
      ) : (
        <DemoPopup
          open={openPopup}
          handleClose={handleClose}
          showEditSchedule={showEditSchedule}
          showDemoSchedule={showDemoSchedule}
          data={props?.data}
          pageName={props?.pageName}
          getDemoReq={props?.getDemoReq}
        />
      )}
    </>
  );
}
