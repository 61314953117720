import React, { useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useDispatch } from "react-redux";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import {
  AffiliatePayoutSave,
  DsaPayoutSave,
  PayoutSave,
} from "../Redux/Actions/AdminAction";

export default function PaymentApproveReject(props: any) {
  const dispatch = useDispatch();

  useEffect(() => {}, []);

  function closePopup(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void {
    props.handleClose();
  }

  const handleOpenPayPopUp = (value: any) => {
    if (props?.pageName === "DsaPaymentPayout") {
      if (value === "approve") {
        let selectedPayment = props?.selectPayment.filter((elm: any) =>
          elm.isSelected === true ? (elm.status = "approved") : false
        );
        const postdata = {
          payout: selectedPayment,
        };
        dispatch(
          DsaPayoutSave(
            postdata,
            (res: any) => {
              props.handleClose();
              props?.getPaymentList();
            },
            () => {}
          )
        );
      } else if (value === "reject") {
        let selectedPayment = props?.selectPayment.filter((elm: any) =>
          elm.isSelected === true ? (elm.status = "rejected") : false
        );
        const postdata = {
          payout: selectedPayment,
        };
        dispatch(
          DsaPayoutSave(
            postdata,
            (res: any) => {
              props.handleClose();
              props?.getPaymentList();
            },
            () => {}
          )
        );
      }
    } else if (props?.pageName === "AffiliatePaymentPayout") {
      if (value === "approve") {
        let selectedPayment = props?.selectPayment.filter((elm: any) =>
          elm.isSelected === true ? (elm.status = "approved") : false
        );
        const postdata = {
          payout: selectedPayment,
        };
        dispatch(
          AffiliatePayoutSave(
            postdata,
            (res: any) => {
              props.handleClose();
              props?.getPaymentList();
            },
            () => {}
          )
        );
      } else if (value === "reject") {
        let selectedPayment = props?.selectPayment.filter((elm: any) =>
          elm.isSelected === true ? (elm.status = "rejected") : false
        );
        const postdata = {
          payout: selectedPayment,
        };
        dispatch(
          AffiliatePayoutSave(
            postdata,
            (res: any) => {
              props.handleClose();
              props?.getPaymentList();
            },
            () => {}
          )
        );
      }
    } else if (props?.pageName === "PaymentPayout") {
      if (value === "approve") {
        let selectedPayment = props?.selectPayment.filter((elm: any) =>
          elm.isSelected === true ? (elm.status = "approved") : false
        );
        const postdata = {
          payout: selectedPayment,
        };
        dispatch(
          PayoutSave(
            postdata,
            (res: any) => {
              props.handleClose();
              props?.getPaymentList();
            },
            () => {}
          )
        );
      } else if (value === "reject") {
        let selectedPayment = props?.selectPayment.filter((elm: any) =>
          elm.isSelected === true ? (elm.status = "rejected") : false
        );
        const postdata = {
          payout: selectedPayment,
        };
        dispatch(
          PayoutSave(
            postdata,
            (res: any) => {
              props.handleClose();
              props?.getPaymentList();
            },
            () => {}
          )
        );
      }
    }
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modelChat"
        open={props.openPopup}
        // onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.openPopup}>
          <div className="modeldivChat ">
            <div className="  mt-0 justify-content-center  ">
              <div className="d-flex">
                <p className="fw-bolder fs-5 text-primary">Payment Status</p>
                <div className="col mb-2 d-flex justify-content-end ">
                  <ClearOutlinedIcon
                    className="colorblue cursor font28"
                    onClick={closePopup}
                  />
                </div>
              </div>

              <div className="demoCard ">
                <div className="row mt-2">
                  <div className="d-flex justify-content-center col-md-12">
                    <div className=" ">
                      <button
                        className={
                          props?.selectPayment?.length > 0
                            ? "btn btn-success "
                            : " btn btn-secondary"
                        }
                        onClick={(value) => handleOpenPayPopUp("approve")}
                      >
                        Approve
                      </button>
                    </div>
                    <div className="ms-4">
                      <button
                        className={
                          props?.selectPayment?.length > 0
                            ? "btn btn-danger "
                            : " btn btn-secondary"
                        }
                        onClick={(value) => handleOpenPayPopUp("reject")}
                      >
                        Reject
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
