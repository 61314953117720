import { Route, Routes } from "react-router-dom";
import Sidebar from "../../Components/sidebar";
import financeSidebarData from "./financeSidebarData";
import Finance from "./finance";
import WIP from "../wip";
import PaymentStatus from "./paymentStatus";
import TransactionHistory from "./TransactionHistory";
import DsaPaymentPayout from "./dsaPaymentPayout";
import AffiliatePaymentPayout from "./affiliatePaymentPayout";
import PaymentPayout from "./paymentPayout";
import UpcomingInstallments from "./upcomingInstallments";
import OverdueInstallments from "./overdueInstallments";
export default function FinanceDashboard() {
  return (
    <div className="AppBox">
      <Sidebar data={financeSidebarData?.FinanceSidebarData} />
      <div className="dashboard">
        <Routes>
          <Route path="/" element={<Finance />} />
          <Route path="finance" element={<Finance />} />
          <Route path="transaction_history" element={<TransactionHistory />} />
          <Route path="payment_status" element={<PaymentStatus />} />
          <Route path="dsaPaymentPayout" element={<DsaPaymentPayout />} />
          <Route
            path="affiliatePaymentPayout"
            element={<AffiliatePaymentPayout />}
          />
          <Route path="paymentPayout" element={<PaymentPayout />} />
          <Route
            path="upcomingInstallments"
            element={<UpcomingInstallments />}
          />
          <Route path="overdueInstallments" element={<OverdueInstallments />} />
          <Route path="*" element={<WIP />} />
        </Routes>
      </div>
    </div>
  );
}
