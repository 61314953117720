import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useDispatch } from "react-redux";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

export default function RecordedVideoPopup(props: any) {
  const dispatch = useDispatch();

  useEffect(() => {}, []);

  function closePopup(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void {
    props.handleClose();
  }

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modelChat"
        open={props.openPopup}
        // onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.openPopup}>
          <div className="recordedVideoPopup ">
            <div className="  mt-0 justify-content-center  ">
              <div className="d-flex">
                <p className="fw-bolder fs-5 text-primary">Recorded Video</p>
                <div className="col mb-2 d-flex justify-content-end ">
                  <ClearOutlinedIcon
                    className="colorblue cursor font28"
                    onClick={closePopup}
                  />
                </div>
              </div>

              <div className=" mt-5">
                <div className="row mt-2">
                  <div className="d-flex justify-content-center">
                    <video
                      className="videoClass"
                      controls
                      style={{
                        maxWidth: "800px",
                        maxHeight: "800px",
                      }}
                    >
                      <source src={props?.recordedPath} type="video/mp4" />
                      <source src="movie.ogg" type="video/ogg" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
