import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "../Assets/css/horizontalTab.css";
import parser from "html-react-parser";

function HorizontalTabTemplet(props: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  return (
    <div className=" ">
      <div className="horizontalcontainer mt-5">
        <ul className="nav nav-tabs nav-justified">
          <li className="nav-item d-flex">
            {JSON.parse(props?.data?.desc)?.map((c: any, index: any) => (
              <a
                className={`nav-link text-lowercase text-capitalize px-3 font16 ${
                  index === 0 ? "active" : ""
                }`}
                id={"pills-" + index + "-tab"}
                data-bs-toggle="pill"
                data-bs-target={"#pills-" + index}
                type="button"
                role="tab"
                aria-controls={"pills-" + index}
                aria-selected="true"
                href="#"
              >
                {c.Heading}
              </a>
            ))}
          </li>
        </ul>
        <div className="tab-content " id="pills-tabContent">
          {JSON.parse(props?.data?.desc)?.map((c: any, index: any) => {
            console.log("data", c);

            return (
              <div
                key={index.toString()}
                className={`tab-pane fade p-3 ${
                  index === 0 ? "show active" : ""
                }`}
                id={"pills-" + index}
                role="tabpanel"
                aria-labelledby={"pills-" + index + "-tab"}
                tabIndex={index}
              >
                <div className=" ">
                  <p>{parser(c?.Text)}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default HorizontalTabTemplet;
