import { useEffect, useState } from "react";
import LabelField from "./labelField";
import CustomDropdown from "./customDropdown";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DatePicker from "./datePicker";
import Time from "./time";
import Addmorebtn from "./addmorebtn";
import {
  getLectureList,
  getSubTopicList,
  uploadRecVideo,
} from "../Redux/Actions/AdminAction";
import { useDispatch } from "react-redux";
import ImagePickerAndViewer from "./imagePickerAndViewer";
import { validateIsFileVideoType } from "../Utils/validators";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../Redux/Actions/snackbarAction";
import { validateIsFileVideoTypeErrMsg } from "../Utils/errorMsg";

interface props {
  courseType: any;
  exam: any;
  subject: any;
  grade: any;
  topicNameList: any;
  data: any;
  index: number;
  titleClassScheduleArr: any;
  setTitleClassScheduleArr: any;
  isResetForm?: boolean;
}

export default function ScheduleRecorded(props: props) {
  const initialValInner = [
    {
      title: "",
      scheduleFrom: "",
      scheduleTo: "",
      sessionTime: "",
      s3Path: "",
    },
  ];

  const dispatch: Function = useDispatch();
  const [subTopicNameList, setSubTopicNameList] = useState<any>([]);
  const [lectureList, setLectureList] = useState<any>([]);
  const [uploadVideo, setUploadVideo] = useState<any>("");
  const [loadVideo, setLoadVideo] = useState<any>("");

  const handleCrossLive = (index: any) => {
    const newArray = [...props?.titleClassScheduleArr];
    if (index > -1) {
      newArray.splice(index, 1);
    }
    props?.setTitleClassScheduleArr(newArray);
  };
  const handleCrossLiveInner = (index: any) => {
    const newArray = [...props?.titleClassScheduleArr];
    if (index > -1) {
      newArray[props?.index].lectureTitle?.splice(index, 1);
    }
    props?.setTitleClassScheduleArr(newArray);
  };
  const onPressAddInner = () => {
    const newArray = [...props?.titleClassScheduleArr];
    newArray[props?.index].lectureTitle?.push(initialValInner[0]);
    props?.setTitleClassScheduleArr(newArray);
  };
  const onChangeDate = (val: any, index: any, key: any, Innerindex: any) => {
    let newArray = JSON.parse(JSON.stringify(props?.titleClassScheduleArr));
    if (key && Innerindex !== "") {
      if (key === "scheduleFrom") {
        newArray[index].lectureTitle[Innerindex]["scheduleTo"] = val;
        newArray[index].lectureTitle[Innerindex][key] = val;
      } else if (key === "lectureId") {
        newArray[index].lectureTitle[Innerindex]["title"] = val?.name;
      } else if (key === "s3Path") {
        if (!validateIsFileVideoType(val)) {
          dispatch(showErrorSnackbar(validateIsFileVideoTypeErrMsg) as any);
          return;
        }
        const reader = new FileReader();
        const file = val.target.files[0];
        reader.onloadend = () => {
          setLoadVideo(reader.result);
        };
        reader.readAsDataURL(file);
        dispatch(
          uploadRecVideo(
            file,
            (res: any) => {
              if (res) {
                dispatch(
                  showSuccessSnackbar("File Succesfully Uploaded") as any
                );
                setUploadVideo(res.filePath);
                newArray[index].lectureTitle[Innerindex]["s3Path"] =
                  res.filePath;
              } else {
                dispatch(showErrorSnackbar("File Not Uploaded") as any);
              }
            },
            () => {}
          )
        );
      } else {
        newArray[index].lectureTitle[Innerindex][key] = val;
      }
      props?.setTitleClassScheduleArr(newArray);
    } else {
      if (key === "topicId") {
        const postData = {
          coursetypeId: props?.courseType,
          examtypeId: props?.exam,
          subjectId: props?.subject,
          topicId: val?._id,
          gradeId: props?.grade,
        };
        if (
          postData["examtypeId"] === null ||
          postData["examtypeId"] === undefined ||
          postData["examtypeId"] === ""
        ) {
          delete postData["examtypeId"];
        }
        if (
          postData["gradeId"] === null ||
          postData["gradeId"] === undefined ||
          postData["gradeId"] === ""
        ) {
          delete postData["gradeId"];
        }

        dispatch(
          getSubTopicList(
            postData,
            (res: any) => {
              setSubTopicNameList(res);
            },
            () => {}
          )
        );
        // newArray[index][key] = val?._id;
      } else if (key === "subTopicId") {
        const postData = {
          coursetypeId: props?.courseType,
          examtypeId: props?.exam,
          subjectId: props?.subject,
          topicId: newArray[index]?.topicId,
          gradeId: props?.grade,
          subTopicId: val?._id,
        };
        if (
          postData["examtypeId"] === null ||
          postData["examtypeId"] === undefined ||
          postData["examtypeId"] === ""
        ) {
          delete postData["examtypeId"];
        }
        if (
          postData["gradeId"] === null ||
          postData["gradeId"] === undefined ||
          postData["gradeId"] === ""
        ) {
          delete postData["gradeId"];
        }
        dispatch(
          getLectureList(
            postData,
            (res: any) => {
              setLectureList(res);
            },
            () => {}
          )
        );
      }
      newArray[index][key] = val?._id;
      props?.setTitleClassScheduleArr(newArray);
    }
  };

  useEffect(() => {
    if (props?.isResetForm) {
      setLectureList([]);
      setSubTopicNameList([]);
    }
  }, [props?.isResetForm]);

  return (
    <>
      <span className="col-8 d-flex justify-content-end removeButton">
        {props?.titleClassScheduleArr.length === 1 ? null : (
          <HighlightOffIcon
            className="cursor"
            color="primary"
            fontSize="small"
            onClick={() => handleCrossLive(props?.index)}
          />
        )}
      </span>
      <div className="col-8 Justify-content-between border py-3 px-3 mb-4 mt-3">
        <>
          <div className="d-flex Justify-content-between">
            <div className="col-3 align-item-center d-flex">
              <LabelField lableName={"Topic Name"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Topic Name"
                setInputText={(value: any) =>
                  onChangeDate(value, props?.index, "topicId", "")
                }
                value={props?.data?.topicId}
                options={props?.topicNameList}
                key={"topicId"}
                reset={props?.isResetForm}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-3 align-item-center d-flex">
              <LabelField lableName={"Sub-Topic Name"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Sub-Topic Name"
                setInputText={(value: any) =>
                  onChangeDate(value, props?.index, "subTopicId", "")
                }
                value={props?.data?.subTopicId}
                options={subTopicNameList}
                key={"subTopicId"}
                reset={props?.isResetForm}
              />
            </div>
          </div>
        </>
        <>
          {props?.data?.lectureTitle?.map((value: any, Innerindex: any) => {
            const {
              lectureId,
              title,
              scheduleFrom,
              scheduleTo,
              sessionTime,
              s3Path,
            } = value;
            return (
              <>
                <span className="col-12 d-flex justify-content-end removeButton">
                  {props?.data?.lectureTitle.length === 1 ? null : (
                    <HighlightOffIcon
                      className="cursor"
                      color="primary"
                      fontSize="small"
                      onClick={() => handleCrossLiveInner(Innerindex)}
                    />
                  )}
                </span>
                <div className="border p-2">
                  <div className="d-flex Justify-content-between">
                    <div className="col-3 align-item-center d-flex">
                      <LabelField lableName={"Lecture Title"} />
                    </div>
                    <div className="col-6 mt-3">
                      <CustomDropdown
                        lableName="Select Lecture Name "
                        setInputText={(value: any) =>
                          onChangeDate(
                            value,
                            props?.index,
                            "lectureId",
                            Innerindex
                          )
                        }
                        value={lectureId}
                        options={lectureList}
                        reset={props?.isResetForm}
                      />
                    </div>
                  </div>

                  <div className="d-flex col-8 Justify-content-between mt-4">
                    <div className="col-4 align-item-center d-flex">
                      <LabelField lableName={"Class Schedule"} />
                    </div>
                    <div className="col-10 d-flex ps-2 ">
                      <div className="col-4">
                        <div className="fw-bold">From Date</div>
                        <DatePicker
                          key={"scheduleFrom"}
                          value={scheduleFrom}
                          setInputText={(value: any) =>
                            onChangeDate(
                              value,
                              props?.index,
                              "scheduleFrom",
                              Innerindex
                            )
                          }
                        />
                      </div>
                      <div className="col-4">
                        <div className="fw-bold">To Date</div>
                        <DatePicker
                          key={"scheduleTo"}
                          value={scheduleTo}
                          setInputText={(value: any) =>
                            onChangeDate(
                              value,
                              props?.index,
                              "scheduleTo",
                              Innerindex
                            )
                          }
                          disabled={true}
                        />
                      </div>
                      <div className="col-4">
                        <div className="fw-bold">Session Time</div>
                        <Time
                          value={sessionTime}
                          key={"sessionTime"}
                          setInputText={(value: any) => {
                            onChangeDate(
                              value,
                              props?.index,
                              "sessionTime",
                              Innerindex
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex Justify-content-between">
                    <div className="col-3 align-item-center d-flex">
                      <LabelField lableName={"S3 URL"} />
                    </div>
                    <div className="col-6 mt-3">
                      <ImagePickerAndViewer
                        lableName={"Video"}
                        value={s3Path}
                        handleChange={(value: any) =>
                          onChangeDate(
                            value,
                            props?.index,
                            "s3Path",
                            Innerindex
                          )
                        }
                        doctype={"video"}
                      />
                    </div>
                  </div>
                </div>
              </>
            );
          })}
          <div className=" col-12 d-flex justify-content-end mt-2 mb-3">
            <Addmorebtn
              name="Add Title More"
              handleClick={() => onPressAddInner()}
            />
          </div>
        </>
      </div>
    </>
  );
}
