export default {
  logo: require("../Assets/images/LurniGoLogo.png"),
  profile: require("../Assets/images/profile.png"),
  totalEarning: require("../Assets/images/totalEarning.png"),
  payment: require("../Assets/images/payment.png"),
  pending: require("../Assets/images/pending.png"),
  bannerImage: require("../Assets/images/banner.jpg"),
  pdfimage: require("../Assets/images/pdf.png"),

  /*************** View Lecture Data ************************ */
  coinFlipSound: require("../Assets/Audio/coinFlipSound.mp3"),
};
