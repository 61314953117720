export default {
  SocialMediasidebarData: [
    {
      title: "BLOGS",
      subNav: [
        {
          title: "CREATE",
          path: "createBlog",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "updateBlog",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "Country SEO",
      path: "createCountrySEO",
    },
    {
      title: "CourseType SEO",
      path: "createCourseTypeSEO",
    },
    {
      title: "Exam / Grade SEO",
      path: "createExamGradeSEO",
    },
    {
      title: "Subject SEO",
      path: "createSubjectSEO",
    },
    // {
    //   title: "COUNSELLOR PAYMENTS",
    //   path: "counsellorPayments",
    // },
    // {
    //   title: "STUDENT PAYMENT LINK",
    //   path: "studentPaymentLink",
    // },
  ],
};
