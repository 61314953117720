import React, { useState, useEffect } from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-monokai";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
// import { loadPyodide } from "pyodide";

function PythonCodeEditor(props: any) {
  let propsData: any = {};
  console.log('props?.code', props?.code);
  if (props?.code && typeof props?.code === 'string') {
    propsData = JSON.parse(props.code);
  }
  else{
    propsData = props.code;
  }
  const [code, setCode] = useState(propsData?.python ? propsData?.python : "");
  const [output, setOutput] = useState("");
  const [pyodideInstance, setPyodideInstance] = useState<any>(null);
  const [csvContent, setCsvContent] = useState(null);
  const [imageDownloadUrl, setImageDownloadUrl] = useState<any>(null);

  useEffect(() => {
    const initializePyodide = async () => {
      try {
        // Load Pyodide from the CDN
        const pyodide = await (window as any)?.loadPyodide({
          indexURL: "https://cdn.jsdelivr.net/pyodide/v0.23.3/full/",
        });
  
        await pyodide.loadPackage("micropip");
        await pyodide.runPythonAsync(`
          import micropip
          await micropip.install(['numpy', 'pandas', 'matplotlib', 'seaborn', 'scipy'])
        `);
  
        setPyodideInstance(pyodide);
      } catch (error) {
        console.error("Error initializing Pyodide:", error);
      }
    };
  
    initializePyodide();
  }, []);

  const handleFileUpload = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e: any) => {
      setCsvContent(e.target.result);
    };

    if (file) {
      reader.readAsText(file);
    }
  };

  const executeCode = async () => {
    setImageDownloadUrl("");
    setOutput("");
    if (pyodideInstance) {
      try {
        // Redirect stdout and stderr to capture the output
        pyodideInstance.runPython(`
          import sys
          from io import StringIO
          sys.stdout = sys.stderr = StringIO()
        `);

        // Check if CSV content is available and pass it to Pyodide's globals
        if (csvContent) {
          pyodideInstance.globals.set("csv_content", csvContent); // Make csv_content globally accessible in Python
        }

        // Run the user's code
        await pyodideInstance.runPythonAsync(code);

        // Fetch the printed output from stdout
        const printOutput = pyodideInstance.runPython(`
          sys.stdout.getvalue()
        `);

        setOutput(printOutput.trim());

        // Check if the plot was saved to /mnt/data/output_plot.png
        const plotExists = pyodideInstance.runPython(`
          import os
          os.path.exists('/mnt/data/output_plot.png')
        `);

        if (plotExists) {
          // Create a download link for the plot
          const plotData = pyodideInstance.FS.readFile(
            "/mnt/data/output_plot.png"
          );
          const blob = new Blob([plotData], { type: "image/png" });
          const url = URL.createObjectURL(blob);
          setImageDownloadUrl(url);
        }
      } catch (error: any) {
        console.error("Error executing Python code:", error);
        setOutput(error.toString());
      }
    } else {
      console.error("Pyodide instance is not available.");
    }
  };

  useEffect(() => {
    if (props?.isPraciseQues) {
      const codeData = {
        python: code,
      };
      props?.setCodingData(JSON.stringify(codeData));
    }
  }, [code]);

  return (
    <div className="row mx-0 d-flex codeEditorBox">
      <div
        className={`EditorScreen ${
          props?.isPraciseQues
            ? "isPraciseQues col-6"
            : props?.fullScreenCoding
            ? "col-6 fullScreen"
            : ""
        }`}
      >
        <div className="d-flex justify-content-between w-100 align-items-center">
          <div className="d-flex codeHeader ">
            <div className={`codeLanguagebtn cursor active}`}>
              <span className="htmlSymbol me-2">/</span> Python
            </div>
          </div>
          <div>
            <input
              type="file"
              accept=".csv"
              className="form-control form-control-sm"
              onChange={handleFileUpload}
            />
          </div>
          <div className="d-flex align-items-center">
            {props?.isPraciseQues ? null : (
              <span
                className="cursor me-2"
                onClick={() => {
                  props?.setfullScreenCoding(!props?.fullScreenCoding);
                }}
              >
                <FullscreenIcon style={{ color: "#fff" }} />
              </span>
            )}
            {props?.isPraciseQues || props?.fullScreenCoding ? (
              <div className="p-1 cursor" onClick={executeCode}>
                <PlayCircleOutlineIcon className="font40" />
              </div>
            ) : (
              <div
                className="font16 btn-close btn-close-white cursor"
                onClick={props?.onClose}
              ></div>
            )}
          </div>
        </div>
        <div className="mt-2">
          {/* <input type="file" accept=".csv" onChange={handleFileUpload} /> */}
          <AceEditor
            mode="python"
            theme="monokai"
            onChange={(newValue) => setCode(newValue)}
            name="python_code_editor"
            editorProps={{ $blockScrolling: true }}
            width="100%"
            height={
              props?.isPraciseQues
                ? "330px"
                : props?.fullScreenCoding
                ? "calc(100vh - 170px)"
                : "250px"
            }
            readOnly={props?.disabled}
            value={code}
          />
        </div>
        {props?.isPraciseQues || props?.fullScreenCoding ? null : (
          <div
            className="mt-2 p-1 text-end position-absolute cursor"
            style={{ bottom: "0", right: "30px" }}
            onClick={executeCode}
          >
            <PlayCircleOutlineIcon className="font48" />
          </div>
        )}
      </div>
      <div
        className={`CodeprevScreen ${
          props?.isPraciseQues
            ? "isPraciseQues col-6"
            : props?.fullScreenCoding
            ? "col-6 fullScreen"
            : ""
        }`}
      >
        <div className="d-flex justify-content-between">
          <h4 className="pt-2">Output</h4>
          {/* <span
            className="cursor"
            onClick={() => {
              setOpenOutput(true);
            }}
          >
            <FullscreenIcon style={{ color: "#fff" }} />
          </span> */}
          {/* {imageDownloadUrl && (
            <div>
              <a href={imageDownloadUrl} download="output_plot.png">
                Download Plot
                <img src={imageDownloadUrl} alt="output" />
              </a>
            </div>
          )} */}
        </div>
        <div className="pythonOutput">
          {imageDownloadUrl ? (
            <img src={imageDownloadUrl} alt="output" className="w-100" />
          ) : (
            <pre>{output}</pre>
          )}
        </div>
      </div>
    </div>
  );
}

export default PythonCodeEditor;
