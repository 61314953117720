import PageTitle from "../../Components/pageTitle";
import { useDispatch } from "react-redux";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/navigation";
import { useEffect, useState } from "react";
import ClassRequestCard from "../../Components/classRequestCard";
import { getPendingClass } from "../../Redux/Actions/AdminAction";
import { updateTeacherList } from "../../Redux/Actions/SuperAdminAction";
import LabelField from "../../Components/labelField";
import Dropdown from "../../Components/dropdown";
import SubmitButton from "../../Components/submitButton";
export default function ClassPending() {
  const dispatch = useDispatch();
  const [teacher, setTeacher] = useState<any>("");

  const [upcomingList, setUpcomingList] = useState<any>([]);
  const [teacherList, setTeacherList] = useState<any>([]);

  useEffect(() => {
    dispatch(
      updateTeacherList(
        {},
        (res: any) => {
          setTeacherList(res?.data);
        },
        () => {}
      )
    );
    getDemoScheduledList();
  }, []);

  const getDemoScheduledList = () => {
    dispatch(
      getPendingClass(
        {},
        (res: any) => {
          setUpcomingList(res);
        },
        () => {}
      )
    );
  };

  const onChangeTeacher = (value: any) => {
    setTeacher(value);
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(
      getPendingClass(
        { empId: teacher?.empId },
        (res: any) => {
          setUpcomingList(res);
        },
        () => {}
      )
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"CLASS PENDING"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className=" d-flex flex-wrap  mt-3">
            <div className="col-md-4 d-flex ms-3 align-items-center mt-2">
              <LabelField lableName={"Teacher"} />
              <div className="col-md-8">
                <Dropdown
                  labelName="Select Teacher Name"
                  setInputText={(value: any) => onChangeTeacher(value)}
                  value={teacher}
                  options={teacherList}
                />
              </div>
            </div>
            <div className=" mb-2  mt-2">
              <SubmitButton name={"Apply Filter"} />
            </div>
          </div>
        </form>
        <div className="row">
          {upcomingList?.map((item: any, index: any) => {
            return (
              <div className="col-md-4 py-3 p-2">
                <ClassRequestCard
                  key={index}
                  data={item}
                  name={"PENDING"}
                  pageName={"teacherPanelClassPending"}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
