export default {
  FinanceSidebarData: [
    {
      title: "FINANCE",
      subNav: [
        {
          title: "CREATE",
          path: "finance",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "finance",
          cName: "sub-nav",
        },
      ],
    },
    // {
    //   title: "Transaction History",
    //   path: "transaction_history",

    // },
    {
      title: "INSTALLMENTS",
      subNav: [
        {
          title: "UPCOMING INSTALLMENTS",
          path: "upcomingInstallments",
          cName: "sub-nav",
        },
        {
          title: "OVERDUE INSTALLMENTS",
          path: "overdueInstallments",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "Payment Status",
      path: "payment_status",
    },
    {
      title: "DSA PAYMENT PAYOUT",
      path: "dsaPaymentPayout",
    },
    {
      title: "AFFILIATE PAYMENT PAYOUT",
      path: "affiliatePaymentPayout",
    },
    {
      title: "TEACHER PAYMENT PAYOUT",
      path: "paymentPayout",
    },
  ],
};
