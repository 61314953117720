import { useEffect, useState, useRef } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import DatePicker from "../../Components/datePicker";
import PaginationView from "../../Components/paginationView";
import PaginationTable from "../../Components/paginationTable";
import {
  getTableDataSlots,
  today,
  usePagination,
} from "../../Utils/appConstants";
import Pagination from "@mui/material/Pagination";
import SubmitButton from "../../Components/searchButton";
import {
  getCountryList,
  getMarketingLandingPage,
} from "../../Redux/Actions/AdminAction";
import { useDispatch } from "react-redux";
import ExportButton from "../../Components/exportButton";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import LeadDemoAlignPopup from "../../Components/leadDemoAlignPopup";

export default function MarketingLandingpage() {
  const dispatch: Function = useDispatch();
  const tableRef = useRef(null);
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(String(count), rowsPerPage);
  const [leads, setLeads] = useState([]);

  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);

  const [openPopup, setOpenPopup] = useState(false);
  const [showEditSchedule, setShowEditSchedule] = useState(false);
  const [showDemoSchedule, setShowDemoSchedule] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [selectedIndex, setSelectedIndex] = useState<any>();

  useEffect(() => {
    getDemoReq();
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );
  }, []);

  const getDemoReq = () => {
    const postdata: any = {
      startDate: startDate,
      endDate: endDate,
      sourceId: 5,
    };
    dispatch(
      getMarketingLandingPage(
        postdata,
        (res: any) => {
          setLeads(res);
          setCount(res?.count);
        },
        (error: any) => { }
      )
    );
  };

  const handleEdit = (value: any) => {
    setSelectedData(value);
    setOpenPopup(true);
    setShowEditSchedule(true);
  };
  const handleDelete = (value: any) => { };
  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onChangeStartDate = (value: any) => {
    setStartDate(value);
  };

  const handleClose = (value: any) => {
    setOpenPopup(false);
  };

  const onChangeEndDate = (value: any) => {
    setEndDate(value);
  };

  const onPageChange = (page: number, rowsPerPage: number) => {
    let postdata: any = {
      startDate: startDate,
      endDate: endDate,
      skip: (page - 1) * rowsPerPage,
      limit: rowsPerPage,
    };

    dispatch(
      getMarketingLandingPage(
        postdata,
        (res: any) => {
          setLeads(res);
          setCount(res?.count);
        },
        (error: any) => {
          dispatch(showErrorSnackbar(error?.msg) as any);
        }
      )
    );
  };

  const onChangeCountryName = (e: any, index: any, data: any) => {
    setLeads([]);
    setSelectedIndex(index);
    setCountry(data);
    const postdata: any = {
      startDate: startDate,
      endDate: endDate,
      countryCode: data?.phonecode,
      sourceId: 5,
    };
    if (country?.phonecode === "") {
      delete postdata["countryCode"];
    }
    dispatch(
      getMarketingLandingPage(
        postdata,
        (res: any) => {
          setLeads(res);
          setCount(res?.count);
        },
        (error: any) => { }
      )
    );
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }

    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const renderListMode = () => {
    return (
      <>
        <div className="">
          <div className="d-flex justify-content-between mt-4">
            <div className="justify-content-start mt-4 ms-3">
              {countryList?.map((item: any, index: any) => {
                return (
                  <button
                    className={`btn btn-sm me-4 ${selectedIndex === index + 1
                      ? "btn-success"
                      : "btn-secondary"
                      }`}
                    onClick={(e) => onChangeCountryName(e, index + 1, item)}
                  >
                    {item?.name}
                  </button>
                );
              })}
            </div>
            <div className="d-flex justify-content-end mt-4">
              {leads?.length !== 0 && leads?.length !== 0 ? (
                <div className=" d-flex justify-content-end ms-2">
                  <DownloadTableExcel
                    filename="users table"
                    sheet="users"
                    currentTableRef={tableRef.current}
                  >
                    <ExportButton name={"Export Details"} />
                  </DownloadTableExcel>
                </div>
              ) : (
                ""
              )}
              {leads?.length !== 0 ? (
                <div className="d-flex paginationdiv justify-content-end align-items-center ms-2  ">
                  <Pagination
                    count={totalPages}
                    size="large"
                    page={currentPage}
                    variant="outlined"
                    shape="rounded"
                    color={"primary"}
                    onChange={handleChange}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>

        {count > 0 ? (
          <PaginationView
            count={count}
            tableData={leads ? leads : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}
        <div>
          <PaginationTable
            tableData={leads ? leads : []}
            pagename={"marketingLandingpage"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "STUDENT NAME",
              "STUDENT MOBILE NUMBER",
              "STUDENT EMAIL",
              "COURSE TYPE",
              "GRADE",
              "SUBJECT",
              "CREATED DATE",
              "DEMO STATUS",
              "ACTION",
            ]}
            edit={true}
            handleEdit={(value: any) => handleEdit(value)}
            handleDelete={(value: any) => handleDelete(value)}
          />
        </div>
        <div ref={tableRef} className="d-none">
          <PaginationTable
            tableData={leads ? leads : []}
            pagename={"marketingLandingpageExport"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "STUDENT NAME",
              "STUDENT MOBILE NUMBER",
              "STUDENT EMAIL",
              "CREATED DATE",
              "DEMO STATUS",
            ]}
            edit={true}
            handleEdit={(value: any) => handleEdit(value)}
            handleDelete={(value: any) => handleDelete(value)}
          />
        </div>
      </>
    );
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    getDemoReq();
  };

  return (
    <div className="contentBox">
      <PageTitle name={"MARKETING LANDING PAGE"} />
      <div className="formDiv">
        <form className="text-center " onSubmit={handleSubmit}>
          <div className="px-2 mx-0 mt-4 row d-flex flex-wrap Justify-content-between ">
            <div className="col-md-2 d-flex mb-2 align-items-center ms-5">
              <LabelField lableName={"Start Date:"} />
              <DatePicker
                value={startDate}
                setInputText={(value: string) => onChangeStartDate(value)}
                maxdate={today}
              />
            </div>
            <div className=" col-md-2 d-flex mb-2 align-items-center">
              <LabelField lableName={"End Date:"} />
              <DatePicker
                value={endDate}
                setInputText={(value: string) => onChangeEndDate(value)}
                maxdate={today}
              />
            </div>

            <div className="d-flex col-md-2  ">
              <SubmitButton name={"Search"} />
            </div>
          </div>
        </form>
        {renderListMode()}
      </div>
      <LeadDemoAlignPopup
        open={openPopup}
        handleClose={handleClose}
        showEditSchedule={showEditSchedule}
        showDemoSchedule={showDemoSchedule}
        data={selectedData}
        pageName={"MarketingLandingpage"}
        getDemoReq={getDemoReq}
      />
    </div>
  );
}
