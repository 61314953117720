import React, { useState } from "react";
import "../Assets/css/ImageTabTemplate.css";
import parser from "html-react-parser";

export default function ImageTabTemplate(props: any) {
  const [data, setData] = useState<any>("");
  const getdata = (val: any) => {
    setData(val);
  };

  return (
    <div className="tabularData">
      <div className="  mt-5 mx-5">
        <div className="d-flex row mx-0">
          {JSON.parse(props?.data?.desc)?.map((c: any, index: any) => {
            return (
              <div
                className="col-md-3 p-3 text-center"
                onClick={() => getdata(c)}
              >
                <div
                  className={` tabularBtn p-1 rounded ${
                    data?.Heading === c?.Heading ? "active" : ""
                  }`}
                >
                  <img
                    src={c?.img}
                    className="img-fluid tabularImg"
                    alt={c?.Heading}
                  />
                </div>
                <p className="mt-3"> {c?.Heading}</p>
              </div>
            );
          })}
        </div>

        {data ? (
          <div className="border p-3 rounded">
            <h4 className="fw-bold">{data?.Heading}</h4>
            {data ? parser(data?.Text) : null}
          </div>
        ) : null}
        {/* <div className={data ?"tabnTableBorder":""}>
            <table className="table table-bordered rounded mb-0 text-center">
              <thead className="table-dark">
                <tr className="p-5">
                  <th className="p-4">Gols</th>
                  <th className="p-4">Strategy</th>
                  <th className="p-4">Recommendation</th>
                  <th className="p-4">performance</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th className="tablebox">{data?.Heading}</th>
                  <td className="tablebox">{data?.Text}</td>
                  <td className="tablebox">{data?.Text}</td>
                  <td className="tablebox">{data?.doctype}</td>
                </tr>
              </tbody>
            </table>
          </div> */}
      </div>
    </div>
  );
}
