import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useDispatch } from "react-redux";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import InputField from "../Atoms/InputField";
import LabelField from "./labelField";
import { paymentStatus } from "../Utils/dataConstant";
import { paymentPayout } from "../Redux/Actions/AdminAction";
import Dropdown from "./dropdown";

export default function FinancePayments(props: any) {
  const dispatch = useDispatch();
  const [UTRNo, setUTRNo] = useState<any>("");
  const [status, setStatus] = useState<any>("");

  useEffect(() => {}, []);

  function closePopup(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void {
    setUTRNo("");
    setStatus("");
    props.handleClose();
  }

  const getTotalSum = () => {
    if (props?.pagename === "PaymentPayout") {
      let partSum = 0;
      for (let i = 0; i < props?.dataCard?.length; i++) {
        partSum += parseInt(props?.dataCard[i]?.Amount);
      }
      return partSum;
    }
    else{
      let partSum = 0;
      for (let i = 0; i < props?.dataCard?.length; i++) {
        partSum += parseInt(props?.dataCard[i]?.amount);
      }
      return partSum;
    }

  };

  const onChangeUtrNumber = (value: any) => {
    setUTRNo(value);
  };
  const onChangeStatus = (value: any) => {
    setStatus(value);
  };
  const onClickSubmit = () => {
    let PaymentDataArr = props?.dataCard?.map((value: any) => {
      return {
        _id: value?._id,
        status: "approved",
        UTRNo: UTRNo,
      };
    });
    // PaymentDataArr?.map((item: any) => {
    //   if (item?.UTRNo === "") {
    //     delete item?.UTRNo;
    //   }
    // });
    if (props?.pagename === "DsaPaymentPayout") {
      const postdata = {
        type: 1,
        payout: PaymentDataArr,
      };


      dispatch(
        paymentPayout(
          postdata,
          (res: any) => {
            setUTRNo("");
            setStatus("");
            props.handleClose();
          },
          () => {}
        )
      );
    } else if (props?.pagename === "AffiliatePaymentPayout") {
      const postdata = {
        type: 3,
        payout: PaymentDataArr,
      };

      dispatch(
        paymentPayout(
          postdata,
          (res: any) => {
            setUTRNo("");
            setStatus("");
            props.handleClose();
          },
          () => {}
        )
      );
    } else if (props?.pagename === "PaymentPayout") {
      const postdata = {
        type: 2,
        payout: PaymentDataArr,
      };

      dispatch(
        paymentPayout(
          postdata,
          (res: any) => {
            setUTRNo("");
            setStatus("");
            props.handleClose();
          },
          () => {}
        )
      );
    }
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modelChat"
        open={props.openPopup}
        // onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.openPopup}>
          <div className="modeldivChat ">
            <div className="  mt-0 justify-content-center  ">
              <div className="d-flex">
                <p className="fw-bolder fs-5 text-primary">Payment Payout</p>
                <div className="col mb-2 d-flex justify-content-end ">
                  <ClearOutlinedIcon
                    className="colorblue cursor font28"
                    onClick={closePopup}
                  />
                </div>
              </div>

              <div className="demoCard mt-5">
                <div className="row mt-2">
                  <div className="col-md-12">
                    <div className="d-flex">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName={"Total Amount"} />
                      </div>
                      <div className="font16 mb-2 ms-2 mt-1">
                        {" "}
                        {getTotalSum()}
                      </div>
                    </div>
                    {/* <div className="d-flex col-md-12">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName={"Status"} isRequired />
                      </div>
                      <div className="col-md-6">
                        <Dropdown
                          labelName="Select Status"
                          setInputText={(value: any) => onChangeStatus(value)}
                          value={status}
                          options={paymentStatus}
                        />
                      </div>
                    </div> */}
                    {/* {status?.name === "Approved" ? ( */}
                      <div className="d-flex col-md-12">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName={"UTR Number"} isRequired />
                        </div>
                        <div className="col-md-6">
                          <InputField
                            placeholder="Enter UTR Number"
                            value={UTRNo}
                            onChangeInput={(value: any) =>
                              onChangeUtrNumber(value)
                            }
                          />
                        </div>
                      </div>
                    {/* ) : null} */}

                    <div className="d-flex col-md-12">
                      <button
                        type="button"
                        className="btn btn-dark mt-2"
                        onClick={() => onClickSubmit()}
                        disabled={UTRNo === ""}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
