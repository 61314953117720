import PageTitle from "../../Components/pageTitle";
import { useDispatch } from "react-redux";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/navigation";
import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import localImages from "../../Constant/localImages";
import ImagePopUp from "../../Components/imagePopUp";
import DownloadIcon from "@mui/icons-material/Download";

export default function TutorDataDetails() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [image, setImage] = useState("");
  const { details } = location.state;
  const { startDate } = location.state;
  const { endDate } = location.state;
  const [openPopup, setOpenPopup] = useState(false);
  const canvasRef = useRef(null);
  useEffect(() => {}, []);

  const ShowImage = (value: any) => {
    setOpenPopup(true);
    setImage(value.target.src);
  };
  const handleClose = (value: any) => {
    setOpenPopup(false);
  };

  const handleDownload = (imgsrc: any) => {
    const link = document.createElement("a");
    link.download = "canvas-image.png";
    link.target = "_blank";
    link.href = imgsrc;
    link.click();
  };
  // useEffect(() => {
  //   drawCanvas();
  // }, [ ]);

  // const drawCanvas = () => {
  //   const canvas: any = canvasRef.current;
  //   const ctx = canvas.getContext("2d");
  //   const img = new Image();
  //   img.onload = () => {
  //     canvas.width = img.width;
  //     canvas.height = img.height;
  //     ctx.drawImage(img, 0, 0);
  //       ctx.font = "bold 26px Poppins";
  //       ctx.textAlign = "center";
  //       ctx.fillStyle = "black";
  //       ctx.fillText( 200, 1000);

  //   };

  // };
  const renderListMode = () => {
    return (
      <div className="border mt-4 p-3 ">
        <h4 className="fw-bold">Tutor Personal Details</h4>
        <div className="row mt-2 mb-4 ">
          <div className="row d-flex justify-content-between">
            {details?.name ? (
              <div className=" col-md-4 d-flex p-2 ">
                <div className="font16 mb-2 fw-bold">Full Name:</div>
                <div className="font16 mb-2 ms-2">{details?.name}</div>
              </div>
            ) : null}
            {details?.name ? (
              <div className="col-md-4 d-flex p-2">
                <div className="font16 mb-2 fw-bold">
                  Personal Mobile number:
                </div>
                <div className="font16 mb-2 ms-2">{details?.mobile}</div>
              </div>
            ) : null}

            {details?.aadhaar_card_number ? (
              <div className="col-md-4 d-flex p-2">
                <div className="font16 mb-2 fw-bold">Aadhaar card number:</div>
                <div className="font16 mb-2 ms-2">
                  {details?.aadhaar_card_number}
                </div>
              </div>
            ) : null}
            {details?.email ? (
              <div className="col-md-4 d-flex p-2">
                <div className="font16 mb-2 fw-bold">Personal Mail ID:</div>
                <div className="font16 mb-2 ms-2">{details?.email}</div>
              </div>
            ) : null}
            {details?.gender ? (
              <div className="col-md-4 d-flex p-2">
                <div className="font16 mb-2 fw-bold">Gender:</div>
                <div className="font16 mb-2 ms-2"> {details?.gender}</div>
              </div>
            ) : null}
            {details?.date_of_birth ? (
              <div className="col-md-4 d-flex p-2">
                <div className="font16 mb-2 fw-bold">Date of Birth:</div>
                <div className="font16 mb-2 ms-2">
                  {moment(details?.date_of_birth).format("DD-MM-YYYY")}
                </div>
              </div>
            ) : null}
            {details?.blood_group ? (
              <div className="col-md-4 d-flex p-2">
                <div className="font16 mb-2 fw-bold">Blood Group:</div>
                <div className="font16 mb-2 ms-2">{details?.blood_group}</div>
              </div>
            ) : null}
            {details?.father_name ? (
              <div className="col-md-4 d-flex p-2">
                <div className="font16 mb-2 fw-bold">Father Name:</div>
                <div className="font16 mb-2 ms-2">{details?.father_name}</div>
              </div>
            ) : null}
            {details?.marital_status ? (
              <div className="col-md-4 d-flex p-2">
                <div className="font16 mb-2 fw-bold">Marital Status:</div>
                <div className="font16 mb-2 ms-2">
                  {details?.marital_status}
                </div>
              </div>
            ) : null}
            {details?.place_of_birth ? (
              <div className="col-md-4 d-flex p-2">
                <div className="font16 mb-2 fw-bold">Place of birth:</div>
                <div className="font16 mb-2 ms-2">
                  {details?.place_of_birth}
                </div>
              </div>
            ) : null}
            {details?.aadhaar_card_number ? (
              <div className="col-md-4 d-flex p-2">
                <div className="font16 mb-2 fw-bold">Aadhaar card number:</div>
                <div className="font16 mb-2 ms-2">
                  {details?.aadhaar_card_number}
                </div>
              </div>
            ) : null}
            {details?.pan_card_number ? (
              <div className="col-md-4 d-flex p-2">
                <div className="font16 mb-2 fw-bold">Pan card number:</div>
                <div className="font16 mb-2 ms-2">
                  {details?.pan_card_number}
                </div>
              </div>
            ) : null}
            {details?.qualification ? (
              <div className="col-md-4 d-flex p-2">
                <div className="font16 mb-2 fw-bold">Qualification:</div>
                <div className="font16 mb-2 ms-2">{details?.qualification}</div>
              </div>
            ) : null}
            {details?.college_university_name ? (
              <div className="col-md-4 d-flex p-2">
                <div className="font16 mb-2 fw-bold">
                  College/University Name:
                </div>
                <div className="font16 mb-2 ms-2">
                  {details?.college_university_name}
                </div>
              </div>
            ) : null}
            {details?.date_of_joining ? (
              <div className="col-md-4 d-flex p-2">
                <div className="font16 mb-2 fw-bold">Date of Joining:</div>
                <div className="font16 mb-2 ms-2">
                  {moment(details?.date_of_joining).format("DD-MM-YYYY")}
                </div>
              </div>
            ) : null}
            {details?.grade ? (
              <div className="col-md-4 d-flex p-2">
                <div className="font16 mb-2 fw-bold">Grade:</div>
                <div className="font16 mb-2 ms-2">{details?.grade}</div>
              </div>
            ) : null}
            {details?.department ? (
              <div className="col-md-4 d-flex p-2">
                <div className="font16 mb-2 fw-bold">Department:</div>
                <div className="font16 mb-2 ms-2">{details?.department}</div>
              </div>
            ) : null}
            {details?.permanent_address ? (
              <div className="col-md-4 d-flex p-2">
                <div className="font16 mb-2 fw-bold">Permanent Address:</div>
                <div className="font16 mb-2 ms-2">
                  {details?.permanent_address}
                </div>
              </div>
            ) : null}
            {details?.emergency_contact_name ? (
              <div className="col-md-4 d-flex p-2">
                <div className="font16 mb-2 fw-bold">
                  Emergency Contact Name:
                </div>
                <div className="font16 mb-2 ms-2">
                  {details?.emergency_contact_name}
                </div>
              </div>
            ) : null}
            {details?.emergency_contact_number ? (
              <div className="col-md-4 d-flex p-2">
                <div className="font16 mb-2 fw-bold">
                  Emergency Contact Number:
                </div>
                <div className=" font16 mb-2 ms-2">
                  {details?.emergency_contact_number}
                </div>
              </div>
            ) : null}
            {details?.emergency_contact_number ? (
              <div className="col-md-4 d-flex p-2">
                <div className="font16 mb-2 fw-bold">
                  Emergency Contact Relationship:
                </div>
                <div className="font16 mb-2 ms-2">
                  {details?.emergency_contact_number}
                </div>
              </div>
            ) : null}
            {details?.bank_account_number ? (
              <div className="col-md-4 d-flex p-2">
                <div className="font16 mb-2 fw-bold">Bank account number:</div>
                <div className="font16 mb-2 ms-2">
                  {details?.bank_account_number}
                </div>
              </div>
            ) : null}
            {details?.ifsc_code ? (
              <div className="col-md-4 d-flex p-2">
                <div className="font16 mb-2 fw-bold">IFSC Code:</div>
                <div className="font16 mb-2 ms-2">{details?.ifsc_code}</div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };
  const renderDocument = () => {
    return (
      <>
        <h4 className="fw-bold">Tutor Document</h4>
        <div className="col-md-12 row d-flex justify-content-between px-3">
          {details?.photo_id_card ? (
            <div className=" mt-2 col-md-3">
              <div className="d-flex p-3  mb-4 border-0 shadow">
                <div className="col-md-2">
                  <img
                    src={details?.photo_id_card}
                    className="img-fluid "
                    onClick={(value: any) => ShowImage(value)}
                  />{" "}
                </div>
                <div className="col-md-8">
                  <p className="fw-bold font16 mb-0 ps-3 ">Tutor Photo</p>
                </div>{" "}
                <div className="col-md-2">
                  <DownloadIcon
                    onClick={() => handleDownload(details?.photo_id_card)}
                  />
                </div>
              </div>
            </div>
          ) : null}
          {details?.adhar_card ? (
            <div className=" mt-2 col-md-3 h-100">
              <div className="d-flex p-3  mb-4 border-0 shadow">
                <div className="col-md-2">
                  <img
                    src={details?.adhar_card}
                    className="img-fluid "
                    onClick={(value: any) => ShowImage(value)}
                  />{" "}
                </div>
                <div className="col-md-8">
                  <p className="fw-bold font16 mb-0 ps-3">Adhar Card</p>
                </div>{" "}
                <div className="col-md-2">
                  <DownloadIcon
                    onClick={() => handleDownload(details?.adhar_card)}
                  />
                </div>
              </div>
            </div>
          ) : null}
          {details?.pan_card ? (
            <div className=" mt-2 col-md-3 h-100">
              <div className="d-flex p-3  mb-4 border-0 shadow">
                <div className="col-md-2">
                  <img
                    src={details?.pan_card}
                    className="img-fluid "
                    onClick={(value: any) => ShowImage(value)}
                  />{" "}
                </div>
                <div className="col-md-8">
                  <p className="fw-bold font16 mb-0 ps-3">Pan Card</p>
                </div>{" "}
                <div className="col-md-2">
                  <DownloadIcon
                    onClick={() => handleDownload(details?.pan_card)}
                  />
                </div>
              </div>
            </div>
          ) : null}
          {details?.offer_letter ? (
            <div className=" mt-2 col-md-3 h-100">
              <div className="d-flex p-3  mb-4 border-0 shadow">
                <div className="col-md-1">
                  <a
                    href={details?.offer_letter}
                    target="_blank"
                    className="tutorDocumentLabel "
                  >
                    <img
                      src={localImages?.pdfimage}
                      className="img-fluid  "
                    ></img>
                  </a>{" "}
                </div>
                <div className="col-md-8">
                  <p className="fw-bold font16 mb-0 ps-3  ">Offer Letter</p>
                </div>{" "}
                <div className="col-md-2"></div>
              </div>
            </div>
          ) : null}
          {details?.tutor_policy ? (
            <div className=" mt-2 col-md-3 h-100">
              <div className="d-flex p-3  mb-4 border-0 shadow">
                <div className="col-md-1">
                  <a
                    href={details?.tutor_policy}
                    target="_blank"
                    className="tutorDocumentLabel "
                  >
                    <img
                      src={localImages?.pdfimage}
                      className="img-fluid "
                    ></img>
                  </a>{" "}
                </div>
                <div className="col-md-8">
                  <p className="fw-bold font16 mb-0 ps-3 ">Tuto Policy</p>
                </div>{" "}
                <div className="col-md-2"></div>
              </div>
            </div>
          ) : null}
          {details?.form_11 ? (
            <div className=" mt-2 col-md-3 h-100">
              <div className="d-flex p-3  mb-4 border-0 shadow">
                <div className="col-md-1">
                  <a
                    href={details?.form_11}
                    target="_blank"
                    className="tutorDocumentLabel "
                  >
                    <img
                      src={localImages?.pdfimage}
                      className="img-fluid  "
                    ></img>
                  </a>{" "}
                </div>
                <div className="col-md-8">
                  <p className="fw-bold font16 mb-0 ps-3 ">Form 11</p>
                </div>{" "}
                <div className="col-md-2"></div>
              </div>
            </div>
          ) : null}
          {details?.declaration_form ? (
            <div className=" mt-2 col-md-3 h-100">
              <div className="d-flex p-3  mb-4 border-0 shadow">
                <div className="col-md-1">
                  <a
                    href={details?.declaration_form}
                    target="_blank"
                    className="tutorDocumentLabel "
                  >
                    <img
                      src={localImages?.pdfimage}
                      className="img-fluid "
                    ></img>
                  </a>{" "}
                </div>
                <div className="col-md-8">
                  <p className="fw-bold font16 mb-0 ps-3 ">Declaration form</p>
                </div>{" "}
                <div className="col-md-2"></div>
              </div>
            </div>
          ) : null}
          {details?.asset_declaration ? (
            <div className=" mt-2 col-md-3 h-100">
              <div className="d-flex p-3  mb-4 border-0 shadow">
                <div className="col-md-1">
                  <a
                    href={details?.asset_declaration}
                    target="_blank"
                    className="tutorDocumentLabel "
                  >
                    <img
                      src={localImages?.pdfimage}
                      className="img-fluid "
                    ></img>
                  </a>{" "}
                </div>
                <div className="col-md-8">
                  <p className="fw-bold font16 mb-0 ps-3 ">
                    Assets Declaration
                  </p>
                </div>{" "}
                <div className="col-md-1"></div>
              </div>
            </div>
          ) : null}

          {details?.data_protection_form ? (
            <div className="p-3 col-md-3 h-100">
              <div className="d-flex p-3 mb-4 border-0 shadow">
                <div className="col-md-1">
                  <a
                    href={details?.data_protection_form}
                    target="_blank"
                    className="tutorDocumentLabel "
                  >
                    <img
                      src={localImages?.pdfimage}
                      className="img-fluid "
                    ></img>
                  </a>{" "}
                </div>
                <div className="col-md-8">
                  <p className="fw-bold font16 mb-0 ps-3  ">
                    {" "}
                    Data Protection Form
                  </p>
                </div>{" "}
                <div></div>
              </div>
            </div>
          ) : null}
        </div>
      </>
    );
  };
  return (
    <div className="contentBox">
      <PageTitle
        name={"TUTOR DETAILS"}
        startdate={startDate}
        endDate={endDate}
      />
      <div className="formDiv">
        <div className="px-4 mx-0 mt-4  ">{renderListMode()}</div>
        <div className="px-4 mx-0 mt-4  ">{renderDocument()}</div>
      </div>
      {openPopup ? (
        <ImagePopUp open={openPopup} handleClose={handleClose} data={image} />
      ) : null}
      <div>
        <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
      </div>
    </div>
  );
}
