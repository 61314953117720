import { useEffect, useState, useRef } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import DatePicker from "../../Components/datePicker";
import PaginationTable from "../../Components/paginationTable";
import { dateFormat, today } from "../../Utils/appConstants";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import { getRole } from "../../Redux/Actions/SuperAdminAction";
import { useDispatch } from "react-redux";
import {
  getCountryList,
  getDemoRequestList,
} from "../../Redux/Actions/AdminAction";
import ExportButton from "../../Components/exportButton";
import { DownloadTableExcel } from "react-export-table-to-excel";
import moment from "moment";
import Submitbtn from "../../Components/submitButton";
import Dropdown from "../../Components/dropdown";
import LeadDemoAlignPopup from "../../Components/leadDemoAlignPopup";

export default function CounsellingLeads() {
  const dispatch: Function = useDispatch();
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [userCategory, setUserCategory] = useState<any>("");
  const [userRoles, setUserRoles] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [leads, setLeads] = useState([]);
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const tableRef = useRef(null);
  const [selectedData, setSelectedData] = useState("");
  const [showEditSchedule, setShowEditSchedule] = useState(false);

  useEffect(() => {
    dispatch(
      getRole(
        "",
        (res: any) => {
          setUserRoles(res);
        },
        () => { }
      )
    );
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );
    getDemoReq();
  }, []);

  const getDemoReq = () => {
    const postData: any = {
      startDate: startDate,
      endDate: endDate,
      countryId: country?._id,
      sourceId: 1,
      category: userCategory?._id,
    };
    if (country) {
      postData["countryId"] = country?._id;
    }
    if (userCategory) {
      postData["category"] = userCategory?._id;
    }

    dispatch(
      getDemoRequestList(
        postData,
        (res: any) => {
          setLeads(res);
        },
        () => { }
      )
    );
  };

  const onChangeUserType = (value: any) => {
    setUserCategory(value);
  };

  const handleEdit = (value: any) => {
    setSelectedData(value);
    setOpenPopup(true);
    setShowEditSchedule(true);
  };
  const handleClose = (value: any) => {
    setOpenPopup(false);
  };

  const handleDelete = (value: any) => { };
  const onChangeStartDate = (value: any) => {
    if (
      moment(value).format(dateFormat.yyyymmddA) >
      moment(endDate).format(dateFormat.yyyymmddA)
    ) {
      dispatch(showErrorSnackbar("Start date should be lass than End date"));
    } else {
      setStartDate(value);
    }
  };

  const onChangeEndDate = (value: any) => {
    if (
      moment(value).format(dateFormat.yyyymmddA) <
      moment(startDate).format(dateFormat.yyyymmddA)
    ) {
      dispatch(showErrorSnackbar("End date should be greater than Start date"));
    } else {
      setEndDate(value);
    }
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    getDemoReq();
  };

  const onChangeCountry = (value: any) => {
    setCountry(value);
  };

  const renderListMode = () => {
    return (
      <>
        <div>
          <PaginationTable
            tableData={leads ? leads : []}
            pagename={"CounsellingLeads"}
            tableHead={[
              "S.N.",
              "FULL NAME",
              "STUDENT MOBILE NUMBER",
              "STUDENT EMAIL",
              "CONTACT DATE &TIME",
              "GRADE LEVEL",
              "ACTION",
            ]}
            edit={true}
            handleEdit={(value: any) => handleEdit(value)}
            handleDelete={(value: any) => handleDelete(value)}
          />
        </div>
        <div ref={tableRef} className="d-none">
          <PaginationTable
            tableData={leads ? leads : []}
            pagename={"CounsellingLeads"}
            tableHead={[
              "S.N.",
              "FULL NAME",
              "STUDENT MOBILE NUMBER",
              "STUDENT EMAIL",
              "CONTACT DATE &TIME",
              "GRADE LEVEL",
            ]}
            edit={true}
          />
        </div>
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Counselling Leads"} />
      <div className="formDiv">
        <form className="text-center" onSubmit={handleSubmit}>
          <div className="px-2 mx-0 mt-4 row d-flex flex-wrap ">
            <div className="col-md-2 d-flex mb-2 align-items-center">
              <LabelField lableName={"Start Date:"} />
              <DatePicker
                value={startDate}
                setInputText={(value: string) => onChangeStartDate(value)}
                maxdate={today}
              />
            </div>
            <div className=" col-md-2 d-flex mb-2 align-items-center">
              <LabelField lableName={"End Date:"} />
              <DatePicker
                value={endDate}
                setInputText={(value: string) => onChangeEndDate(value)}
                maxdate={today}
              />
            </div>
            <div className="col-md-3 d-flex mb-2 align-items-center">
              <LabelField lableName={"Submitted by:"} />
              <div className="col-7 ps-2">
                <Dropdown
                  labelName="Select Category"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeUserType(value)
                  }
                  value={userCategory}
                  options={userRoles}
                />
              </div>
            </div>
            <div className="d-flex col-md-3 ms-2 ">
              <LabelField lableName={"Country"} />
              <div className="col-7 ps-2">
                <Dropdown
                  labelName="Select Country"
                  setInputText={(value: any) => onChangeCountry(value)}
                  value={country}
                  options={countryList}
                />
              </div>
            </div>
            <div className="col-md-1  d-flex my-2 mx-2">
              <Submitbtn name={"Search"} />
            </div>
          </div>

          {leads?.length !== 0 && leads?.length !== 0 ? (
            <div className="pe-5 d-flex justify-content-end mt-5">
              <DownloadTableExcel
                filename="users table"
                sheet="users"
                currentTableRef={tableRef.current}
              >
                <ExportButton name={"Export Details"} />
              </DownloadTableExcel>
            </div>
          ) : (
            ""
          )}

          {renderListMode()}
        </form>
      </div>
      {openPopup ? (
        // <LeadDeatilpopup
        //   open={openPopup}
        //   data={popupData}
        //   handleClose={handleClose}
        // />
        <LeadDemoAlignPopup
          open={openPopup}
          handleClose={handleClose}
          showEditSchedule={showEditSchedule}
          data={selectedData}
          pageName={"counsellingleads"}
          getDemoReq={getDemoReq}
        />
      ) : (
        ""
      )}
    </div>
  );
}
