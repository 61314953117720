import { getStudentList } from "./SuperAdminAction";
import services from "../../Constant/service";
import endpoint from "../../Constant/endpoint";
import { showErrorSnackbar, showSuccessSnackbar } from "./snackbarAction";
import { updateCommonValues } from "./CommonAction";

/******************************************
 Sample Api's
 *******************************************/

export const sampleGetAction =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.academic.addBookSession,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const samplePostAction =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.academic.addBookSession,
      params,
      (res: { statusCode: number; msg: any }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const sampleDeleteAction =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.deleteApiCall(
      endpoint.academic.addBookSession + params,
      "",
      (res: { data: { statusCode: number; msg: any } }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const samplePatchAction =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.academic.addBookSession + params,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
            // dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            // dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const sampleUploadMediaAction =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    let formData = new FormData();
    formData.append("file", params);
    services.postUploadMedia(
      endpoint.academic.addBookSession,
      formData,
      (res: { statusCode: number; data: any; msg: string }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.msg));
          callback(res.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

/******************************************
 Leads
 *******************************************/

export const getLandingLeads =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.leads.landingLeads,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getAcademicLeads =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.leads.academicLeads,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };
/****************************************course type******************************************/
export const addCourseType =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.course.coursetype,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getCourseTypelist =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.course.getCourseTypelist,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateCourseType =
  (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.course.updateCourseType + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
            // dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            // dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deleteCourseType =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.deleteApiCall(
      endpoint.course.deleteCourseType + params,
      "",
      (res: { data: { statusCode: number; msg: any } }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

// *************************************Exam Name****************************

export const addExamName =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.exam.addExamName,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const uploadExamLogo =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    let formData = new FormData();
    formData.append("file", params);
    services.postUploadMedia(
      endpoint.exam.uploadExamLogo,
      formData,
      (res: { statusCode: number; data: any; msg: string }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.msg));
          callback(res.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

// export const getExamList =
//   (params: any, callback: Function, errorCallback: Function): any =>
//     async (dispatch: Function, getState: Function) => {
//       dispatch(updateCommonValues("loader", true));
//       services.getApiCall(
//         endpoint.exam.getExamList,
//         params,
//         (res: any) => {
//           dispatch(updateCommonValues("loader", false));
//           if (res?.data?.statusCode === services?.status_code?.success) {
//             callback(res?.data?.data);
//           } else {
//             errorCallback(res);
//             // dispatch(showErrorSnackbar(res?.msg));
//           }
//         },
//         (error: any) => {
//           dispatch(updateCommonValues("loader", false));
//         }
//       );
//     };

export const getExamList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.exam.getExamList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateExamName =
  (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.exam.updateExamName + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
            // dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            // dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deleteExamname =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.deleteApiCall(
      endpoint.exam.deleteExamname + params,
      "",
      (res: { data: { statusCode: number; msg: any } }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

// ************************************Add Language****************************

export const uploadLanguageLogo =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    let formData = new FormData();
    formData.append("file", params);
    services.postUploadMedia(
      endpoint.language.uploadLanguageLogo,
      formData,
      (res: { statusCode: number; data: any; msg: string }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.msg));
          callback(res.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addLanguage =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.language.addLanguage,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getLanguageList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.language.getLanguageList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const updateLanguage =
  (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.language.updateLanguage + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
            // dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            // dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deleteLanguage =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.deleteApiCall(
      endpoint.language.deleteLanguage + params,
      "",
      (res: { data: { statusCode: number; msg: any } }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

// ************************************Sub-category****************************

export const uploadSubcategoryLogo =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    let formData = new FormData();
    formData.append("file", params);
    services.postUploadMedia(
      endpoint.subCategory.uploadSubcategoryLogo,
      formData,
      (res: { statusCode: number; data: any; msg: string }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.msg));
          callback(res.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addSubcategory =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.subCategory.addSubcategory,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getSubcategoryList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.subCategory.getSubcategoryList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const updateSubCategory =
  (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.subCategory.updateSubCategory + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
            // dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            // dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deleteSubCategory =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.deleteApiCall(
      endpoint.subCategory.deleteSubCategory + params,
      "",
      (res: { data: { statusCode: number; msg: any } }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

// ************************************Section****************************

export const addSection =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.section.addSection,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getSectionList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.section.getSectionList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const updateSection =
  (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.section.updateSection + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
            // dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            // dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deleteSection =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.deleteApiCall(
      endpoint.section.deleteSection + params,
      "",
      (res: { data: { statusCode: number; msg: any } }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };
// ******************************************************
export const addMeeting =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.meeting.addMeeting,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getMeetingList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.meeting.getMeetingList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          //dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deleteBbbMeetingId =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.deleteApiCall(
      endpoint.meeting.deleteBbbMeetingId + params,
      "",
      (res: { data: { statusCode: number; msg: any } }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };
export const updateBbbMeeting =
  (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.meeting.updateBbbMeeting,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
            // dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            // dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };
/*******************************Exam Description***************************************** */
export const uploadExamBanner =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    let formData = new FormData();
    formData.append("file", params);
    services.postUploadMedia(
      endpoint.examDescription.uploadExamBanner,
      formData,
      (res: { statusCode: number; data: any; msg: string }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.msg));
          callback(res.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addExamDescription =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.examDescription.addExamDescription,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
        dispatch(showErrorSnackbar(error?.data.msg));
      }
    );
  };

export const getExamDescriptionList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.examDescription.getExamDescriptionList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          //dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateExamDescription =
  (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.examDescription.updateExamDescription + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
            // dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            // dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deleteExamDescription =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.deleteApiCall(
      endpoint.examDescription.deleteExamDescription + params,
      "",
      (res: { data: { statusCode: number; msg: any } }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };
// ************************************Main Category****************************

export const addMainCategory =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.mainCategory.addMainCategory,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getMainCategoryList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.mainCategory.getMainCategoryList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const updateMainCategory =
  (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.mainCategory.updateMainCategory + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
            // dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            // dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deleteMainCategory =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.deleteApiCall(
      endpoint.mainCategory.deleteMainCategory + params,
      "",
      (res: { data: { statusCode: number; msg: any } }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getSessionList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.mainCategory.getSessionList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          //dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getMainCategoryEditData =
  (id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.mainCategory.getMainCategoryEditData + id,
      "",
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

// ************************************Assign Exam****************************

export const getGeneralCourseList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.assignExam.getGeneralCourseList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          //dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getAssignExam =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.assignExam.getAssignExam,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getAssignExamList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.assignExam.getAssignExamList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

/************************Course Description******************************* */
export const addCourseDescription =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.courseDescription.addCourseDescription,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
        dispatch(showErrorSnackbar(error?.data.msg));
      }
    );
  };
export const getCourseDescription =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.courseDescription.getCourseDescription,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          //dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateCourseDescription =
  (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.courseDescription.updateCourseDescription + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
            // dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            // dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };
export const deleteCourseDescription =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.deleteApiCall(
      endpoint.courseDescription.deleteCourseDescription + params,
      "",
      (res: { data: { statusCode: number; msg: any } }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };
/*********************************************subject*******************************************************/

export const addSubject =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.subject.addSubject,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getSubjectList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.subject.getSubjectList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          //dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          //dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateSubject =
  (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.subject.updateSubject + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
            // dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            // dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deleteSubject =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.deleteApiCall(
      endpoint.subject.deleteSubject + params,
      "",
      (res: { data: { statusCode: number; msg: any } }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getSubject =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.subject.getSubject,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          //dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          //dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

/***************************** get  assigned exams */

export const getAssignedExamsList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.assignExam.getAssignedExamsList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

/******************** Create Package ****************/

export const uploadPackageMedia =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    let formData = new FormData();
    formData.append("file", params);
    services.postUploadMedia(
      endpoint.package.uploadPackageMedia,
      formData,
      (res: { statusCode: number; data: any; msg: string }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.msg));
          callback(res.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getCourseDuration =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.package.getCourseDuration,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addPackage =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.package.addPackage,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getPackageList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.package.getPackageList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getPackageEditData =
  (id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.package.getPackageEditData + id,
      "",
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const updatePackage =
  (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.package.updatePackage + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
            // dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            // dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deletePackage =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.deleteApiCall(
      endpoint.package.deletePackage + params,
      "",
      (res: { data: { statusCode: number; msg: any } }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

// **************************

export const getGeneralExamList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.exam.getGeneralExamList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getGeneralSubjectList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.subject.getGeneralSubjectList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };
export const getGeneralTeacherList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.subject.getGeneralTeacherList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getGeneralBbbMeetingList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.meeting.getGeneralBbbMeetingList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getSectionTypesList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.section.getSectionTypesList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          //dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

/***************************About Exam***********************************/
export const addAboutExam =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.aboutexam.addAboutExam,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };
/****************************************Exam Dates********************************************/
export const addExamDates =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.examdates.addExamDates,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };
export const uploadExamDateIcon =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    let formData = new FormData();
    formData.append("file", params);
    services.postUploadMedia(
      endpoint.examdates.uploadExamIcon,
      formData,
      (res: { statusCode: number; data: any; msg: string }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.msg));
          callback(res.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addExamDateIcon =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.examdates.addExamDateIcon,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getExamDateIconList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.examdates.getExamDateIconList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

/****************************Topic************************** */

export const addTopic =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.topic.addTopic,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getTopicList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.topic.getTopicList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          //dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getTopicEditData =
  (id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.topic.getTopicEditData + id,
      "",
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const updateTopic =
  (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.topic.updateTopic + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
            // dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            // dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deleteTopic =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.deleteApiCall(
      endpoint.topic.deleteTopic + params,
      "",
      (res: { data: { statusCode: number; msg: any } }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

/****************************SubTopic************************** */

export const addSubTopic =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.subtopic.addSubTopic,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getSubTopicList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.subtopic.getSubTopicList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          //dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateSubTopic =
  (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.subtopic.updateSubTopic + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
            // dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            // dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deleteSubTopic =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.deleteApiCall(
      endpoint.subtopic.deleteSubTopic + params,
      "",
      (res: { data: { statusCode: number; msg: any } }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

/****************Pattern */

export const getCalculatorTypes =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.pattern.getCalculatorTypes,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const addPattern =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.pattern.addPattern,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getPatternList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.pattern.getPatternList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getPatternByID =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.pattern.getPatternByID + params,
      { examPatternId: params },
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updatePattern =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.pattern.updatePattern,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
            dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deletePatternPart =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.deleteApiCall(
      endpoint.pattern.deletePart,
      params,
      (res: { data: { statusCode: number; msg: any } }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deletePatternSection =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.deleteApiCall(
      endpoint.pattern.deletePart,
      params,
      (res: { data: { statusCode: number; msg: any } }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deletePattern =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.deleteApiCall(
      endpoint.pattern.deletePart,
      params,
      (res: { data: { statusCode: number; msg: any } }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

/****************************Notification************************** */

export const addNotification =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.notification.addNotification,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getNotificationIconList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.notification.getNotificationIconList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getNotificationList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.notification.getNotificationList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getNotificationEditData =
  (id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.notification.getNotificationEditData + id,
      "",
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const updateNotification =
  (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.notification.updateNotification + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
            // dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            // dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deleteNotification =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.deleteApiCall(
      endpoint.notification.deleteNotification + params,
      "",
      (res: { data: { statusCode: number; msg: any } }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

// **************Exam Set*******************

export const getExamSetTypeId =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ExamSet.getExamSetTypeId,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const addExamSet =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ExamSet.addExamSet,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getExamSetList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ExamSet.getExamSetList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const changeActiveStatusExamset =
  (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.ExamSet.changeActiveStatusExamset + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
            // dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            // dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateExamSet =
  (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.ExamSet.updateExamSet + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
            // dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            // dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deleteExamSet =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.deleteApiCall(
      endpoint.ExamSet.deleteExamSet + params,
      "",
      (res: { data: { statusCode: number; msg: any } }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getExamSetQuestionList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ExamSet.getExamSetQuestionList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getExamSetAvailQuestionList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ExamSet.getExamSetAvailQuestionList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getFilteredPatternList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ExamSet.getFilteredPatternList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

/******************* Question */

export const getQuestionsExamSetList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.Question.getExamSetList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getSectionsData =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.Question.getSectionsData,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const updatePartTotalQuestions =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.Question.updatePartTotalQuestions,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
            dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addQuestions =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.Question.addQuestions,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateQuestions =
  (id: string, params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.Question.updateQuestions + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
            dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deleteQuestions =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.deleteApiCall(
      endpoint.Question.deleteQuestions + params,
      "",
      (res: { data: { statusCode: number; msg: any } }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const uploadQuestionMedia =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    let formData = new FormData();
    formData.append("file", params);
    services.postUploadMedia(
      endpoint.Question.uploadQuestionMedia,
      formData,
      (res: { statusCode: number; data: any; msg: string }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.msg));
          callback(res.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getQuestionsList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.Question.getQuestionsList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.examData);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateQuestionOrder =
  (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.Question.updateQuestionOrder + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
            dispatch(showErrorSnackbar(res?.data?.msg));
          } else {
            dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deleteExamPattern =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.deleteApiCall(
      endpoint.pattern.deletePattern + params,
      "",
      (res: { data: { statusCode: number; msg: any } }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };
/*******************************************Registration Details******************************************************/

export const getRegistrationDetails =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.Registration.getRegistrationDetails,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          // dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const addManuallyCourse =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.Registration.addManuallyCourse,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const updateCourse =
  (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.Registration.updateCourse,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
            // dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            // dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getTransactions =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.CXO.getTransactions,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          //dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

// **************************BLOG*****************************

export const uploadBlogImg =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    let formData = new FormData();
    formData.append("file", params);
    services.postUploadMedia(
      endpoint.Blog.uploadBlogImg,
      formData,
      (res: { statusCode: number; data: any; msg: string }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.msg));
          callback(res.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addBlog =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.Blog.addBlog,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getBlogList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.Blog.getBlogList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          //dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deleteBlog =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.deleteApiCall(
      endpoint.Blog.deleteBlog + params,
      "",
      (res: { data: { statusCode: number; msg: any } }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateBlog =
  (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.Blog.updateBlog + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
            // dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            // dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addScholorshipPattern =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.pattern.addScholorshipPattern,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getScholorshipPattern =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.pattern.getScholorshipPattern,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getScholorshipPatternByID =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.pattern.getScholorshipPatternByID + params,
      "",
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

// ***************sales panel********************

export const getLandingPageSales =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.sales.getLandingPageSales,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getTutorNetwork =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.sales.getTutorNetwork,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getMarketingLandingPage =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.sales.getMarketingLandingPage,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getCountryList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.superAdmin.getCountryList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getGeneralTimezoneList =
  (id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.superAdmin.getGeneralTimezoneList + id,
      "",
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

// **********************DemoRequest*********************************

export const getDemoRequestList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.demo.getDemoRequestList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getPreferTeacherList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.demo.getPreferTeacherList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getOtherTeacherList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.demo.getOtherTeacherList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const alignDemo =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.demo.alignDemo,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getScheduledList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.demo.getScheduledList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addTeacherTimeAvailability =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.teacher.addTeacherTimeAvailability,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const ReScheduleDemo =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.demo.ReScheduleDemo,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const teacherDemoSchedule =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.teacher.teacherDemoSchedule,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getSourceList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.superAdmin.getSourceList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getBBBTeacherList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.meeting.getBBBTeacherList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getweeklyScheduleList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.teacher.getweeklyScheduleList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getDemoParentFeedBack =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.demo.getDemoParentFeedBack,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getCourseExamList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.superAdmin.getCourseExamList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

// ********************AssignSubject******************

export const bindSubject =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.subject.bindSubject,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getNewSubjectList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.subject.getNewSubjectList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const bindSubjectList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.subject.bindSubjectList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const assignedSubjectList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.subject.assignedSubjectList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addCourseDescFeature =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.courseDescription.addCourseDescFeature,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
        dispatch(showErrorSnackbar(error?.data.msg));
      }
    );
  };

export const courseDescFeatureList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.courseDescription.courseDescFeatureList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
        dispatch(showErrorSnackbar(error?.data.msg));
      }
    );
  };

export const getCountlist =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.package.getCountlist,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getExamListWeb =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.superAdmin.getExamListWeb,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const register =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.superAdmin.register,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addLecture =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.lecture.addLecture,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getLectureList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.lecture.getLectureList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const deleteLectures =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.deleteApiCall(
      endpoint.lecture.deleteLectures + params,
      "",
      (res: { data: { statusCode: number; msg: any } }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };
export const updateLecture =
  (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.lecture.updateLecture + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
            // dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            // dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const createCoupon =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.coupon.createCoupon,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getCouponList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.coupon.getCouponList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const deactiveCoupon =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.deleteApiCall(
      endpoint.coupon.deleteCoupon + params,
      "",
      (res: { data: { statusCode: number; msg: any } }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deleteCoupon =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.deleteApiCall(
      endpoint.coupon.deleteCoupon + params,
      "",
      (res: { data: { statusCode: number; msg: any } }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateCoupon =
  (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.coupon.updateCoupon + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const assignFeature =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.feature.assignFeature,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getLink =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.demo.getLink,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getFeatureList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.feature.getFeatureList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const updateFeature =
  (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.feature.updateFeature + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };
export const deleteFeature =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.deleteApiCall(
      endpoint.feature.deleteFeature + params,
      "",
      (res: { data: { statusCode: number; msg: any } }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getClassData =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.demo.getClassData,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getPackageWebList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiWebCall(
      endpoint.package.getPackageWebList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const addGlobalSetting =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.globalSetting.addGlobalSetting,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getGlobalSettingList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.globalSetting.getGlobalSettingList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          //dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getPaymentStatus =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.CXO.getPaymentStatus,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          //dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getPayoutList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.payout.getPayoutList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showSuccessSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const PayoutSave =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.payout.PayoutSave,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getTeacherPayoutList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.teacher.getTeacherPayoutList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const oldManualActivation =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.Registration.oldManualActivation,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getLectureCount =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.lecture.getLectureCount,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getCounsellorList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.superAdmin.getCounsellorList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getRescheduleList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.rescheduleRequest.getRescheduleList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const RescheduleRequest =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.rescheduleRequest.RescheduleRequest,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar(res?.msg));
        } else {
          errorCallback(res);
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deleteRescheduleReq =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.deleteApiCall(
      endpoint.rescheduleRequest.deleteRescheduleReq + params,
      "",
      (res: { data: { statusCode: number; msg: any } }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res);
          dispatch(showSuccessSnackbar(res?.data?.msg));
        } else {
          errorCallback(res);
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addInstallment =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.installment.addInstallment,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getInstallment =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.installment.getInstallment,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const teacherOverallRecord =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.conversion.teacherOverallRecord,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const teacherRecordDetails =
  (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.conversion.teacherRecordDetails + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getBbbServerTimings =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.serverTimings.getBbbServerTimings,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const teacherNotification =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.notification.teacherNotification,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const teacherDemoPending =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.teacher.teacherDemoPending,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const teacherClassPending =
  (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.teacher.teacherClassPending + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

// ********************DSA*****************

export const addDsaUser =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.DSA.addDsaUser,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getDsaPanelUsers =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.DSA.getDsaPanelUsers,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const updateDsaUser =
  (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.DSA.updateDsaUser + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const checkUser =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.DSA.checkUser,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
          // dispatch(showSuccessSnackbar(res?.msg));
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const deleteDsaUser =
  (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.DSA.deleteDsaUser + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getDsaPayoutList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.DSA.getDsaPayoutList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const DsaPayoutSave =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.DSA.DsaPayoutSave,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getDsaSalesList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.DSA.getDsaSalesList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const createRegisterUser =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.DSA.createRegisterUser,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
          dispatch(showSuccessSnackbar(res?.msg));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getDsaManagerList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.DSA.getDsaManagerList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getPaymentDetails =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.transactions.getPaymentDetails,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addIndex =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.academics.addIndex,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const uploadAcademicsImg =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    let formData = new FormData();
    formData.append("file", params);
    services.postUploadMedia(
      endpoint.academics.uploadAcademicsImg,
      formData,
      (res: { statusCode: number; data: any; msg: string }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getIndex =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.academics.getIndex,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addPage =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.academics.addPage,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
          dispatch(showSuccessSnackbar(res?.msg));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getAddPageData =
  (id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.academics.getAddPageData + id,
      "",
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const addLiveFeedback =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.teacher.addLiveFeedback,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
          dispatch(showSuccessSnackbar(res?.msg));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getLiveFeedback =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.teacher.getLiveFeedback,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
          dispatch(showSuccessSnackbar(res?.msg));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getLiveTeacherFeedbackList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.Feedback.getLiveTeacherFeedbackList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
          dispatch(showSuccessSnackbar(res?.msg));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getUpcomingListAdmin =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.teacher.getUpcomingListAdmin,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
          dispatch(showSuccessSnackbar(res?.msg));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getLiveStudentFeedbackList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.Feedback.getLiveStudentFeedbackList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
          dispatch(showSuccessSnackbar(res?.msg));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getFeedbackForGraph =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.Feedback.getFeedbackForGraph,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
          dispatch(showSuccessSnackbar(res?.msg));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getFeedbackForGraphLectureWise =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.Feedback.getFeedbackForGraphLectureWise,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
          dispatch(showSuccessSnackbar(res?.msg));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getUpcomingInstallments =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.CXO.getUpcomingInstallments,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
          dispatch(showSuccessSnackbar(res?.msg));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getOverdueInstallments =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.CXO.getOverdueInstallments,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
          dispatch(showSuccessSnackbar(res?.msg));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getUserAnswerReq =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.teacher.getUserAnswerReq,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const submitUserAnswerPoints =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.teacher.submitUserAnswerPoints,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getAffilateMarketingLead =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.leads.affilateMarketingLead,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };
export const getStudentListFeedback =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.Feedback.getStudentListFeedback,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getManagerByCompany =
  (id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.DSA.getManagerByCompany + id,
      "",
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getStudentListByTeacherID =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.teacher.getStudentListByTeacherID,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const AffiliatePaymentList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.affiliate.AffiliatePaymentList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const AffiliatePayoutSave =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.affiliate.AffiliatePayoutSave,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateUserProfile =
  (params: any, data: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.Registration.updateUserProfile + params,
      data,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };
/*******************************Question config**********************************/
export const addQuestionConfig =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.questionConfig.addQuestionConfig,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getQuestionConfigList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.questionConfig.getQuestionConfigList,
      {},
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

// export const deleteQuestionConfig =
// (params: any, id: any, callback: Function, errorCallback: Function): any =>
// async (dispatch: Function, getState: Function) => {
//   dispatch(updateCommonValues("loader", true));
//   services.patchApiCall(
//     endpoint.questionConfig.deleteQuestionConfig + id,
//     params,
//     (res: any) => {
//       dispatch(updateCommonValues("loader", false));
//       if (res?.data?.statusCode === services?.status_code?.success) {
//         callback(res?.data?.data);
//       } else {
//         errorCallback(res);
//       }
//     },
//     (error: any) => {
//       dispatch(updateCommonValues("loader", false));
//       errorCallback(error);
//     }
//   );
// };

export const deleteQuestionConfig =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.deleteApiCall(
      endpoint.questionConfig.deleteQuestionConfig + params,
      "",
      (res: { data: { statusCode: number; msg: any } }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const createQuestionBank =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.questionBank.createQuestionBank,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getCartList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.orders.getCartList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const addToCart =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.orders.addToCart,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar(res?.msg));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };
export const applyCoupon =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.orders.applyCoupon,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };
export const placeOrder =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.orders.placeOrder,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const paymentRequest =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.orders.paymentRequest,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const paymentExecute =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.orders.paymentExecute,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const addlectureCharges =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.globalSetting.addlectureCharges,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getLectureCharges =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.globalSetting.getLectureCharges,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getByPackageTopicwiseList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.orders.getByPackageTopicwiseList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar(res?.msg));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getCustomizePackageAmount =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.orders.getCustomizePackageAmount,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar(res?.msg));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const saveCustomizePackage =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.orders.saveCustomizePackage,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar(res?.msg));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getTutorDetails =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.tutorDetails.getTutorDetails,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getTeacherStudentList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.teacher.getTeacherStudentList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const updateStudentLevel =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.teacher.updateStudentLevel,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
          } else {
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getQuestionBankList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.questionBank.getQuestionBankList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getAllStudentList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.student.getAllStudentList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const updateQuestionBank =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.questionBank.updateQuestionBank,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
          } else {
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deleteQuestionBank =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.deleteApiCall(
      endpoint.questionBank.deleteQuestionBank + params,
      "",
      (res: { data: { statusCode: number; msg: any } }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const importQuestions =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.questionBank.importQuestions,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const deleteAllQuestions =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.questionBank.deleteAllQuestions,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const saveRegistrationDetails =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.orders.saveRegistrationDetails,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          // dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const savePaymentLink =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.orders.savePaymentLink,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getTransactionPaymentLinks =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.orders.getTransactionPaymentLinks,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const updateImportQuestionBank =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.questionBank.updateImportQuestionBank,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
          } else {
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getReferenceUserList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.Registration.getReferenceUserList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getPendingClass =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.demo.getPendingClass,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const paymentPayout =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.finance.paymentPayout,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
          } else {
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const uploadRecVideo =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    let formData = new FormData();
    formData.append("file", params);
    services.postUploadMedia(
      endpoint.recordedVideo.uploadRecVideo,
      formData,
      (res: { statusCode: number; data: any; msg: string }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getUpdateDemoData =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.academics.getUpdateDemoData,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getDemoClassBindData =
  (id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.academics.getDemoClassBindData + id,
      "",
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getPaidAdsLeadList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.PaidAds.getPaidAdsLeadList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getRenquiredLeadList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.PaidAds.getRenquiredLeadList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateIndex =
  (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.academics.updateIndex + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
          } else {
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getTeacherUpcomingLectureList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.teacher.getTeacherUpcomingLectureList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getAlignedLecture =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.teacher.getAlignedLecture,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          // dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const addAcademicData =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.teacher.addAcademicData,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          // dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const addSubmitDescriptiveQuestion =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.teacher.addSubmitDescriptiveQuestion,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          // dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const uploadSeoImg =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    let formData = new FormData();
    formData.append("file", params);
    services.postUploadMedia(
      endpoint.Seo.uploadSeoImg,
      formData,
      (res: { statusCode: number; data: any; msg: string }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addFaqType =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.Seo.addFaqType,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          // dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getFaqTypeList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.Seo.getFaqTypeList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const addSeoData =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.Seo.addSeoData,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar(res?.msg));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getSeoData =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.Seo.getSeoData,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getJitSiSoftwareList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.superAdmin.getJitSiSoftwareList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };
