import React, { useState, useEffect } from "react";
import AceEditor from "react-ace";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
// import 'brace/mode/html';
// import 'brace/mode/javascript';
// import 'brace/mode/python';
// import 'brace/mode/jsx';
// import 'brace/theme/github';
import "../Assets/css/codeEditor.css";
import FullscreenIcon from "@mui/icons-material/Fullscreen";

function CodeEditor(props: any) {
  console.log("props?.code", props?.code);
  let code: any = {};
  if (props?.code) {
    code = JSON.parse(props.code);
  }
  // JSON.parse(props?.code)
  const [htmlCode, setHtmlCode] = useState(code?.html ? code?.html : "");
  const [cssCode, setCssCode] = useState(code?.css ? code?.css : "");
  const [jsCode, setJsCode] = useState(code?.js ? code?.js : "");
  const [output, setOutput] = useState("");
  const [error, setError] = useState("");
  const [selectedTab, setSelectedTab] = useState("HTML");

  console.log("htmlCode", htmlCode);
  console.log("jsCode", jsCode);
  console.log("output", output);
  const compileCode = () => {
    // if(props?.isPraciseQues){
    //   const codeData={
    //     html:htmlCode,
    //     css:cssCode,
    //     js:jsCode
    //   }
    //   props?.setCodingData(JSON.stringify(codeData));
    // }

    try {
      // Basic HTML sanitization
      const html = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Document</title>
          <style>         
              ${cssCode}           
          </style>
        </head>
        <body style="color:#fff;">
          ${htmlCode}
          <script>
            try {
              ${jsCode}
            } catch (e) {
              document.body.innerHTML += '<p style="color: red;">JavaScript Error: ' + e.message + '</p>';
            }
          </script>
        </body>
        </html>
      `;
      setOutput(html);
      setError("");
    } catch (e: any) {
      setError("HTML Error: " + e.message);
    }
  };

  useEffect(() => {
    // compileCode();
    if (props?.isPraciseQues) {
      const codeData = {
        html: htmlCode,
        css: cssCode,
        js: jsCode,
      };
      props?.setCodingData(JSON.stringify(codeData));
    }
  }, [htmlCode, jsCode, cssCode]);

  return (
    <div className="row mx-0 d-flex codeEditorBox">
      <div
        className={`EditorScreen ${
          props?.isPraciseQues
            ? "isPraciseQues col-6"
            : props?.fullScreenCoding
            ? "col-6 fullScreen"
            : ""
        }`}
      >
        <div className="d-flex justify-content-between w-100 align-items-center">
          <div className="d-flex codeHeader ">
            <div
              className={`codeLanguagebtn cursor ${
                selectedTab === "HTML" ? "active" : ""
              }`}
              onClick={() => setSelectedTab("HTML")}
            >
              <span className="htmlSymbol me-2">/</span> HTML
            </div>
            <div
              className={`codeLanguagebtn cursor ${
                selectedTab === "CSS" ? "active" : ""
              }`}
              onClick={() => setSelectedTab("CSS")}
            >
              <span className="cssSymbol me-2">*</span> CSS
            </div>
            <div
              className={`codeLanguagebtn cursor ${
                selectedTab === "JS" ? "active" : ""
              }`}
              onClick={() => setSelectedTab("JS")}
            >
              <span className="jsSymbol me-2">O</span> JS
            </div>
          </div>
          <div className="d-flex align-items-center">
            {props?.isPraciseQues ? null : (
              <span
                className="cursor me-2"
                onClick={() => {
                  props?.setfullScreenCoding(!props?.fullScreenCoding);
                }}
              >
                <FullscreenIcon style={{ color: "#fff" }} />
              </span>
            )}

            {props?.isPraciseQues || props?.fullScreenCoding ? (
              <div className="p-1 cursor" onClick={compileCode}>
                <PlayCircleOutlineIcon className="font40" />
              </div>
            ) : (
              <div
                className="font16 btn-close btn-close-white cursor"
                onClick={props?.onClose}
              ></div>
            )}
          </div>
        </div>
        <div className="mt-2">
          {selectedTab === "HTML" ? (
            <AceEditor
              mode="html"
              theme="monokai"
              onChange={(value) => setHtmlCode(value)}
              name="html_editor"
              editorProps={{ $blockScrolling: true }}
              width="100%"
              height={
                props?.isPraciseQues
                  ? "330px"
                  : props?.fullScreenCoding
                  ? "calc(100vh - 170px)"
                  : "250px"
              }
              value={htmlCode}
              readOnly={props?.disabled}
            />
          ) : selectedTab === "CSS" ? (
            <AceEditor
              mode="css"
              theme="monokai"
              onChange={(value) => setCssCode(value)}
              name="html_editor"
              editorProps={{ $blockScrolling: true }}
              width="100%"
              height={
                props?.isPraciseQues
                  ? "330px"
                  : props?.fullScreenCoding
                  ? "calc(100vh - 170px)"
                  : "250px"
              }
              value={cssCode}
              readOnly={props?.disabled}
            />
          ) : selectedTab === "JS" ? (
            <AceEditor
              mode="javascript"
              theme="monokai"
              onChange={(value) => setJsCode(value)}
              name="js_editor"
              editorProps={{ $blockScrolling: true }}
              width="100%"
              height={
                props?.isPraciseQues
                  ? "330px"
                  : props?.fullScreenCoding
                  ? "calc(100vh - 170px)"
                  : "250px"
              }
              value={jsCode}
              readOnly={props?.disabled}
            />
          ) : null}
        </div>
        {props?.isPraciseQues || props?.fullScreenCoding ? null : (
          <div
            className="mt-2 p-1 text-end position-absolute cursor"
            style={{ bottom: "0", right: "30px" }}
            onClick={compileCode}
          >
            <PlayCircleOutlineIcon className="font48" />
          </div>
        )}
      </div>
      <div
        className={`CodeprevScreen ${
          props?.isPraciseQues
            ? "isPraciseQues col-6"
            : props?.fullScreenCoding
            ? "col-6 fullScreen"
            : ""
        }`}
      >
        <h4 className="pt-2">Output</h4>
        <iframe
          srcDoc={output}
          title="Output"
          style={{
            width: "100%",
            height: props?.isPraciseQues ? "330px" : "200px",
            border: "none",
          }}
        />
        {error && (
          <div style={{ color: "red", marginTop: "10px" }}>{error}</div>
        )}
      </div>
    </div>
  );
}

export default CodeEditor;
