import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Components/pageTitle";
import { useLocation, useNavigate } from "react-router-dom";
import LabelField from "../../Components/labelField";
import InputField from "../../Atoms/InputField";
import {
  length_Hundred,
  length_Two,
  length_TwoHundredFifty,
  validateIsNumberOnly,
} from "../../Utils/validators";
import DeleteIcon from "@mui/icons-material/Delete";
import Addmorebtn from "../../Components/addmorebtn";
import { addIndex, getAlignedLecture, getIndex, updateIndex } from "../../Redux/Actions/AdminAction";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import PaginationTable from "../../Components/paginationTable";

export default function IndexAcademics() {
  const dispatch: Function = useDispatch();
  const bottomRef: any = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const {
    country,
    courseType,
    grade,
    exam,
    subject,
    topic,
    subTopic,
    lecture,
    pageName,
  } = location.state;
  const initialVal: {
    indexName: any;
    indexOrder: any;
  }[] = [
      {
        indexName: "",
        indexOrder: "",
      },
    ];
  const [indexArr, setIndexArr] = useState(initialVal);
  const [indexBindingList, setIndexBindingList] = useState<any>([]); 
const [newindexBindingList,setNewindexBindingList] = useState<any>([]); 
const [selectedIndex , setSelectedindex]=useState<any>("")
const [selectedData, setSelectedData]=useState<any>("") 
  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [bottomRef?.current]);
 
  useEffect(() => {
    getData()
  }, []); 

  const getData =()=>{
    const postdata = {
      lectureId: lecture?._id,
    };
    dispatch(
      getIndex(
        postdata,
        (res: any) => {
          setIndexBindingList(res?.data);
          const newData = res?.data.map((item:any) => {
            return  { ...item, isEditNameIndex: false } ;
          });
          setNewindexBindingList(newData);
        },
        () => { }
      )
     
    );
  }
  
  function onchangeIndex(val: any, index: any, key: any) { 
    let newArr = JSON.parse(JSON.stringify(indexArr));
    if (key) {
      if (key === "indexOrder") {
        if (!validateIsNumberOnly(val)) {
          newArr[index][key] = val;
          setIndexArr(newArr);
        } else {
          dispatch(showErrorSnackbar("Please enter number only"));
          return;
        }
      } else if (key === "indexName") {
        newArr[index][key] = val;
        setIndexArr(newArr);
      }
      setIndexArr(newArr);
    }
  }

    
  function onchangeEditIndex(val: any, index: any, key: any) {
    let newEditIndexArr = JSON.parse(JSON.stringify(newindexBindingList));
    if (key) {
      if (key === "indexOrder") {
        if (!validateIsNumberOnly(val)) {
          newEditIndexArr[index][key] = val;
          setNewindexBindingList(newEditIndexArr);
        } else {
          dispatch(showErrorSnackbar("Please enter number only"));
          return;
        }
      } 
      else if (key === "indexName") {
        newEditIndexArr[index]["name"] = val;
        setNewindexBindingList(newEditIndexArr);
      } 
    }
  }

  const onPressAdd = () => {
    setIndexArr([...indexArr, initialVal[0]]);
  };

  const handleClose = (index: any) => {
    const newArrr = [...indexArr];
    newArrr.splice(index, 1);
    setIndexArr(newArrr);
  };

  const onAddIndex = () => {
    const postData = {
      lectureId: lecture?._id,
      index: indexArr,
    };
    const result = indexBindingList?.flatMap((item: any) =>
      indexArr?.filter(
        (subItem: any) => item?.indexOrder === parseInt(subItem?.indexOrder)
      )
    );

    if (result?.length > 0) {
      dispatch(
        showErrorSnackbar(
          `Index orders already exist. ${result
            ?.map((item: any) => `${item?.indexName} ${item?.indexOrder}`)
            .join(", ")}`
        )
      );
    } else {
      dispatch(
        addIndex(
          postData,
          () => {
            setIndexArr(initialVal);
            dispatch(
              getIndex(
                {
                  lectureId: lecture?._id,
                },
                (res: any) => {
                  setIndexBindingList(res?.data);
                  const newData = res?.data.map((item:any) => {
                    return  { ...item, isEditNameIndex: false } ;
                  });
                  setNewindexBindingList(newData);
                },
                () => { }
              )
            );
          },
          () => { }
        )
      );
    }
  };

  const handleEdit = (value: any) => {
    navigate("../addPageAcademics", {
      state: {
        lecture: lecture,
        country: country,
        courseType: courseType,
        grade: grade,
        exam: exam,
        subject: subject,
        topic: topic,
        subTopic: subTopic,
        indexName: value?.name,
        indexOrder: value?.indexOrder,
        indexId: value?._id,
        isEditMode: value.pageAdded === true ? true : false,
      },
    });
  };
const handleEditNameIndex=(value: any)=>{
  setSelectedindex(value)
  newindexBindingList[value].isEditNameIndex=true
  setNewindexBindingList(newindexBindingList)
  setSelectedData(newindexBindingList[value])  
}
const handleUpdateNameIndex=(value:any)=>{ console.log(" Update ",value) 

  const Postdata = {
    indexName:value?.name,
    indexOrder: value?.indexOrder,
  };
  
  dispatch(
    updateIndex(
      Postdata,
      value?._id,
      (res: any) => {
        getData()
      },
      () => { }
    )
  );


}
  const validateIndex = () => {
    let isIndexArr: boolean = false;
    indexArr?.map((item: any) => {
      if (
        item?.indexName === "" ||
        item?.indexOrder === "" ||
        item?.indexName?.length < length_Two + 1
      ) {
        isIndexArr = true;
      }
    });
    return isIndexArr;
  };

  const viewLecture = () => {
    dispatch(
      getAlignedLecture(
        {
          demo: false,
          lectureId: lecture?._id,
          syllabus: true,
        },
        (res: any) => {
          // setStudentData(res);
          const updatedData: any = JSON.parse(JSON.stringify(res));
          updatedData.lecturePoint[0].status = 2;
          sessionStorage?.setItem("Lecture", JSON.stringify(updatedData));
          // let url = `http://localhost:3002/lectureView`;
           let url=`${process.env.REACT_APP_ADMINLINK}/lectureView`;
          
          window.open(url, "_blank");
        },
        () => {}
      )
    );

    // setOpenPopup(true);
  };

  return (
    <div className="contentBox ">
      <PageTitle
        name={"INDEX"}
        lectureData={lecture}
        countryData={country}
        courseTypeData={courseType}
        gradeData={grade}
        examData={exam}
        subjectData={subject}
        topicData={topic}
        subTopicData={subTopic}
      />
      <div className="px-4 mx-0  formDiv">
        <form className=" pt-2">
          <div className="container-fluid pe-0 ps-0">
            <div className="py-2 "></div>
            <table className="w-100 ">
              <thead className="table-primary font-12">
                <tr>
                  <th className="p-3 ">COUNTRY</th>
                  <th className="p-3 ">COURSE TYPE</th>
                  {exam ? <th className="p-3 ">EXAM</th> : null}
                  {grade ? <th className="p-3 ">GRADE</th> : null}
                  <th className="p-3 ">SUBJECT</th>
                  <th className="p-3 ">TOPIC</th>
                  <th className="p-3 ">SUB-TOPIC</th>
                  <th className="p-3 ">LECTURE NAME</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{country?.name ? country?.name : "-"}</td>
                  <td>{courseType?.name ? courseType?.name : "-"}</td>
                  {grade ? <td>{grade?.name ? grade?.name : "-"}</td> : null}
                  {exam ? <td>{exam?.name ? exam?.name : "-"}</td> : null}
                  <td>{subject?.name ? subject?.name : "-"}</td>
                  <td>{topic?.name ? topic?.name : "-"}</td>
                  <td>{subTopic?.name ? subTopic?.name : "-"}</td>
                  <td>{lecture?.name ? lecture?.name : "-"}</td>
                </tr>
              </tbody>
            </table>
            <div className="p-2 d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-dark rounded-pill px-3 "
                      onClick={() => viewLecture()}
                    >
                      View Lecture
                    </button>
                  </div>

            <div className="align-item-center d-flex pt-3">
              <LabelField lableName={"Add New Index"} />
            </div>
            <div className="d-flex Justify-content-between border border-black">
              <div
                className="col-8"
                style={{ maxHeight: "250px", overflow: "auto" }}
              >
                {indexArr.map((item: any, index: any) => {
                  const { indexName, indexOrder } = item;
                  return (
                    <div
                      className="d-flex p-1"
                      ref={index === indexArr.length - 1 ? bottomRef : null}
                    >
                      <div className=" d-flex col-11 border rounded p-0">
                        <div className=" input-border">
                          <div className="d-flex jusftify-content-between mt-2">
                            <span className="px-2">{index + 1} </span>
                            <InputField
                              placeholder="Enter Index Name"
                              value={indexName}
                              onChangeInput={(value: any) =>
                                onchangeIndex(value, index, "indexName")
                              }
                              maxlength={length_TwoHundredFifty - 1}
                              isRequired
                            />
                            <InputField
                              placeholder="Enter Index Order"
                              value={indexOrder}
                              onChangeInput={(value: any) =>
                                onchangeIndex(value, index, "indexOrder")
                              }
                              maxlength={length_Hundred - 1}
                              isRequired
                            />
                          </div>
                        </div>
                      </div>
                      <span className="col-6 p-2 d-flex removeButton">
                        {indexArr.length === 1 ? null : (
                          <DeleteIcon
                            className="cursor"
                            color="error"
                            fontSize="small"
                            onClick={() => handleClose(index)}
                          />
                        )}
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="col-4">
                <div className="">
                  <div className="p-2">
                    <Addmorebtn name="Add More" handleClick={onPressAdd} />
                  </div>
                  <div className="p-2">
                    <button
                      type="button"
                      className="btn btn-dark rounded-pill px-3 "
                      onClick={() => onAddIndex()}
                      disabled={validateIndex()}
                    >
                      Add Index
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className=" pt-3">
              <div className="">
                <PaginationTable
                  tableData={newindexBindingList ? newindexBindingList : []}
                  pagename={"IndexAcademics"}
                  tableHead={[
                    "S.N",
                    "INDEX NAME",
                    "INDEX ORDER",
                    "PAGE ADD STATUS",
                    "ACTION",
                  ]}
                  edit={true} 
                  selectedIndex={selectedIndex}
                  handleEdit={(value: any) => handleEdit(value)}
                  handleEditNameIndex={(value: any) => handleEditNameIndex(value)}
                  handleUpdateNameIndex={(value: any) => handleUpdateNameIndex(value)}
                  selectedData={selectedData}
                  onchangeEditIndex={(value: any,index:number,key:string) => onchangeEditIndex(value,index,key)}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
