export const leadsData = {
  count: 2,
  data: [
    {
      _id: 1,
      firstName: "Hemant",
      lastName: "Dodake",
      mobile: "+91 9999988888",
      email: "hemant@lurnigo.com",
      currentSchool: "school",
      parentEmail: "pemail",
      parentMobile: "pmob",
      gradeId: "classval",
      remarks: "desc",
      refId: "aboutUsval",
      terms_condition: "IAgree",
      admission_guidance: "guidance",
      role: "BookType",
    },
    {
      _id: 2,
      firstName: "fname1",
      lastName: "lname1",
      email: "email1",
      mobile: "mob1",
      currentSchool: "school1",
      parentEmail: "pemail1",
      parentMobile: "pmob1",
      gradeId: "classval1",
      remarks: "desc1",
      refId: "aboutUsval1",
      terms_condition: "IAgree1",
      admission_guidance: "guidance1",
      role: "BookType1",
    },
  ],
};
export const userData = {
  count: 1,
  data: [
    {
      _id: 1,
      EName: "eName",
      EmpID: "eid",
      Department: "dept",
      PanelRole: "role",
      MobNumber: "mob",
      Username: " name",
      DateOfJoining: "date",
      Status: "status",
    },
  ],
};

export const priorityOrderData = [
  {
    _id: 1,
    name: 1,
  },
  {
    _id: 2,
    name: 2,
  },
  {
    _id: 3,
    name: 3,
  },
  {
    _id: 4,
    name: 4,
  },
];

export const homepageList = [
  {
    _id: true,
    name: "Yes",
  },
  {
    _id: false,
    name: "No",
  },
];

export const statusList = [
  {
    _id: true,
    name: "Yes",
  },
  {
    _id: false,
    name: "No",
  },
];

export const courseActivationStatusList = [
  {
    _id: 1,
    name: "Yes",
  },
  {
    _id: 2,
    name: "No",
  },
];

export const notificationTypeData = [
  {
    _id: 1,
    name: "Class Schedule",
  },
  {
    _id: 2,
    name: "Test Alert",
  },
  {
    _id: 3,
    name: "Others",
  },
];

export const defaultDuration = "00:00:00";

export const examSet = [
  {
    _id: 1,
    name: " DPP",
  },
  {
    _id: 2,
    name: " Unit Test",
  },
  {
    _id: 3,
    name: "Grand Test",
  },
];

export const examSession = [
  {
    _id: 1,
    name: " Morning",
  },
  {
    _id: 2,
    name: " Afternoon",
  },
  {
    _id: 3,
    name: "Evening",
  },
];

export const dppExampleList = [
  {
    _id: 1,
    name: "DPP 1",
  },
  {
    _id: 2,
    name: "DPP 2",
  },
  {
    _id: 3,
    name: "DPP 3",
  },
];

export const questionset = [
  {
    _id: 1,
    ExamName: "L12 ECOLOGY ",
    qCount: "10/10",
    Model: "no",
    isActive: true,
  },
];

export const complexLevelData = [
  {
    _id: 1,
    name: "High",
  },
  {
    _id: 2,
    name: "Medium",
  },
  {
    _id: 3,
    name: "Low",
  },
];

export const examSection = [
  {
    _id: 1,
    name: "Section A",
  },
  {
    _id: 2,
    name: "Section B",
  },
  {
    _id: 3,
    name: "Section C",
  },
];

export const notificationScheduleType = [
  {
    _id: 1,
    name: "Batch Live",
  },
  {
    _id: 2,
    name: "1:1 Live",
  },
  {
    _id: 3,
    name: "Demo Live",
  },
  {
    _id: 4,
    name: "Free User",
  },
];

export const notificationTypeList = [
  {
    id: 1,
    n_title: "Live Demo For Chetan",
    date: "22/08/2023; 05:30:00 pm",
    schedule: "Himanshu",
  },
];

export const inputTypeText = {
  descType: "text",
  description: "",
};

export const inputTypeTextAcademics = {
  descType: "text",
  description: "",
  textContentNo: "",
};

export const inputTypeImage = {
  descType: "img",
  description: "",
};

export const inputTypeVideo = {
  descType: "vid",
  description: "",
};

export const optionTypeText = {
  descType: "text",
  description: <></>,
  isCorrect: false,
};
export const optionTypeImage = {
  descType: "img",
  description: <></>,
  isCorrect: false,
};

export const feedbacklist = [
  {
    id: 1,
    course: "Life Science",
    exam: "CSIR NET",
    subject: "ACT",
    batch: "LSCSIR NET Jan 2023",
    review: "20",
    rating: "4.5",
    lectures: [
      {
        id: 1,
        lecturename: "Biochem Life Science",
        subjectname: "Biochem",
        topic: "Cell Development",
        subtopic: "Biochem Cell Development",
        reviewcount: "20",
        overallrating: "4.5",
        feedback: [
          {
            id: 1,
            rating: "4.5",
            date: "09-01-2023; 11:30:20 AM",
            description:
              "Best Ever Coaching Centre For Csir Net Life Science. The Faculty Of IFAS Is Very Supporting And Talented. Their Content Is Very Pure, Knowledgeable. They Teach Every Topic Very Clear And In Interesting Way.I Suggest IFAS For CSIR NET Preparation. Proud To Be A Member Of IFAS.",
          },
        ],
      },
    ],
  },
];

export const demoTimeList = [
  {
    name: "9 AM",
    _id: 9,
    data: "9:00:00",
  },
  {
    name: "10 AM",
    _id: 10,
    data: "10:00:00",
  },
  {
    name: "11 AM",
    _id: 11,
    data: "11:00:00",
  },
  {
    name: "12 PM",
    _id: 12,
    data: "12:00:00",
  },
  {
    name: "1 PM",
    _id: 13,
    data: "1:00:00",
  },
  {
    name: "2 PM",
    _id: 14,
    data: "2:00:00",
  },
  {
    name: "3 PM",
    _id: 15,
    data: "3:00:00",
  },
  {
    name: "4 PM",
    _id: 16,
    data: "4:00:00",
  },
  {
    name: "5 PM",
    _id: 17,
    data: "5:00:00",
  },
  {
    name: "6 PM",
    _id: 18,
    data: "6:00:00",
  },
  {
    name: "7 PM",
    _id: 19,
    data: "7:00:00",
  },
  {
    name: "8 PM",
    _id: 20,
    data: "8:00:00",
  },
  {
    name: "9 PM",
    _id: 21,
    data: "9:00:00",
  },
];

export const levelList = [
  {
    _id: 1,
    name: "Level 1",
  },
  {
    _id: 2,
    name: "Level 2",
  },
  {
    _id: 3,
    name: "Level 3",
  },
];
export const TypeList = [
  {
    _id: 1,
    name: "Global",
  },
  {
    _id: 2,
    name: "Counsellor",
  },
];

export const GradeList = [
  {
    _id: 1,
    name: "1st",
  },
  {
    _id: 2,
    name: "2nd",
  },
  {
    _id: 3,
    name: "3rd",
  },
  {
    _id: 4,
    name: "4th",
  },
  {
    _id: 5,
    name: "5th",
  },
  {
    _id: 6,
    name: "6th",
  },
  {
    _id: 7,
    name: "7th",
  },
  {
    _id: 8,
    name: "8th",
  },
  {
    _id: 9,
    name: "9th",
  },
  {
    _id: 10,
    name: "10th",
  },
  {
    _id: 11,
    name: "11th",
  },
  {
    _id: 12,
    name: "12th",
  },
];

export const cancelReasonList: any = [
  {
    _id: 1,
    name: "Kid Unavailability",
  },
  {
    _id: 2,
    name: "Customer Emergency",
  },
  {
    _id: 3,
    name: "No Need for Additional Tutoring",
  },
  {
    _id: 4,
    name: "In-Person Over Online Tutoring",
  },
  {
    _id: 4,
    name: "Scheduling Conflict",
  },
  {
    _id: 4,
    name: "Preference for Other Providers",
  },
  {
    _id: 4,
    name: "Fake Schedule / No Interest",
  },
];

export const currencyList: any = [
  {
    _id: 1,
    name: "USD",
  },
  {
    _id: 2,
    name: "AUD",
  },
  {
    _id: 3,
    name: "INR",
  },
];

export const paymentOptionsList: any = [
  {
    _id: 1,
    name: "Full Payment",
  },
  {
    _id: 2,
    name: "Pay in 2 Installments",
  },
  {
    _id: 3,
    name: "Pay in 3 Installments",
  },
];

export const Installments: any = [
  {
    _id: 1,
    name: "2 Installments",
  },
  {
    _id: 2,
    name: "3 Installments",
  },
];

export const CouponStatusList: any = [
  {
    _id: 1,
    name: "Activated",
  },
  {
    _id: 2,
    name: "Deactivated",
  },
];

export const PaymentTypes: any = [
  {
    _id: 1,
    name: "Transaction",
  },
  {
    _id: 2,
    name: "Extend",
  },
  {
    _id: 3,
    name: "Upgrade",
  },
];

export const PaymentStatus: any = [
  {
    _id: 1,
    name: "Generated",
  },
  {
    _id: 2,
    name: "Successful",
  },
  {
    _id: 3,
    name: "Failed",
  },
];

export const ContentPageList: any = [
  {
    _id: 1,
    name: "Class Content",
  },
  {
    _id: 2,
    name: "Practice Question",
  },
];

export const ImageRatioList: any = [
  {
    _id: 1,
    name: "1:1",
  },
  {
    _id: 2,
    name: "16:9",
  },
  {
    _id: 3,
    name: "2:3",
  },
];

export const TextOptions: any = [
  {
    _id: 1,
    name: "Heading",
  },
  {
    _id: 2,
    name: "Sub-Heading",
  },
  {
    _id: 3,
    name: "Description",
  },
  {
    _id: 4,
    name: "Points",
  },
  {
    _id: 7,
    name: "TextImage-left",
  },
  {
    _id: 8,
    name: "TextImage-right",
  },
  {
    _id: 9,
    name: "TextImage-center",
  },
  {
    _id: 10,
    name: "TextImage-outer",
  },
  {
    _id: 11,
    name: "Flip Card",
  },
  {
    _id: 12,
    name: "Accordian",
  },
  {
    _id: 13,
    name: "Tabular Data",
  },
  {
    _id: 14,
    name: "Horizontal Tab",
  },
];

export const QuestionType: any = [
  {
    _id: 1,
    name: "MCQ",
  },
  {
    _id: 2,
    name: "Fixed Question",
  },
  {
    _id: 3,
    name: "MSQ",
  },
  {
    _id: 4,
    name: "Descriptive",
  },
  {
    _id: 5,
    name: "Match The Pair",
  },
  {
    _id: 6,
    name: "Coding",
  },
];

export const affilateStatus = [
  {
    _id: 1,
    name: "unscheduled",
  },
  {
    _id: 2,
    name: "scheduled",
  },
  {
    _id: 3,
    name: "rescheduled",
  },
  {
    _id: 4,
    name: "cancelled",
  },
  {
    _id: 5,
    name: "completed",
  },
  {
    _id: 6,
    name: "started",
  },
  {
    _id: 8,
    name: "feedback submitted",
  },
  {
    _id: 7,
    name: "counselling completed",
  },
];
export const CodingTypeData: any = [
  {
    _id: 1,
    name: "HTML",
  },
  {
    _id: 2,
    name: "CSS",
  },
  {
    _id: 3,
    name: "JAVASCRIPT",
  },
  {
    _id: 4,
    name: "PYTHON",
  },
];

export const complexLevel = [
  {
    _id: 1,
    name: "High",
  },
  {
    _id: 2,
    name: "Medium",
  },
  {
    _id: 3,
    name: "Low",
  },
  {
    _id: 4,
    name: "Complex",
  },
];
export const studentLevelList: any = [
  {
    _id: 1,
    name: "Beginner",
  },
  {
    _id: 2,
    name: "Intermediate",
  },
  {
    _id: 3,
    name: "Advance",
  },
];

export const FormStatus: any = [
  {
    _id: 1,
    name: "Onboarding",
  },
  {
    _id: 2,
    name: "Joining Document",
  },
  {
    _id: 3,
    name: "GreytHr",
  },
];

export const GenderData: any = [
  {
    _id: 1,
    name: "Male",
  },
  {
    _id: 2,
    name: "Female",
  },
  {
    _id: 3,
    name: "Other",
  },
];

export const MaritalStatus: any = [
  {
    _id: 1,
    name: "Married",
  },
  {
    _id: 2,
    name: "Unmarried",
  },
];

export const PaymentStatusList: any = [
  {
    _id: 1,
    name: "Generated",
  },
  {
    _id: 200,
    name: "Successful",
  },
  {
    _id: 400,
    name: "Failed",
  },
];

export const CounsellorPayTypeList: any = [
  {
    _id: 5,
    name: "For Counsellor CouponCode Payments",
  },
  {
    _id: 6,
    name: "For Counsellor Link Payments",
  },
];

export const paymentStatus: any = [
  {
    _id: 1,
    name: "Approved",
  },
  {
    _id: 2,
    name: "Reject",
  },
];

export const socialMediaType: any = [
  {
    _id: 1,
    name: "Google",
  },
  {
    _id: 2,
    name: "Youtube",
  },
  {
    _id: 3,
    name: "Facebook",
  },
  {
    _id: 4,
    name: "Instagram",
  },
  {
    _id: 5,
    name: "Quora",
  },
  {
    _id: 6,
    name: "Linkedin",
  },
];
export const imagePositionList: any = [
  {
    _id: 1,
    name: "Imageleft",
  },
  {
    _id: 2,
    name: "Imageright",
  },
  {
    _id: 3,
    name: "center",
  },
];

export const SeoTextOptions: any = [
  // {
  //   _id: 1,
  //   name: "Heading",
  // },
  // {
  //   _id: 2,
  //   name: "Sub-Heading",
  // },
  // {
  //   _id: 3,
  //   name: "Description",
  // },
  // {
  //   _id: 4,
  //   name: "Points",
  // },
  {
    _id: 5,
    name: "Home Content",
  },
  {
    _id: 6,
    name: "Rating from other panel",
  },
  {
    _id: 7,
    name: "College logos",
  },
  {
    _id: 8,
    name: "Course type",
  },
  {
    _id: 9,
    name: "Course Features",
  },
  {
    _id: 10,
    name: "Lurnigo Process",
  },
  {
    _id: 11,
    name: "Lurnigo Stats",
  },
  {
    _id: 12,
    name: "Review & Rating",
  },
  {
    _id: 13,
    name: "Subjects",
  },
  {
    _id: 14,
    name: "Tutoring Services",
  },
  {
    _id: 15,
    name: "Subscription Plans",
  },
  {
    _id: 16,
    name: "Book Session",
  },
  {
    _id: 17,
    name: "Syllabus",
  },
  {
    _id: 18,
    name: "Content",
  },
  {
    _id: 19,
    name: "Subject Feature",
  },
];

export const DirectionList: any = [
  {
    _id: 1,
    name: "Left",
  },
  {
    _id: 2,
    name: "Right",
  },
];
