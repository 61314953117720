import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Components/pageTitle";
import { useLocation, useNavigate } from "react-router-dom";
import LabelField from "../../Components/labelField";
import InputField from "../../Atoms/InputField";
import { length_Hundred, validateIsNumberOnly } from "../../Utils/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import CustomDropdown from "../../Components/customDropdown";
import { addPage, getAddPageData } from "../../Redux/Actions/AdminAction";
import {
  CodingTypeData,
  ContentPageList,
  defaultDuration,
  homepageList,
  TextOptions,
} from "../../Utils/dataConstant";
import AcademicsClassContent from "../../Components/academicsClassContent";
import AcademicsFunFact from "../../Components/academicsFunFact";
import SubmitButton from "../../Components/submitButton";
import AcademicsPracticeQuestion from "../../Components/academicsPracticeQuestion";
import TimerClock from "../../Atoms/timerClock";
import TextCKEditor from "../../Components/textCKEditor";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/ace";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/worker-html";
import "ace-builds/src-noconflict/worker-css";
import "ace-builds/src-noconflict/worker-javascript";


// Set base path
import { config } from "ace-builds";
config.set("basePath", "node_modules/ace-builds/src-noconflict");
config.set("useWorker", false);

export default function AddPageAcademics() {
  const dispatch: Function = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    lecture,
    country,
    courseType,
    grade,
    exam,
    subject,
    topic,
    subTopic,
    indexName,
    indexOrder,
    indexId,
    isEditMode,
  } = location.state;
  const [pageNo, setPageNo] = useState<any>(indexOrder ? indexOrder : "");
  const [indexBinding, setIndexBinding] = useState<any>("");
  const [backgroundImg, setBackgroundImg] = useState<any>("");
  const [uploadImages, setUploadImages] = useState<any>("");
  const [contentPage, setContentPage] = useState<any>("");
  const [resetContentPageList, setResetContentPageList] = useState(false);
  const [menuArray, setmenuArray] = useState<any>();
  const [minDuration, setMinDuration] = useState<any>(defaultDuration);
  const [contentList, setContentList] = useState<any>([]);
  const [funFactList, setFunFactList] = useState<any>([]);
  const [mcqQueList, setMcqQueList] = useState<any>([]);
  const [addPageDataList, setAddPageDataList] = useState<any>([]);
  const [coin, setCoin] = useState<any>("");
  const [isCoding, setIsCoding] = useState(false);
  const [resetCodingType, setresetCodingType] = useState(false);
  const [codingType, setCodingType] = useState<any>("");
  const [demoCoding, setDemoCoding] = useState<any>({
    html:"",
    css:"",
    js:"",
    python:""
  });
  useEffect(() => {
    getPageDetails();
    setmenuArray(TextOptions);
  }, []);

  const getPageDetails = () => {
    dispatch(
      getAddPageData(
        indexId,
        (res: any) => {
          setAddPageDataList(res);
          res.forEach((value: any) => {
            setPageNo(value?.pageNo);
            setMinDuration(value?.minimumDuration);
            setBackgroundImg(value?.backgroundImage);
            setUploadImages(value?.backgroundImage);
            setContentPage(value?.pageType);
            setCoin(value?.coin);
            setIsCoding(value?.coding);
            if(value?.codingLanguage){
              const filterCodingType=CodingTypeData?.filter((item:any)=>item?._id===value?.codingLanguage)
              if(filterCodingType?.length>0){
                setCodingType(filterCodingType[0]);
              }
            }
            if(value?.demoCoding?.html){
              setDemoCoding((prevState:any) => ({...prevState, html:value?.demoCoding?.html}))
            }
            if(value?.demoCoding?.css){
              setDemoCoding((prevState:any) => ({...prevState, css:value?.demoCoding?.css}))
            }
            if(value?.demoCoding?.js){
              setDemoCoding((prevState:any) => ({...prevState, js:value?.demoCoding?.js}))
            }
            if(value?.demoCoding?.python){
              setDemoCoding((prevState:any) => ({...prevState, python:value?.demoCoding?.python
            }))
          }

          });
        },
        () => {}
      )
    );
  };

  const onChangePageNumber = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setPageNo(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };
  const onChangeMinDuration = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setMinDuration(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };

  const onChangeCoin = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setCoin(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };

  const onChangeIndexBinding = (value: any) => {
    setIndexBinding(value);
  };

  const onChangeContentPage = (value: any) => {
    setContentPage(value);
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const postdata: any = {
      countryId: country?._id,
      coursetypeId: courseType?._id,
      examtypeId: exam?._id,
      subjectId: subject?._id,
      topicId: topic?._id,
      subTopicId: subTopic?._id,
      lectureId: lecture?._id,
      pageNo: pageNo,
      indexId: indexId,
      pageType: contentPage?._id,
      minimumDuration: minDuration,
      coding: isCoding,
      demoCoding
    };
    if (
      postdata["gradeId"] === null ||
      postdata["gradeId"] === undefined ||
      postdata["gradeeId"] === ""
    ) {
      delete postdata["gradeId"];
    }
    if (contentPage?._id === 1) {
      postdata["classContent"] = contentList?.classContent;
      postdata["funFact"] = funFactList?.funFact;
      if (
        postdata["funFact"] === null ||
        postdata["funFact"] === undefined ||
        postdata["funFact"] === funFactList?.length < 0
      ) {
        delete postdata["funFact"];
      }
    }
    if (contentPage?._id === 2) {
      postdata["coin"] = coin;
      postdata["questionType"] = mcqQueList?.questionType;
      postdata["question"] = mcqQueList?.question;
      postdata["option"] = mcqQueList?.options;
      postdata["answer"] = mcqQueList?.answer;
      postdata["hint"] = mcqQueList?.hint;
    }
    if (isCoding) {
      postdata["codingLanguage"] = codingType?._id;
    }
    dispatch(
      addPage(
        postdata,
        (res: any) => {
          setPageNo("");
          setIndexBinding("");
          setResetContentPageList(true);
          setMinDuration("");
          setBackgroundImg("");
          setUploadImages("");
          setContentList([]);
          setFunFactList([]);
          navigate("/Academics/indexAcademics", {
            state: {
              lecture: lecture,
              country: country,
              courseType: courseType,
              grade: grade,
              exam: exam,
              subject: subject,
              topic: topic,
              subTopic: subTopic,
            },
          });
        },
        () => {}
      )
    );
  };
  const handleMcqData = (value: any) => {
    setMcqQueList(value);
  };

  const handleClassContentData = (value: any) => {
    setContentList(value);
  };

  const handleFunFactData = (value: any) => {
    setFunFactList(value);
  };
  const validation = () => {
    return (
      pageNo === "" ||
      minDuration === "" ||
      contentPage === "" ||
      (contentPage?._id === 2 ? coin === "" : false)
    );
  };

  const onChangeCoding=(value:string,key:string)=>{
    setDemoCoding({ ...demoCoding, [key]: value });

  }
  return (
    <div className="contentBox">
      <PageTitle name={"ADD PAGE"} />
      <div className="m-4 formDiv">
        <form onSubmit={handleSubmit}>
          <div className="d-flex mt-2 align-items-center justify-content-between">
            <div className="d-flex col-md-4">
              <div className="col-md-4">
                <LabelField lableName={"Page Number"} />
              </div>
              <div className="col-md-4">
              <InputField
                placeholder="Enter Page Number"
                value={pageNo}
                onChangeInput={(value: any) => onChangePageNumber(value)}
                maxlength={length_Hundred}
                disabled
                isRequired
              />
              </div>
           
            </div>
            <div className="d-flex col-md-4">
              <div className="col-md-4">
                <LabelField lableName={"Index Binding"} />
              </div>
              <div className="col-md-6">
                <InputField
                  placeholder="Enter Index"
                  value={indexName}
                  onChangeInput={(value: any) => onChangeIndexBinding(value)}
                  isRequired
                  disabled
                />
              </div>
            </div>
            <div className="d-flex col-md-4">
              <div className="col-md-4">
                <LabelField lableName={"Min Time Duration"} />
              </div>
              <div className="col-md-6">
              <TimerClock
                value={minDuration}
                setInputText={(value: any) => setMinDuration(value)}
              />
              </div>
            </div>
          </div>
          <div className="d-flex mt-2 align-items-center ">
            <div className="d-flex col-md-4">
              <div className="col-md-4">
                <LabelField lableName={"Content On Page"} />
              </div>
              <div className="col-md-6">
                <CustomDropdown
                  lableName="Select Content"
                  setInputText={(value: any) => onChangeContentPage(value)}
                  value={contentPage}
                  options={ContentPageList}
                  Isrequired={true}
                  reset={resetContentPageList}
                  defaultValue={contentPage}
                  disabled={isEditMode}
                />
              </div>
            </div>
            {contentPage?._id === 1 ? (
              <div className="d-flex col-md-4">
                <div className="col-md-4">
                  <LabelField lableName={"Is Coding"} />
                </div>
                <div className="col-md-6">
                  <CustomDropdown
                    lableName=" select"
                    setInputText={(value: any) => setIsCoding(value?._id)}
                    value={isCoding}
                    options={homepageList}
                    Isrequired={true}
                    defaultValue={isCoding}
                    disabled={isEditMode}
                  />
                </div>
              </div>
            ) : null}

            {isCoding && contentPage?._id === 1 ? (
              <div className="d-flex col-md-4">
                <div className="col-md-4">
                  <LabelField lableName={"Select Coding Type"} />
                </div>
                <div className="col-md-6">
                  <CustomDropdown
                    lableName="Select Coding Type"
                    setInputText={(value: any) => {setCodingType(value)}}
                    value={codingType}
                    options={CodingTypeData}
                    reset={resetCodingType}
                    defaultValue={codingType?._id}
                    disabled={isEditMode}
                  />
                </div>
              </div>
            ) : null}
            {contentPage?._id === 2 ? (
              <div className="d-flex col-md-4">
                <div className="col-md-4">
                  <LabelField lableName={"Add Coin"} />
                </div>
                <InputField
                  placeholder="Enter Coin"
                  value={coin}
                  onChangeInput={(value: any) => onChangeCoin(value)}
                  isRequired
                />
              </div>
            ) : null}
          </div>
          {contentPage?._id === 1 ? (
            <>
              <div className="border border-black">
                <AcademicsClassContent
                  setClassContentData={(value: any) =>
                    handleClassContentData(value)
                  }
                  getData={addPageDataList}
                />
              </div>
              <div className="border border-black mt-2 row mx-0">
                {codingType?.name === "HTML" || codingType?.name === "CSS" || codingType?.name === "JAVASCRIPT" ? (
                  <div className="col-md-6">
                     <div className="p-3 bg-dark d-flex align-items-center justify-content-between">
                     <h5 className="fw-bold text-light">HTML</h5>
                     </div>
                    <AceEditor
                      mode="html"
                      theme="monokai"
                      onChange={(value) => onChangeCoding(value, "html")}
                      name="html_editor"
                      editorProps={{ $blockScrolling: true }}
                      width="100%"
                      height={"250px"
                      }
                      value={demoCoding?.html||""}
                    />
                  </div>

                ):null}
                {codingType?.name === "CSS" ? (
                  <div className="col-md-6">
                    <div className="p-3 bg-dark d-flex align-items-center justify-content-between">
                     <h5 className="fw-bold text-light">CSS</h5>
                     </div>
                    <AceEditor
                      mode="css"
                      theme="monokai"
                      onChange={(value) => onChangeCoding(value, "css")}
                      name="html_editor"
                      editorProps={{ $blockScrolling: true }}
                      width="100%"
                      height={"250px"}
                      value={demoCoding?.css||""}

                    />
                  </div>

                ) :null}
                { codingType?.name === "JAVASCRIPT" ? (
                  <div className="col-md-6">
                    <div className="p-3 bg-dark d-flex align-items-center justify-content-between">
                     <h5 className="fw-bold text-light">JAVASCRIPT</h5>
                     </div>
                  <AceEditor
                    mode="javascript"
                    theme="monokai"
                    onChange={(value) => onChangeCoding(value, "js")}
                    name="js_editor"
                    editorProps={{ $blockScrolling: true }}
                    width="100%"
                    height={"250px"}
                    value={demoCoding?.js ||""}
                  />
                </div>
                ) : null}

{ codingType?.name === "PYTHON" ? (
                  <div className="col-md-6">
                    <div className="p-3 bg-dark d-flex align-items-center justify-content-between">
                      <h5 className="fw-bold text-light">PYTHON</h5>
                    </div>

                    <AceEditor
                      mode="python"
                      theme="monokai"
                      onChange={(value) => onChangeCoding(value, "python")}
                      name="python_code_editor"
                      editorProps={{ $blockScrolling: true }}
                      width="100%"
                      height={"250px"}
                      value={demoCoding?.python || ""}
                    />
                  </div>
                ) : null}

              </div>
              <div className="border border-black mt-2">
                <AcademicsFunFact
                  setFunFactData={(value: any) => handleFunFactData(value)}
                  getData={addPageDataList}
                />
              </div>
            </>
          ) : null}

          {contentPage?._id === 2 ? (
            <>
              <AcademicsPracticeQuestion
                setMcqData={(value: any) => handleMcqData(value)}
                getData={addPageDataList}
                isEditMode={isEditMode}
                setCodingType={setCodingType}
                codingType={codingType}
                CodingTypeData={CodingTypeData}
                setIsCoding={setIsCoding}
              />
            </>
          ) : null}
          {contentPage ? (
            <div className=" d-flex justify-content-center mt-3">
              <SubmitButton name={"Submit"} validation={validation()} />
            </div>
          ) : null}
        </form>
      </div>
    </div>
  );
}
