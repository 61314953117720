export default {
  AffiliateSidebarData: [
    {
      title: "MY PROFILE",
      path: "userProfile",
    },
    { 
            title: "DEMO MANAGEMENT",
            subNav: [
              {
                title: "DEMO REQUEST",
                path: "demoRequest",
                cName: "sub-nav",
              },
              {
                title: "DEMO SCHEDULE",
                path: "demoSchedule",
                cName: "sub-nav",
              },
              {
                title: "DEMO CONDUCTED",
                path: "demoConducted",
                cName: "sub-nav",
              },
              {
                title: "DEMO CANCEL",
                path: "demoCancel",
                cName: "sub-nav",
              },
              // {
              //   title: "DEMO JOIN",
              //   path: "demoJoin",
              //   cName: "sub-nav",
              // },
            ],
          },
    // {
    //   title: "LEAD GENERATED",
    //   path: "affiliateLeads",
    // },
    {
      title: "DEMO PAYMENTS",
      path: "demoPayments",
    },

  ],
};

 