import { Route, Routes } from "react-router-dom";
import Sidebar from "../../Components/sidebar";
import WIP from "../wip";
import operationSidebarData from "./operationSidebarData";
import CounsellingLeads from "./counsellingLeads";
import LandingPageLeads from "./landingPageLeads";
import TutorNetwork from "./tutorNetwork";
import MarketingLandingpage from "./marketingLandingpage";
import DemoRequest from "./demoRequest";
import MyDemo from "./myDemo";
import DemoSchedule from "./demoSchedule";
import DemoConducted from "./demoConducted";
import DemoPending from "../SalesPanel/demoPending";
import DemoCancel from "./demoCancel";
import UpcomingScheduleClass from "./upcomingScheduleClass";
import DemoJoin from "./demoJoin";
import ClassJoin from "./classJoin";
import ClassPending from "../SalesPanel/classPending";
import PaymentPayout from "./paymentPayout";
import RegisteredUsers from "../SuperAdmin/registeredUsers";
import DsaPaymentPayout from "./dsaPaymentPayout";
import DsaConversion from "./dsaConversion";
import DsaConversionDetails from "./DsaConversionDetails";
import PtmFeedback from "./ptmFeedback";
import LiveClassStudentFeedback from "./liveClassStudentFeedback";
import Conversion from "./conversion";
import ConversionDetails from "./conversionDetails";
import StudentLiveFeedback from "./studentLiveFeedback";
import TeacherLiveFeedback from "./teacherLiveFeedback";
import AffiliateMarketing from "./affiliateMarketing";
import AffiliatePaymentPayout from "./affiliatePaymentPayout";
export default function OperationDashboard() {
  return (
    <div className="AppBox">
      <Sidebar data={operationSidebarData?.OperationSidebarData} />
      <div className="dashboard">
        <Routes>
          <Route path="/" element={<CounsellingLeads />} />
          <Route path="counsellingLeads" element={<CounsellingLeads />} />
          <Route path="landingpageleads" element={<LandingPageLeads />} />
          <Route path="registeredUsers" element={<RegisteredUsers />} />
          <Route path="tutorNetwork" element={<TutorNetwork />} />
          <Route
            path="marketingLandingpage"
            element={<MarketingLandingpage />}
          />
          <Route path="demoRequest" element={<DemoRequest />} />
          <Route path="myDemo" element={<MyDemo />} />
          <Route path="demoSchedule" element={<DemoSchedule />} />
          <Route path="demoConducted" element={<DemoConducted />} />
          <Route path="demoPending" element={<DemoPending />} />
          <Route path="demoCancel" element={<DemoCancel />} />
          <Route path="scheduleClass" element={<UpcomingScheduleClass />} />
          <Route
            path="upcomingScheduleClass"
            element={<UpcomingScheduleClass />}
          />
          <Route path="demoJoin" element={<DemoJoin />} />
          <Route path="classJoin" element={<ClassJoin />} />
          <Route path="classPending" element={<ClassPending />} />
          <Route path="paymentPayout" element={<PaymentPayout />} />
          <Route path="dsaPaymentPayout" element={<DsaPaymentPayout />} />
          <Route path="dsaConversion" element={<DsaConversion />} />
          <Route
            path="dsaConversionDetails"
            element={<DsaConversionDetails />}
          />
          <Route path="conversion" element={<Conversion />} />
          <Route path="conversionDetails" element={<ConversionDetails />} />
          <Route path="ptmFeedback" element={<PtmFeedback />} />
          <Route
            path="liveClassStudentFeedback"
            element={<LiveClassStudentFeedback />}
          />
          <Route path="studentLiveFeedback" element={<StudentLiveFeedback />} />
          <Route path="teacherLiveFeedback" element={<TeacherLiveFeedback />} />
          <Route path="affiliateMarketing" element={<AffiliateMarketing />} />
          <Route
            path="affiliatePaymentPayout"
            element={<AffiliatePaymentPayout />}
          />

          <Route path="*" element={<WIP />} />
        </Routes>
      </div>
    </div>
  );
}
