import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import DatePicker from "../../Components/datePicker";
import { today } from "../../Utils/appConstants";
import SubmitButton from "../../Components/searchButton";
import { useDispatch } from "react-redux";
import CustomDropdown from "../../Components/customDropdown";
import {
  PayoutSave,
  getCountryList,
  getPayoutList,
} from "../../Redux/Actions/AdminAction";
import { updateTeacherList } from "../../Redux/Actions/SuperAdminAction";
import RecordedVideoPopup from "../../Components/recordedVideoPopup";
import PaymentApproveReject from "../../Components/paymentApproveReject";

export default function PaymentPayout() {
  const dispatch: Function = useDispatch();
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);
  const [payoutList, setPayoutList] = useState<any>([]);
  const [teacher, setTeacher] = useState<any>("");
  const [teacherList, setTeacherList] = useState<any>([]);
  const [resetteacher, setResetTeacher] = useState<any>(false);
  const [toggle, setToggle] = useState("Pending");
  const [open, setopen] = useState(false);
  const [recordedPath, setRecordedPath] = useState<any>("");
  const [selectPayment, setSelectPayment] = useState<any>([]);
  const [openPay, setOpenPay] = useState(false);


  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );
    const postData = {};
    dispatch(
      updateTeacherList(
        postData,
        (res: any) => {
          setTeacherList(res?.data);
        },
        () => {}
      )
    );
    reloadList();
  }, []);

  const tab = [
    {
      _id: "1",
      name: "Pending",
    },
    {
      _id: "2",
      name: "Approved Payment",
    },
    {
      _id: "3",
      name: "Rejected Payment",
    },
  ];

  const handleChangetab = (val: any) => {
    if (val === "Pending") {
      const data = {
        startDate: startDate,
        endDate: endDate,
        type: "LIVE",
        countryId: country?._id,
        empId: teacher?.empId,
      };
      dispatch(
        getPayoutList(
          data,
          (res: any) => {
            let unpaidList = res?.data?.filter(
              (elm: any, idx: any) => elm?.status === "pending"
            );
            setPayoutList(unpaidList);
          },
          () => {}
        )
      );
      setToggle(val);
    } else if (val === "Approved Payment") {
      const data = {
        startDate: startDate,
        endDate: endDate,
        type: "LIVE",
        countryId: country?._id,
        empId: teacher?.empId,
      };
      dispatch(
        getPayoutList(
          data,
          (res: any) => {
            let paidList = res?.data?.filter(
              (elm: any, idx: any) => elm.status === "approved"
            );
            setPayoutList(paidList);
          },
          () => {}
        )
      );
      setToggle(val);
    } else if (val === "Rejected Payment") {
      const data = {
        startDate: startDate,
        endDate: endDate,
        type: "LIVE",
        countryId: country?._id,
        empId: teacher?.empId,
      };
      dispatch(
        getPayoutList(
          data,
          (res: any) => {
            let paidList = res?.data?.filter(
              (elm: any, idx: any) => elm.status === "rejected"
            );
            setPayoutList(paidList);
          },
          () => {}
        )
      );
      setToggle(val);
    }
  };

  const onChangeStartDate = (value: any) => {
    setStartDate(value);
  };

  const onChangeEndDate = (value: any) => {
    setEndDate(value);
  };
  const onChangeCountry = (value: any) => {
    setCountry(value);
  };
  const onChangeteacher = (value: any) => {
    setTeacher(value);
  };
  const handleOpenPayPopUp = (value: any) => {
    setOpenPay(true);

  };

  const handleCheck = (value: any, index: any) => {
    let newArray = JSON.parse(JSON.stringify(payoutList));
    if (value.target.checked) {
      newArray[index]["isSelected"] = true;
      setSelectPayment(newArray);
      setPayoutList(newArray);
    } else {
      newArray[index]["isSelected"] = false;
      setSelectPayment([]);
      setPayoutList(newArray);
    }
  };

  const onclickView = (value: any) => {
    setRecordedPath(value);
    setopen(true);
  };

  const handleClose = () => {
    setopen(false);
  };

  const handleClosePay = () => {
    setOpenPay(false);
  };

  const renderListMode = () => {
    return (
      <div className="p-2 table-responsive">
        <div className="col-md-12 p-2 border-end ">
          <div className="d-flex mt-5  border-bottom  ">
            {tab?.map((c, index) => (
              <div
                className={`col-md-4 fs-6 text-center cursor  ${
                  toggle === c.name
                    ? " text-primary border-bottom border-primary border-3 fw-bold"
                    : "text-secondary fw-bold"
                }`}
                onClick={() => handleChangetab(c.name)}
              >
                {c.name}
              </div>
            ))}
          </div>
        </div>
        <table className="table table-bordered text-center w-100">
          <thead className="table-primary ">
            <tr className="col-md-12">
              <th className="p-3 col-md-1">S.N</th>
              <th className="p-3 col-md-1">Teacher Name</th>
              <th className="p-3 col-md-1">Class Title</th>
              <th className="p-3 col-md-1">Class Date</th>
              <th className="p-3 col-md-1">Actual Start Time</th>
              <th className="p-3 col-md-1">Delayed/Early </th>
              <th className="p-3 col-md-4">Total Class Time (in min)</th>
              <th className="p-3 col-md-1">DPP Status </th>
              <th className="p-3 col-md-1">Recorded Status</th>
              <th className="p-3 text-start col-md-1">Recordings</th>
              <th className="p-3 col-md-1"> Per Class Charges</th>
              <th className="p-3 col-md-1"> Amount</th>
              <th className="p-3 col-md-1">Pay</th>
            </tr>
          </thead>
          {payoutList?.length > 0 ? (
            <tbody className="col-md-12 ">
              {payoutList?.map((value: any, index) => {
                return (
                  <>
                    <tr>
                      <div className="d-flex col-md-1">
                        {value?.status === "pending" ? (
                          <td>
                            <span>
                              <input
                                className="check"
                                type="checkbox"
                                checked={value.isSelected}
                                onChange={(value) => {
                                  handleCheck(value, index);
                                }}
                              />
                            </span>
                          </td>
                        ) : null}
                        <td>{index + 1}</td>
                      </div>

                      <td className="col-md-1">
                        {value?.name ? value?.name : "-"}
                      </td>
                      <td className="text-start col-md-1 ">
                        {" "}
                        {value?.title ? value?.title : "-"}
                      </td>
                      <td className="text-start col-md-1 ">
                        {" "}
                        {value?.classDate ? value?.classDate : "-"}
                      </td>
                      <td className="text-start col-md-1 ">
                        {" "}
                        {value?.actualStartTime ? value?.actualStartTime : "-"}
                      </td>
                      {value?.delayMinutes ? (
                        <td
                          className={
                            value?.delayMinutes?.includes("-")
                              ? "text-danger fw-bold col-md-1"
                              : "text-success fw-bold col-md-1"
                          }
                        >
                          {" "}
                          {value?.delayMinutes} Min
                        </td>
                      ) : (
                        <td>-</td>
                      )}

                      <td className=" col-md-3 ">
                        {value?.totalClassTimeMinutes
                          ? value?.totalClassTimeMinutes
                          : "-"}
                      </td>
                      <td className=" col-md-1 ">
                        {value?.DPP_Uploaded === true
                          ? "Uploaded"
                          : "Not Uploaded"}
                      </td>
                      <td className=" col-md-1 ">
                        {value?.recordedStatus === true
                          ? "Uploaded"
                          : "Not Uploaded"}
                      </td>
                      <td className=" col-md-1">
                        <button
                          type="button"
                          className="btn btn-dark"
                          onClick={() => onclickView(value?.s3Path)}
                          disabled={value?.recordedStatus === false}
                        >
                          View
                        </button>
                      </td>
                      <td className=" col-md-1 ">
                        {value?.charges ? value?.charges : "-"}
                      </td>
                      <td className=" col-md-1 ">
                        {value?.Amount
                          ? parseFloat(value?.Amount).toFixed(2)
                          : "-"}
                      </td>
                      <td>
                        {value?.status === "Paid" ? value?.status : "Unpaid"}
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              <td className="fw-bold p-1">No Record Found</td>
            </tbody>
          )}
        </table>
        {payoutList?.length > 0 && toggle === "Pending" ? (
          <div className="d-flex justify-content-end">
            <button
              className={
                selectPayment?.length > 0
                  ? "btn btn-success "
                  : " btn btn-secondary"
              }
              onClick={(value) => handleOpenPayPopUp(value)}
            >
              Submit
            </button>
          </div>
        ) : null}
      </div>
    );
  };

  const reloadList = () => {
    const data = {
      startDate: startDate,
      endDate: endDate,
      type: "LIVE",
      countryId: country?._id,
      empId: teacher?.empId,
    };
    if (
      data["countryId"] === null ||
      data["countryId"] === undefined ||
      data["countryId"] === ""
    ) {
      delete data["countryId"];
    }
    if (
      data["empId"] === null ||
      data["empId"] === undefined ||
      data["empId"] === ""
    ) {
      delete data["empId"];
    }
    dispatch(
      getPayoutList(
        data,
        (res: any) => {
          res?.data?.filter((item: any) => {
            if (item?.status === "pending") {
              setToggle("Pending");
              let unpaidList = res?.filter(
                (elm: any, idx: any) => elm?.status === "pending"
              );
              setPayoutList(unpaidList);
            } else if (item?.status === "approved") {
              setToggle("Approved Payment");
              let paidList = res?.filter(
                (elm: any, idx: any) => elm.status === "approved"
              );
              setPayoutList(paidList);
            } else if (item?.status === "rejected") {
              setToggle("Rejected Payment");
              let cancelList = res?.filter(
                (elm: any, idx: any) => elm.status === "rejected"
              );
              setPayoutList(cancelList);
            }
          });
        },
        () => {}
      )
    );
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const data = {
      startDate: startDate,
      endDate: endDate,
      type: "LIVE",
      countryId: country?._id,
      empId: teacher?.empId,
    };
    dispatch(
      getPayoutList(
        data,
        (res: any) => {
          if (toggle === "Pending") {
            let unpaidList = res?.data?.filter(
              (elm: any, idx: any) => elm.status === "pending"
            );
            setPayoutList(unpaidList);
          } else if (toggle === "Approved Payment") {
            let paidList = res?.data?.filter(
              (elm: any, idx: any) => elm.status === "approved"
            );
            setPayoutList(paidList);
          } else if (toggle === "Rejected Payment") {
            let cancelList = res?.data?.filter(
              (elm: any, idx: any) => elm.status === "rejected"
            );
            setPayoutList(cancelList);
          }
        },
        () => {}
      )
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"PAYMENT PAYOUT"} />
      <div className="formDiv">
        <form className="text-center " onSubmit={handleSubmit}>
          <div className="px-2 mx-0 mt-4 row d-flex flex-wrap Justify-content-between ">
            <div className="col-md-4 d-flex mb-2 align-items-center ">
              <LabelField lableName={"Start Date:"} />
              <div className="col-md-7 ps-2">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => onChangeStartDate(value)}
                  maxdate={today}
                />
              </div>
            </div>
            <div className=" col-md-4 d-flex mb-2 align-items-center">
              <LabelField lableName={"End Date:"} />
              <div className="col-md-7 ps-2">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => onChangeEndDate(value)}
                  maxdate={today}
                />
              </div>
            </div>
            <div className=" col-md-4 d-flex mb-2 align-items-center">
              <LabelField lableName={"Country"} />
              <div className="col-md-7 ps-2">
                <CustomDropdown
                  lableName="Select Country"
                  setInputText={(value: any) => onChangeCountry(value)}
                  value={country}
                  options={countryList}
                  reset={resetCountryList}
                />
              </div>
            </div>
            <div className=" col-md-4 d-flex mb-2 align-items-center">
              <LabelField lableName={"Teachers Name"} />
              <div className="col-md-7 ps-2">
                <CustomDropdown
                  lableName="Select Teacher Name"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeteacher(value)
                  }
                  value={teacher}
                  options={teacherList}
                  reset={resetteacher}
                />
              </div>
            </div>

            <div className="d-flex col-md-2  ">
              <SubmitButton name={"Search"} />
            </div>
          </div>
        </form>
        <div className="shadow-lg  m-2 mt-5  ">{renderListMode()}</div>
      </div>
      <RecordedVideoPopup
        openPopup={open}
        handleClose={handleClose}
        recordedPath={recordedPath}
      />
      <PaymentApproveReject
        openPopup={openPay}
        handleClose={handleClosePay}
        paymentList={payoutList}
        getPaymentList={reloadList}
        selectPayment={selectPayment}
        pageName={"PaymentPayout"}
      />
    </div>
  );
}
